import React from 'react';
import { string } from 'prop-types';
import Collapsible from 'react-collapsible';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import { locationsForState } from '../../util/locations';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const locationLink = location => (
  <div key={location.name} className={css.listItem}>
    <NamedLink
      name="SearchPage"
      to={{
        search: location.url,
      }}
      className={css.link}
    >
      {location.name} Trailers
    </NamedLink>
  </div>
);

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ForOwnersPage" className={css.link}>
                    <FormattedMessage id="Footer.toOwnersPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ForHirersPage" className={css.link}>
                    <FormattedMessage id="Footer.toHirersPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SafeHiringPage" className={css.link}>
                    <FormattedMessage id="Footer.toSafeHiringPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ExplainWeightsPage" className={css.link}>
                    <FormattedMessage id="Footer.toExplainWeightsPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HelpPage" className={css.link}>
                    <FormattedMessage id="Footer.toHelpPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="MusteringPage" className={css.link}>
                    <FormattedMessage id="Footer.toMusteringPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <h2 className={css.searchHeading}>Victoria</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Melbourne%2C%20Victoria&bounds=-37.5112737225%2C145.512528832%2C-38.433859306%2C144.593741856&origin=-37.8142%2C144.9632',
                    }}
                    className={css.link}
                  >
                    Melbourne Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Geelong%2C%20Victoria%2C%20Australia&bounds=-37.7899738891714%2C144.81672315966634%2C-38.508626110828594%2C143.90287684033368&origin=-38.1493%2C144.3598',
                    }}
                    className={css.link}
                  >
                    Geelong Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=7Bendigo%2C%20Victoria%2C%20Australia&bounds=-36.3995738891714%2C144.73110681999395%2C-37.118226110828594%2C143.83409318000605&origin=-36.7589%2C144.2826',
                    }}
                    className={css.link}
                  >
                    Bendigo Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('VIC').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>Queensland</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Brisbane%2C%20Queensland%2C%20Australia&bounds=-26.996844991%2C153.31787024%2C-27.767436998%2C152.668522848&origin=-27.469%2C153.0235',
                    }}
                    className={css.link}
                  >
                    Brisbane Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Gold%20Coast%2C%20Queensland%2C%20Australia&bounds=-27.6430738891714%2C153.82157105604983%2C-28.361726110828602%2C153.00762894395018&origin=-28.0024%2C153.4146',
                    }}
                    className={css.link}
                  >
                    Gold Coast Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Sunshine%20Coast%2C%20Queensland%2C%20Australia&bounds=-26.1849738891714%2C153.4524660943142%2C-26.9036261108286%2C152.64913390568583&origin=-26.5443%2C153.0508',
                    }}
                    className={css.link}
                  >
                    Sunshine Coast Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Townsville%2C%20Queensland%2C%20Australia&bounds=-18.8975738891714%2C147.2046221848306%2C-19.616226110828602%2C146.4433778151694&origin=-19.2569%2C146.824',
                    }}
                    className={css.link}
                  >
                    Townsville Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Cairns%2C%20Queensland%2C%20Australia&bounds=-16.5611738891714%2C146.14748538554747%2C-17.2798261108286%2C145.3963146144525&origin=-16.9205%2C145.7719',
                    }}
                    className={css.link}
                  >
                    Cairns Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Bundaberg%2C%20Queensland%2C%20Australia&bounds=-24.5059738891714%2C152.74773942512826%2C-25.224626110828602%2C151.95566057487173&origin=-24.8653%2C152.3517',
                    }}
                    className={css.link}
                  >
                    Bundaberg Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('QLD').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>New South Wales</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Sydney%2C%20New%20South%20Wales%2C%20Australia&bounds=-33.508673889171405%2C151.64275427216987%2C-34.2273261108286%2C150.77724572783015&origin=-33.868%2C151.21',
                    }}
                    className={css.link}
                  >
                    Sydney Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Newcastle%2C%20New%20South%20Wales%2C%20Australia&bounds=-32.567973889171405%2C152.20939485768903%2C-33.2866261108286%2C151.35320514231094&origin=-32.9273%2C151.7813',
                    }}
                    className={css.link}
                  >
                    Newcastle Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Central%20Coast%2C%20New%20South%20Wales%2C%20Australia&bounds=-33.060673889171404%2C151.8265082068538%2C-33.7793261108286%2C150.96549179314619&origin=-33.42%2C151.396',
                    }}
                    className={css.link}
                  >
                    Central Coast Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Wagga%20Wagga%2C%20New%20South%20Wales%2C%20Australia&bounds=-34.755673889171405%2C147.80707442876914%2C-35.4743261108286%2C146.92852557123084&origin=-35.115%2C147.3678',
                    }}
                    className={css.link}
                  >
                    Wagga Wagga Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('NSW').map(locationLink)}
                </Collapsible>
              </div>
            </div>
            <div className={css.searchesExtra}>
              <h2 className={css.searchHeading}>Australian Capital Territory</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Canberra%2C%20Australian%20Capital%20Territory%2C%20Australia&bounds=-34.9225738891714%2C149.5690779547593%2C-35.6412261108286%2C148.68872204524067&origin=-35.2819%2C149.1289',
                    }}
                    className={css.link}
                  >
                    Canberra Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('ACT').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>South Australia</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Adelaide%2C%20South%20Australia%2C%20Australia&bounds=-34.5680738891714%2C139.03816768879858%2C-35.286726110828596%2C138.1616323112014&origin=-34.9274%2C138.5999',
                    }}
                    className={css.link}
                  >
                    Adelaide Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('SA').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>Tasmania</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Hobart%2C%20Tasmania%2C%20Australia&bounds=-42.5231738891714%2C147.81847970499638%2C-43.2418261108286%2C146.83772029500363&origin=-42.8825%2C147.3281',
                    }}
                    className={css.link}
                  >
                    Hobart Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Launceston%2C%20Tasmania%2C%20Australia&bounds=-41.074773889171404%2C147.61658210512473%2C-41.7934261108286%2C146.6580178948753&origin=-41.4341%2C147.1373',
                    }}
                    className={css.link}
                  >
                    Launceston Trailers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Burnie%2C%20Tasmania%2C%20Australia&bounds=-40.6964738891714%2C146.38031540617186%2C-41.415126110828595%2C145.42728459382812&origin=-41.0558%2C145.9038',
                    }}
                    className={css.link}
                  >
                    Burnie Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('TAS').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>Western Australia</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Perth%2C%20Western%20Australia%2C%20Australia&bounds=-31.5933738891714%2C116.28399127385089%2C-32.3120261108286%2C115.43700872614912&origin=-31.9527%2C115.8605',
                    }}
                    className={css.link}
                  >
                    Perth Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('WA').map(locationLink)}
                </Collapsible>
              </div>

              <h2 className={css.searchHeading}>Northern Territory</h2>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Darwin%2C%20Northern%20Territory%2C%20Australia&bounds=-12.247341944585699%2C131.08835037507413%2C-12.6066680554143%2C130.7204036249259&origin=-12.427005%2C130.904377',
                    }}
                    className={css.link}
                  >
                    Darwin Trailers
                  </NamedLink>
                </li>
              </ul>
              <div className={css.collapsibleSection}>
                <Collapsible
                  trigger={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showMoreLocationsTrigger" />
                      </span>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className={css.listItem}>
                      <span className={css.link}>
                        <FormattedMessage id="Footer.showFewerLocationsTrigger" />
                      </span>
                    </div>
                  }
                >
                  {locationsForState('NT').map(locationLink)}
                </Collapsible>
              </div>
            </div>

            <div className={css.organization} id="organization">
              <div className={css.organizationInfo}>
                <div className={css.extraLinks}>
                  <div className={css.someLinks}>{socialMediaLinks}</div>
                  <div className={css.legalMatters}>
                    <ul className={css.tosAndPrivacy}>
                      <li>
                        <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                          <FormattedMessage id="Footer.termsOfUse" />
                        </NamedLink>
                      </li>
                      <li>
                        <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                          <FormattedMessage id="Footer.privacyPolicy" />
                        </NamedLink>
                      </li>
                      <li>
                        <NamedLink name="OwnerTermsPage" className={css.legalLink}>
                          <FormattedMessage id="Footer.ownerTerms" />
                        </NamedLink>
                      </li>
                      <li>
                        <NamedLink name="RentalTermsPage" className={css.legalLink}>
                          <FormattedMessage id="Footer.rentalTerms" />
                        </NamedLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo inverse={true} format="desktop" className={css.logo} />
                </NamedLink>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={css.copyrightAndTermsMobile}>
          <div className={css.tosAndPrivacyMobile}>
            <div>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
            </div>
            <div>
              <NamedLink name="OwnerTermsPage" className={css.terms}>
                <FormattedMessage id="Footer.ownerTerms" />
              </NamedLink>
            </div>
            <div>
              <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </div>
            <div>
              <NamedLink name="RentalTermsPage" className={css.legalLink}>
                <FormattedMessage id="Footer.rentalTerms" />
              </NamedLink>
            </div>
          </div>
          <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
            <FormattedMessage id="Footer.copyright" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
