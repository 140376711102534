import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionSpecialtyTrailers.module.css';

const SectionSpecialtyTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.specialtyTrailersContent}>
        <h3 className={css.heading}>
          How to find the right specialty trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that have the right features, are the
            right size and price.
          </li>
          <li>Choose your desired specialty trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h3 className={css.heading}>Specialty trailers FAQ</h3>
        <h4 className={css.subheading}>What is a specialty trailer?</h4>
        <p>
          Specialty trailers are unique trailers designed for specific purposes. On Local Trailer
          Hire you can find a range of specialised trailers available for hire from locals. We've
          got some beauties listed, including tipper trailers, coolroom trailers, mountain bike
          trailers, plant & machinery trailers, jetski trailers, and boat trailers.
        </p>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h4 className={css.subheading}>What is a coolroom trailer?</h4>
        <p>
          A coolroom trailer, as the name suggests, is a trailer equipped with cooling capabilities.
          It is ideal for keeping perishable goods, like food and beverages, chilled during
          transport or events. A coolroom trailer is a real lifesaver when it comes to keeping
          things frosty. Whether you're hosting a party, going on a road trip, or running a mobile
          catering business, a coolroom trailer ensures your items stay fresh and cool. It'll make
          sure your goodies stay fresh and cool throughout the journey or the event.
        </p>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h4 className={css.subheading}>What would I use a tipper trailer for?</h4>
        <p>
          A tipper trailer is specifically designed for convenient unloading of materials. With its
          hydraulic system, you can tilt the trailer bed and effortlessly dump stuff like gravel,
          sand, or soil. It's a real timesaver when you're working on a construction site,
          transporting bulk loose items, or sprucing up your backyard. Say goodbye to back-breaking
          unloading and hello to easy peasy tipping!
        </p>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h4 className={css.subheading}>How do I know what size boat trailer I need for my boat?</h4>
        <p>
          Choosing the right boat trailer size for your boat is crucial to ensure safe and efficient
          transportation. You want to make sure your boat fits snugly and safely. To figure out the
          right size, check your boat's length, weight, and type. Take a squiz at the boat
          manufacturer's specs or have a yarn with experienced boaties. They'll steer you in the
          right direction. Check out the details and specifications of the boat trailer on the
          listing page on Local Trailer Hire. If you're still unsure, simply send an enquiry to the
          trailer owner to make sure you get the perfect trailer for your boat.
        </p>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h4 className={css.subheading}>Do jetski trailers fit any sized jetski?</h4>
        <p>
          Jetski trailers come in all shapes and sizes, just like our wave-riding machines. Some
          trailers are specifically made for certain jetski models, while others can be adjusted to
          fit a range of sizes. Make sure to check the trailer details listed on Local Trailer Hire
          and see if it's a good match for your jetski. If you're in doubt, have a chinwag with the
          trailer owner. They'll make sure you're cruisin' with the right fit for your jetski, so
          you can hit the water with a big grin on your face! Remember, having a properly fitted
          trailer ensures the safe and secure transportation of your beloved jetski.
        </p>
      </div>
      <div className={css.specialtyTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('camping')}</li>
          <li>{categoryLink('car')}</li>
          <li>{categoryLink('motorbike')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};

SectionSpecialtyTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionSpecialtyTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSpecialtyTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSpecialtyTrailersComponent;
