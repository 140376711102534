import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from './localtrailerhire-logo-440w.png';
import InverseLogoImage from './lth-logo-white.svg';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, inverse, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  const image = inverse ? InverseLogoImage : LogoImage;

  if (format === 'desktop') {
    return <img className={className} src={image} alt={config.siteTitle} {...rest} />;
  }

  return <img className={mobileClasses} src={image} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string, bool } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  inverse: false,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
  inverse: bool,
};

export default Logo;
