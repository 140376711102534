import React from 'react';

import css from './SectionBookingRequirementsMaybe.module.css';

const SectionBookingRequirementsMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.minDuration) {
    return null;
  }

  return (
    <div className={css.sectionBookingRequirements}>
      <h2 className={css.bookingRequirementTitle}></h2>
    </div>
  );
};

export default SectionBookingRequirementsMaybe;
