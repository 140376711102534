import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { rootClassName, className, tooltipText, arrowDirection, isWarning } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tooltipClass = isWarning ? css.tooltipContentWarning : css.tooltipContent;

  return (
    <div className={classes}>
      <div className={css.tooltipWrapper}>
        {arrowDirection === 'up' ? <div className={css.arrowUp}></div> : null}
        <div className={tooltipClass}>{tooltipText}</div>
        {arrowDirection === 'down' ? <div className={css.arrowDown}></div> : null}
      </div>
    </div>
  );
};

Tooltip.defaultProps = { rootClassName: null, className: null };

Tooltip.propTypes = {
  rootClassName: string,
  className: string,
  arrowDirection: string,
  tooltipText: string.isRequired,
  isWarning: bool,
};

export default Tooltip;
