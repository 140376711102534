import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { validate as isValidUUID } from 'uuid';
import { userBySlug } from '../../util/api';
import * as log from '../../util/log';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const FETCH_SALES_FOR_SAME_CUSTOMER_REQUEST =
  'app/ProfilePage/FETCH_SALES_FOR_SAME_CUSTOMER_REQUEST';
export const FETCH_SALES_FOR_SAME_CUSTOMER_SUCCESS =
  'app/ProfilePage/FETCH_SALES_FOR_SAME_CUSTOMER_SUCCESS';
export const FETCH_SALES_FOR_SAME_CUSTOMER_ERROR =
  'app/ProfilePage/FETCH_SALES_FOR_SAME_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  salesForSameCustomer: [],
  fetchSalesForSameCustomerInProgress: false,
  fetchSalesForSameCustomerError: null,
};

const isServer = typeof window === 'undefined';

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case FETCH_SALES_FOR_SAME_CUSTOMER_REQUEST:
      return {
        ...state,
        fetchSalesForSameCustomerInProgress: true,
        fetchSalesForSameCustomerError: null,
      };
    case FETCH_SALES_FOR_SAME_CUSTOMER_SUCCESS:
      return {
        ...state,
        fetchSalesForSameCustomerInProgress: false,
        salesForSameCustomer: payload,
      };
    case FETCH_SALES_FOR_SAME_CUSTOMER_ERROR:
      log.error(payload, 'profile-page-fetch-sales-for-same-customer-failed');
      return {
        ...state,
        fetchSalesForSameCustomerInProgress: false,
        fetchSalesForSameCustomerError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

const fetchSalesForSameCustomerRequest = () => ({ type: FETCH_SALES_FOR_SAME_CUSTOMER_REQUEST });
const fetchSalesForSameCustomerSuccess = response => ({
  type: FETCH_SALES_FOR_SAME_CUSTOMER_SUCCESS,
  payload: response,
});
const fetchSalesForSameCustomerError = e => ({
  type: FETCH_SALES_FOR_SAME_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      if (!isServer) {
        const slug = response?.data?.data?.attributes?.profile?.publicData?.slug;
        if (slug && window?.location?.pathname !== `/u/${slug}`) {
          // Replace the current history state with the updated state
          window.history.replaceState({}, '', `${window.location.origin}/u/${slug}`);
        }
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

const finishLoadData = (dispatch, userId) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  // Robin: disabling this for now because it causes a flicker on
  // load because the browser clears the data and thus renders without
  // the data(after server side rendered with the data)
  // dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
    dispatch(fetchSalesForSameCustomer(userId)),
  ]);
};

export const loadData = params => async (dispatch, getState, sdk) => {
  if (isValidUUID(params.id)) {
    return finishLoadData(dispatch, new UUID(params.id));
  }

  return await userBySlug(params.id)
    .then(response => {
      if (!response) {
        dispatch(
          showUserError(
            storableError({ name: 'userNotFoundError', status: 404, statusText: 'User not found' })
          )
        );
      } else {
        return finishLoadData(dispatch, response.id);
      }
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'user-by-slug-failed', { slug: params.id });
      dispatch(showUserError(error));
    });
};

export const fetchSalesForSameCustomer = customerId => (dispatch, getState, sdk) => {
  const { isAuthenticated } = getState().Auth;
  if (!isAuthenticated) {
    return Promise.resolve(null);
  }

  dispatch(fetchSalesForSameCustomerRequest());

  return sdk.transactions
    .query({ only: 'sale', userId: customerId })
    .then(res => {
      dispatch(fetchSalesForSameCustomerSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchSalesForSameCustomerError(storableError(e)));
    });
};
