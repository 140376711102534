const states = [
  /New South Wales/,
  /Western Australia/,
  /Northern Territory/,
  /Australian Capital Territory/,
  /South Australia/,
  /Queensland/,
  /Tasmania/,
  /Victoria/,
];

const statesLongToShort = {
  Victoria: 'VIC',
  'New South Wales': 'NSW',
  'Western Australia': 'WA',
  'South Australia': 'SA',
  Tasmania: 'TAS',
  'Australian Capital Territory': 'ACT',
  'Northern Territory': 'NT',
  Queensland: 'QLD',
};

export const abbreviateState = stateLong => {
  return statesLongToShort[stateLong] ?? stateLong;
};

export const stateFromAddress = address => {
  for (const state of states) {
    if (state.test(address)) {
      return state.toString().replace(/\//g, '');
    }
  }
};

export const suburbFromAddress = address => {
  for (const state of states) {
    if (state.test(address)) {
      return trimCommasAndSpaces(address.split(state)[0]) // everything before the state
        .split(',')
        .pop()
        .trim();
    }
  }
  // throw new Error(`Unknown state in address ${address}`);
};

const trimCommasAndSpaces = str => str.replace(/^[\s\,]+|[\s\,]+$/gm, '');

const addressParts = address => {
  for (const state of states) {
    if (state.test(address)) {
      const splitAddress = address.split(state);
      return [
        ...trimCommasAndSpaces(splitAddress[0])
          .split(',')
          .map(s => s.trim()),
        state.toString().replace(/\//g, ''),
        ...trimCommasAndSpaces(splitAddress[1])
          .split(',')
          .map(s => s.trim()),
      ];
    }
  }
  return [address];
};

export const splitAddress = address => {
  const parts = addressParts(address);
  return {
    line1: parts[0],
    line2: parts.length > 5 ? parts[1] : undefined,
    line3: parts.length > 6 ? parts[2] : undefined,
    line4: parts.length > 7 ? parts[3] : undefined,
    line5: parts.length > 8 ? parts[4] : undefined,
    subLocality: parts.length > 9 ? parts[5] : undefined,
    suburb: parts.length > 4 ? parts[parts.length - 4] : undefined,
    state: parts.length > 3 ? parts[parts.length - 3] : undefined,
    postCode: parts.length > 2 ? parts[parts.length - 2] : undefined,
    country: parts.length > 1 ? parts[parts.length - 1] : undefined,
  };
};

const getAddressString = address => {
  switch (typeof address) {
    case 'string':
      return address;
    case 'object':
      return address.selectedPlace.address;
    case 'undefined':
      return undefined;
    default:
      throw new Error(`Unexpected address type ${typeof address} for value: ${address}`);
  }
};

export const getAddress = (building, address) => {
  if (!building || building.trim().length === 0) {
    return getAddressString(address);
  }
  return `${building}, ${getAddressString(address)}`;
};

export const listingToSuburbKey = listing => {
  try {
    const trailerAddress = this.getAddress(
      listing.attributes.publicData.location?.building,
      listing.attributes.publicData.location?.address
    );

    return `${this.suburbFromAddress(trailerAddress)}, ${this.stateFromAddress(trailerAddress)}`;
  } catch (err) {
    console.error('ERROR in listingToSuburbKey', err.stack || err);
    return undefined;
  }
};
