import React from 'react';
import { compose } from 'redux';
import Collapsible from 'react-collapsible';
import { withViewport } from '../../util/contextHelpers';
import { bool, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { propTypes } from '../../util/types';

import css from './SectionHowItWorks.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionHowItWorksComponent = props => {
  const { rootClassName, className, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <Collapsible
            open={!isMobileLayout}
            trigger={
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part1Title" />
              </h2>
            }
          >
            <p className={css.content}>
              <FormattedMessage id="SectionHowItWorks.part1Text" />
            </p>
          </Collapsible>
        </div>

        <div className={css.step}>
          <Collapsible
            open={!isMobileLayout}
            trigger={
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part2Title" />
              </h2>
            }
          >
            <p className={css.content}>
              <FormattedMessage id="SectionHowItWorks.part2Text" />
            </p>
          </Collapsible>
        </div>

        <div className={css.step}>
          <Collapsible
            open={!isMobileLayout}
            trigger={
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part3Title" />
              </h2>
            }
          >
            <p className={css.content}>
              <FormattedMessage id="SectionHowItWorks.part3Text" />
            </p>
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorksComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorksComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const SectionHowItWorks = compose(withViewport)(SectionHowItWorksComponent);

export default SectionHowItWorks;
