// import { storableError } from '../../util/errors';
import * as log from '../util/log';
import { callMeBack } from '../util/api';

// ================ Action types ================ //

export const CALL_ME_BACK_REQUEST = 'app/FlyersPage/CALL_ME_BACK_REQUEST';
export const CALL_ME_BACK_SUCCESS = 'app/FlyersPage/CALL_ME_BACK_SUCCESS';
export const CALL_ME_BACK_ERROR = 'app/FlyersPage/CALL_ME_BACK_ERROR';

// ================ Reducer ================ //

const initialState = {
  submitInProgress: false,
  callMeBackError: null,
  callMeBackSuccess: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CALL_ME_BACK_REQUEST:
      return {
        ...state,
        submitInProgress: true,
        callMeBackError: null,
        callMeBackSuccess: null,
      };
    case CALL_ME_BACK_SUCCESS:
      return {
        ...state,
        submitInProgress: false,
        callMeBackError: null,
        callMeBackSuccess: true,
      };
    case CALL_ME_BACK_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        submitInProgress: false,
        callMeBackSuccess: false,
        callMeBackError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const callMeBackRequest = () => ({
  type: CALL_ME_BACK_REQUEST,
  payload: {},
});

const callMeBackSuccess = response => ({
  type: CALL_ME_BACK_SUCCESS,
});

const callMeBackError = e => ({
  type: CALL_ME_BACK_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const sendCallMeBackRequest = (
  name,
  phoneNumber,
  reasonForCallBack,
  otherInfo,
  email
) => async (dispatch, getState, sdk) => {
  const bodyParams = {
    name,
    phoneNumber,
    reasonForCallBack,
    otherInfo,
    email,
    // params: {},
  };
  dispatch(callMeBackRequest());
  try {
    const response = await callMeBack({ bodyParams });
    dispatch(callMeBackSuccess(response));
  } catch (e) {
    log.error(e, 'submitting-call-back-request-failed');
    dispatch(callMeBackError(e));
  }
};
