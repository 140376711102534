import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-melbourne',
    predictionPlace: {
      address: 'Melbourne, Victoria',
      bounds: new LatLngBounds(
        new LatLng(-37.5112737225, 145.512528832),
        new LatLng(-38.433859306, 144.593741856)
      ),
      origin: new LatLng(-37.8142, 144.9632),
    },
  },
  {
    id: 'default-sydney',
    predictionPlace: {
      address: 'Sydney, New South Wales',
      bounds: new LatLngBounds(
        new LatLng(-33.508673889171405, 151.64275427216987),
        new LatLng(-34.2273261108286, 150.77724572783015)
      ),
      origin: new LatLng(-33.868, 151.21),
    },
  },
  {
    id: 'default-brisbane',
    predictionPlace: {
      address: 'Brisbane, Queensland',
      bounds: new LatLngBounds(
        new LatLng(-26.996844991, 153.31787024),
        new LatLng(-27.767436998, 152.668522848)
      ),
      origin: new LatLng(-27.469, 153.0235),
    },
  },
  {
    id: 'default-adelaide',
    predictionPlace: {
      address: 'Adelaide, South Australia',
      bounds: new LatLngBounds(
        new LatLng(-34.5680738891714, 139.038167688798587),
        new LatLng(-35.286726110828596, 138.1616323112014)
      ),
      origin: new LatLng(-34.9274, 138.5999),
    },
  },
  {
    id: 'default-perth',
    predictionPlace: {
      address: 'Perth, Western Australia',
      bounds: new LatLngBounds(
        new LatLng(-31.7730489445857, 116.07222566458621),
        new LatLng(-32.1323750554143, 115.64873433541378)
      ),
      origin: new LatLng(-31.952712, 115.86048),
    },
  },
];
export default defaultLocations;
