import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import { txIsFeeGstManuallyAdjusted, txIsPayoutManuallyAdjusted } from '../transaction';

const { Money } = sdkTypes;

const manualPayoutMoney = amount => new Money(amount * 100, config.currency);

export const lineItemManualCustomerCommissionTaxMaybe = (transaction, intl) => {
  const manualFeeGstLineItems = transaction.attributes.metadata?.feeGstAdjustment ?? 0;

  const formattedFeeGstLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.manualFeeGst" />
  ) : (
    'GST adjustment'
  );

  const formattedPayout = () => {
    if (!!manualFeeGstLineItems) {
      return !!intl
        ? formatMoney(intl, manualPayoutMoney(manualFeeGstLineItems))
        : `$${manualFeeGstLineItems.toFixed(2)}`;
    }
  };

  const showManualFeeGstAdjustment = txIsFeeGstManuallyAdjusted(transaction);

  return { label: formattedFeeGstLabel, price: formattedPayout(), showManualFeeGstAdjustment };
};
