import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { openingHoursForDayOfWeek } from '../../util/string-dates';

import css from './SectionOpeningHoursMaybe.module.css';

const SectionOpeningHoursMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const defaultOpeningHours = {
    entries: [
      { dayOfWeek: 'mon', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'tue', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'wed', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'thu', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'fri', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'sat', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'sun', startTime: '06:00', endTime: '22:00' },
    ],
  };
  const openingHours = publicData.openingHours || defaultOpeningHours;

  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const dayRow = dayOfWeek => {
    const hours = openingHoursForDayOfWeek(dayOfWeek, openingHours);
    return (
      <tr key={dayOfWeek}>
        <td className={css.dayOfWeek}>
          <FormattedMessage id={`EditListingAvailabilityPlanForm.dayOfWeek.${dayOfWeek}`} />
        </td>
        <td>{hours ? hours : 'None'}</td>
      </tr>
    );
  };

  return (
    <div className={css.sectionOpeningHours}>
      <h2 className={css.openingHoursTitle}>
        <FormattedMessage id="ListingPage.openingHoursTitle" />
      </h2>
      <table>
        <tbody>{daysOfWeek.map(dayRow)}</tbody>
      </table>
    </div>
  );
};

export default SectionOpeningHoursMaybe;
