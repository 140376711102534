/**
 * CurrencyInput renders an input field that format it's value according to currency formatting rules
 * onFocus: renders given value in unformatted manner: "9999,99"
 * onBlur: formats the given input: "9 999,99 €"
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import * as log from '../../util/log';

import css from './FieldCurrencyInput.module.css';

const { Money } = sdkTypes;

const allowedInputProps = allProps => {
  // Strip away props that are not passed to input element (or are overwritten)
  // eslint-disable-next-line no-unused-vars
  const { currencyConfig, defaultValue, intl, input, meta, ...inputProps } = allProps;
  return inputProps;
};

const FieldCurrencyInputComponent = ({
  className,
  input,
  meta,
  label,
  id,
  placeholder,
  currencyConfig,
  intl,
  defaultValue,
}) => {
  const classes = classNames(css.root, className);
  const inputClass = classNames(css.input, {
    [css.inputSuccess]: meta.valid,
    [css.inputError]: meta.hasError,
  });

  const initialValueIsMoney = input.value instanceof Money;
  // Check if initial value is a Money object

  if (initialValueIsMoney && input.value.currency !== currencyConfig.currency) {
    const e = new Error('Value currency different from marketplace currency');
    log.error(e, 'currency-input-invalid-currency', { currencyConfig, inputValue: input.value });
    throw e;
  }

  const inputProps = allowedInputProps({ ...input, currencyConfig, intl, meta, defaultValue });

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.currencyInputWrapper}>
        <span className={css.currencySymbol}>{currencyConfig.symbol || '$'}</span>
        <input
          className={inputClass}
          type="number"
          {...inputProps}
          placeholder={placeholder}
          step="0.01"
        />
      </div>
      {meta.touched && meta.error && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

FieldCurrencyInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldCurrencyInputComponent.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  name: PropTypes.string,
  label: PropTypes.string,

  // Generated by final-form's Field component
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const FieldCurrencyInput = injectIntl(FieldCurrencyInputComponent);

export default FieldCurrencyInput;
