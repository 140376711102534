import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../../components/';

import superOwnerIcon from '../../assets/super-owner-icon.png';

import css from './Avatar.module.css';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';
const AVATAR_SIZES_EXTRA_LARGE = '152px';

const AVATAR_IMAGE_VARIANTS = [
  // 40x40
  'square-xsmall',

  // 80x80
  'square-xsmall2x',

  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = props => {
  const {
    rootClassName,
    className,
    initialsClassName,
    user,
    renderSizes,
    disableProfileLink,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const isSuperOwner =
    avatarUser.attributes?.profile?.publicData &&
    avatarUser.attributes?.profile?.publicData?.superOwner;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  const defaultUserDisplayName = isBannedUser
    ? bannedUserDisplayName
    : isDeletedUser
    ? deletedUserDisplayName
    : '';

  const defaultUserAbbreviatedName = '';

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const displayNameWithSuperOwnerStatus = `${displayName}${
    isSuperOwner ? ' is a Super Owner' : ''
  }`;
  const rootProps = { className: classes, title: displayNameWithSuperOwnerStatus };
  const linkProps = avatarUser.id
    ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;

  const classForInitials = initialsClassName || css.initials;

  const renderAvatarContent = () => {
    if (isBannedUser || isDeletedUser) {
      return (
        <div {...rootProps}>
          <IconBannedUser className={css.bannedUserIcon} />
        </div>
      );
    } else if (hasProfileImage) {
      const imageComponent = (
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayNameWithSuperOwnerStatus}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      );
      return profileLinkEnabled ? (
        <NamedLink className={css.profileLink} {...linkProps}>
          {imageComponent}
        </NamedLink>
      ) : (
        <div>{imageComponent}</div>
      );
    } else {
      const initialsComponent = <span className={classForInitials}>{abbreviatedName}</span>;
      return profileLinkEnabled ? (
        <NamedLink className={css.profileLink} {...linkProps}>
          {initialsComponent}
        </NamedLink>
      ) : (
        <>{initialsComponent}</>
      );
    }
  };

  return (
    <div {...rootProps}>
      {renderAvatarContent()}
      {isSuperOwner && (
        <img
          src={superOwnerIcon}
          alt="this owner has the super owner badge"
          className={classNames(css.superOwnerBadge, {
            [css.superOwnerBadgeMedium]: renderSizes === AVATAR_SIZES_MEDIUM,
            [css.superOwnerBadgeLarge]: renderSizes === AVATAR_SIZES_LARGE,
            [css.superOwnerBadgeExtraLarge]: renderSizes === AVATAR_SIZES_EXTRA_LARGE,
          })}
        />
      )}
    </div>
  );
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  initialsClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  initialsClassName: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarMedium = props => (
  <Avatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => (
  <Avatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />
);
AvatarLarge.displayName = 'AvatarLarge';

export const AvatarExtraLarge = props => (
  <Avatar rootClassName={css.extraLargeAvatar} renderSizes={AVATAR_SIZES_EXTRA_LARGE} {...props} />
);
AvatarExtraLarge.displayName = 'AvatarExtraLarge';
