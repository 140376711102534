export const trailerCategoryUrls = {
  cage:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=cage',
  car:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=car',
  motorbike:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=motorbike',
  enclosed:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=enclosed',
  box:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=box',
  camping:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=camping',
  flatTop:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=flat-top',
  speciality:
    '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=plant-and-machinery%2Cboat%2Cspecialty',
};
// boat: '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=boat',
// machinery: '?address=Australia&bounds=-3.277086564834846%2C159.4202665952671%2C-48.192850418409755%2C109.55885861869491&origin=-25.7349684916223%2C134.489562606981&pub_category=plant-and-machinery',
