const { LINE_ITEM_UNITS } = require('./types');

export const getQuantity = (unitType, transaction) => {
  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  if (!unitPurchase) {
    throw new Error(`LineItemUnitsMaybe: lineItem (${unitType}) missing`);
  }

  return Math.trunc(unitPurchase.quantity);
};
