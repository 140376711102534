import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionForOwnersGuarantee.module.css';

const SectionForOwnersGuarantee = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const learnMoreButton = (
    <NamedLink className={css.learnMoreLink} name="OwnerTermsPage">
      <span className={css.learnMore}>
        <FormattedMessage id="SectionForOwnersGuarantee.learnMore" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <h2 className={css.subheading}>The Trailer Guarantee</h2>
      <p>
        It's nice to know you have a partner when hiring out your trailer. That's why we created the
        $2,000 trailer guarantee to give trailer owners peace of mind they can't get anywhere else.
      </p>
      <div className={css.learnMoreButtonContainer}>{learnMoreButton}</div>
    </div>
  );
};

SectionForOwnersGuarantee.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForOwnersGuarantee.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForOwnersGuarantee;
