import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// Customer can change booking request dates
export const TRANSITION_CHANGE_BOOKING_REQUEST = 'transition/change-booking-request';

// Provider received a reminder for pending booking request
export const TRANSITION_BOOKING_REQUEST_REMINDER_1 = 'transition/booking-request-reminder-1';
// Provider received a second reminder for pending booking request
export const TRANSITION_BOOKING_REQUEST_REMINDER_2 = 'transition/booking-request-reminder-2';
// Provider received a third reminder for pending booking request
export const TRANSITION_BOOKING_REQUEST_REMINDER_3 = 'transition/booking-request-reminder-3';

// Admin can also abort the transaction (cancel request).
export const TRANSITION_ABORT = 'transition/abort';

// Customer can abort the transaction (cancel request).
export const TRANSITION_ABORT_BY_CUSTOMER = 'transition/abort-by-customer';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Customer can cancel the transaction in Accepted state.
export const TRANSITION_CANCEL_BY_CUSTOMER = 'transition/cancel-by-customer';

// Admin can cancel the transaction in Accepted state.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CANCEL_FOR_CUSTOMER = 'transition/cancel-for-customer';
export const TRANSITION_CANCEL_BY_PROVIDER = 'transition/cancel-by-provider';
export const TRANSITION_CANCEL_AFTER_COMPLETE = 'transition/cancel-after-complete';
export const TRANSITION_CANCEL_AFTER_REVIEW_BY_CUSTOMER =
  'transition/cancel-after-review-by-customer';
export const TRANSITION_CANCEL_AFTER_REVIEW_BY_PROVIDER =
  'transition/cancel-after-review-by-provider';
export const TRANSITION_CANCEL_AFTER_REVIEWS = 'transition/cancel-after-reviews';

// The backend automatically expires the refundable period, moving from Accepted to Non-refundable state
export const TRANSITION_EXPIRE_REFUNDABLE_PERIOD = 'transition/expire-refundable-period';

// Admin can cancel the transaction in Non-refundable state
export const TRANSITION_CANCEL_FOR_PROVIDER_WITH_REFUND =
  'transition/cancel-for-provider-with-refund';
export const TRANSITION_CANCEL_FOR_CUSTOMER_WITH_REFUND =
  'transition/cancel-for-customer-with-refund';
export const TRANSITION_CANCEL_FOR_CUSTOMER_WITHOUT_REFUND =
  'transition/cancel-for-customer-without-refund';

// Customer can cancel the transaction in Non-refundable state
export const TRANSITION_CANCEL_BY_CUSTOMER_WITHOUT_REFUND =
  'transition/cancel-by-customer-without-refund';

// 24 hours after cancel without a refund, the payment is paid out
export const TRANSITION_PAYOUT_AFTER_CANCEL_WITHOUT_REFUND =
  'transition/payout-after-cancel-without-refund';

// Admin can trigger a refund after non-refundable booking is cancelled
export const TRANSITION_REFUND_AFTER_NON_REFUNDABLE_CANCELATION =
  'transition/refund-after-non-refundable-cancellation';

// Owner can change booking dates
export const TRANSITION_CHANGE_PAID_OUT_BOOKING = 'transition/change-paid-out-booking';
export const TRANSITION_CHANGE_ACCEPTED_BOOKING = 'transition/change-accepted-booking';
export const TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING = 'transition/change-non-refundable-booking';

// The backend will mark the transaction paid out.
export const TRANSITION_PAYOUT = 'transition/payout';
export const TRANSITION_PAYOUT_BEFORE_COMPLETE = 'transition/payout-before-complete';
export const TRANSITION_PAYOUT_AFTER_COMPLETE = 'transition/payout-after-complete';
export const TRANSITION_PAYOUT_AFTER_REVIEW_BY_CUSTOMER =
  'transition/payout-after-review-by-customer';
export const TRANSITION_PAYOUT_AFTER_REVIEW_BY_PROVIDER =
  'transition/payout-after-review-by-provider';
export const TRANSITION_PAYOUT_AFTER_REVIEWS = 'transition/payout-after-reviews';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_COMPLETE_BEFORE_PAYOUT = 'transition/complete-before-payout';
export const TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT = 'transition/complete-early-before-payout';
export const TRANSITION_COMPLETE_AFTER_PAYOUT = 'transition/complete-after-payout';
export const TRANSITION_COMPLETE_EARLY_AFTER_PAYOUT = 'transition/complete-early-after-payout';

export const TRANSITIONS_NO_PAYOUT = [
  TRANSITION_ABORT,
  TRANSITION_CANCEL,
  TRANSITION_EXPIRE,
  TRANSITION_DECLINE,
  TRANSITION_ABORT_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_FOR_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_FOR_PROVIDER_WITH_REFUND,
  TRANSITION_CANCEL_FOR_CUSTOMER_WITH_REFUND,
  TRANSITION_CANCEL_AFTER_COMPLETE,
  TRANSITION_CANCEL_AFTER_REVIEW_BY_CUSTOMER,
  TRANSITION_CANCEL_AFTER_REVIEW_BY_PROVIDER,
  TRANSITION_CANCEL_AFTER_REVIEWS,
  TRANSITION_REFUND_AFTER_NON_REFUNDABLE_CANCELATION,
];

export const TRANSITIONS_WITH_REFUND = [
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_FOR_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_AFTER_COMPLETE,
  TRANSITION_CANCEL_AFTER_REVIEW_BY_CUSTOMER,
  TRANSITION_CANCEL_AFTER_REVIEW_BY_PROVIDER,
  TRANSITION_CANCEL_AFTER_REVIEWS,
  TRANSITION_CANCEL_FOR_PROVIDER_WITH_REFUND,
  TRANSITION_CANCEL_FOR_CUSTOMER_WITH_REFUND,
  TRANSITION_REFUND_AFTER_NON_REFUNDABLE_CANCELATION,
];

export const TRANSITIONS_WITH_CAPTURE_CHARGE = [TRANSITION_ACCEPT];

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_REVIEW_1_BY_PROVIDER_NO_PAYOUT =
  'transition/review-1-by-provider-no-payout';
export const TRANSITION_REVIEW_2_BY_PROVIDER_NO_PAYOUT =
  'transition/review-2-by-provider-no-payout';
export const TRANSITION_REVIEW_1_BY_CUSTOMER_NO_PAYOUT =
  'transition/review-1-by-customer-no-payout';
export const TRANSITION_REVIEW_2_BY_CUSTOMER_NO_PAYOUT =
  'transition/review-2-by-customer-no-payout';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_NON_REFUNDABLE = 'non-refundable';
const STATE_CANCELED = 'canceled';
const STATE_CANCELED_PAYOUT_PENDING = 'cancelled-payout-pending';
const STATE_REVIEWS_OPEN = 'reviews-open';
const STATE_PAID_OUT = 'paid-out';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_REVIEWED_NO_PAYOUT = 'reviewed-no-payout';
const STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT = 'reviewed-by-customer-no-payout';
const STATE_REVIEWED_BY_PROVIDER_NO_PAYOUT = 'reviewed-by-provider-no-payout';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-hourly-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_ABORT]: STATE_DECLINED,
        [TRANSITION_ABORT_BY_CUSTOMER]: STATE_DECLINED,
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
        [TRANSITION_CHANGE_BOOKING_REQUEST]: STATE_PREAUTHORIZED,
        [TRANSITION_BOOKING_REQUEST_REMINDER_1]: STATE_PREAUTHORIZED,
        [TRANSITION_BOOKING_REQUEST_REMINDER_2]: STATE_PREAUTHORIZED,
        [TRANSITION_BOOKING_REQUEST_REMINDER_3]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL_BY_CUSTOMER]: STATE_CANCELED,
        [TRANSITION_CANCEL_FOR_CUSTOMER]: STATE_CANCELED,
        [TRANSITION_CANCEL_BY_PROVIDER]: STATE_CANCELED,
        [TRANSITION_PAYOUT]: STATE_PAID_OUT, // Removed as of early-returns process.
        [TRANSITION_PAYOUT_BEFORE_COMPLETE]: STATE_PAID_OUT, // Moved to STATE_NON_REFUNDABLE as of customer-cancel process.
        [TRANSITION_COMPLETE_BEFORE_PAYOUT]: STATE_REVIEWS_OPEN, // Moved to STATE_NON_REFUNDABLE as of customer-cancel process.
        [TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT]: STATE_REVIEWS_OPEN, // Moved to STATE_NON_REFUNDABLE as of customer-cancel process.
        [TRANSITION_CHANGE_ACCEPTED_BOOKING]: STATE_ACCEPTED,
        [TRANSITION_EXPIRE_REFUNDABLE_PERIOD]: STATE_NON_REFUNDABLE,
      },
    },

    [STATE_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_CANCEL_BY_CUSTOMER_WITHOUT_REFUND]: STATE_CANCELED_PAYOUT_PENDING,
        [TRANSITION_CANCEL_FOR_CUSTOMER_WITH_REFUND]: STATE_CANCELED,
        [TRANSITION_CANCEL_FOR_CUSTOMER_WITHOUT_REFUND]: STATE_CANCELED_PAYOUT_PENDING,
        [TRANSITION_CANCEL_FOR_PROVIDER_WITH_REFUND]: STATE_CANCELED,
        [TRANSITION_PAYOUT_BEFORE_COMPLETE]: STATE_PAID_OUT,
        [TRANSITION_COMPLETE_BEFORE_PAYOUT]: STATE_REVIEWS_OPEN,
        [TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT]: STATE_REVIEWS_OPEN,
        [TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING]: STATE_NON_REFUNDABLE,
      },
    },

    [STATE_CANCELED_PAYOUT_PENDING]: {
      on: {
        [TRANSITION_PAYOUT_AFTER_CANCEL_WITHOUT_REFUND]: STATE_CANCELED,
        [TRANSITION_REFUND_AFTER_NON_REFUNDABLE_CANCELATION]: STATE_CANCELED,
      },
    },

    [STATE_REVIEWS_OPEN]: {
      on: {
        [TRANSITION_PAYOUT_AFTER_COMPLETE]: STATE_DELIVERED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER_NO_PAYOUT]: STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT,
        [TRANSITION_REVIEW_1_BY_PROVIDER_NO_PAYOUT]: STATE_REVIEWED_BY_PROVIDER_NO_PAYOUT,
        [TRANSITION_CANCEL_AFTER_COMPLETE]: STATE_CANCELED,
      },
    },

    [STATE_PAID_OUT]: {
      on: {
        [TRANSITION_COMPLETE_AFTER_PAYOUT]: STATE_DELIVERED,
        [TRANSITION_COMPLETE_EARLY_AFTER_PAYOUT]: STATE_DELIVERED,
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
        [TRANSITION_CHANGE_PAID_OUT_BOOKING]: STATE_PAID_OUT,
      },
    },

    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER_NO_PAYOUT]: STATE_REVIEWED_NO_PAYOUT,
        [TRANSITION_PAYOUT_AFTER_REVIEW_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_CANCEL_AFTER_REVIEW_BY_CUSTOMER]: STATE_CANCELED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER_NO_PAYOUT]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER_NO_PAYOUT]: STATE_REVIEWED_NO_PAYOUT,
        [TRANSITION_PAYOUT_AFTER_REVIEW_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_CANCEL_AFTER_REVIEW_BY_PROVIDER]: STATE_CANCELED,
      },
    },

    [STATE_REVIEWED_NO_PAYOUT]: {
      on: {
        [TRANSITION_PAYOUT_AFTER_REVIEWS]: STATE_REVIEWED,
        [TRANSITION_CANCEL_AFTER_REVIEWS]: STATE_CANCELED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);
// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

export const transitionsToShowOnDashboard = [
  ...getTransitionsToState(STATE_PREAUTHORIZED),
  ...getTransitionsToState(STATE_ACCEPTED),
  ...getTransitionsToState(STATE_NON_REFUNDABLE),
  ...getTransitionsToState(STATE_PAID_OUT),
  ...getTransitionsToState(STATE_REVIEWS_OPEN),
  ...getTransitionsToState(STATE_DELIVERED),
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT),
  ...getTransitionsToState(STATE_ENQUIRY),
];

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsNonRefundable = tx =>
  getTransitionsToState(STATE_NON_REFUNDABLE).includes(txLastTransition(tx));

export const txIsPaidOut = tx =>
  getTransitionsToState(STATE_PAID_OUT).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  [
    ...getTransitionsToState(STATE_CANCELED_PAYOUT_PENDING),
    ...getTransitionsToState(STATE_CANCELED),
  ].includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsReviewsOpen = tx =>
  getTransitionsToState(STATE_REVIEWS_OPEN).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_NO_PAYOUT),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? [
        ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
        ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER_NO_PAYOUT),
      ].includes(txLastTransition(tx))
    : [
        ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
        ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER_NO_PAYOUT),
      ].includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  [
    ...getTransitionsToState(STATE_REVIEWED),
    ...getTransitionsToState(STATE_REVIEWED_NO_PAYOUT),
  ].includes(txLastTransition(tx));

export const txIsReviewableBy = (tx, isCustomer) => {
  return (
    txIsReviewsOpen(tx) ||
    txIsDelivered(tx) ||
    (txIsInFirstReviewBy(tx, !isCustomer) && !txIsReviewed(tx))
  );
};

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenCompleted = tx => {
  return hasPassedStateFn(STATE_DELIVERED)(tx) || hasPassedStateFn(STATE_REVIEWS_OPEN)(tx);
};

export const txHasBeenCanceled = tx => {
  return (
    hasPassedStateFn(STATE_CANCELED_PAYOUT_PENDING)(tx) || hasPassedStateFn(STATE_CANCELED)(tx)
  );
};

export const txHasBeenCompletedAndNotCanceled = tx => {
  return txHasBeenCompleted(tx) && !txHasBeenCanceled(tx);
};
export const txHasBeenPaidOut = tx => {
  return (
    hasPassedStateFn(STATE_PAID_OUT)(tx) ||
    hasPassedStateFn(STATE_DELIVERED)(tx) ||
    hasPassedStateFn(STATE_REVIEWED_BY_CUSTOMER)(tx) ||
    hasPassedStateFn(STATE_REVIEWED_BY_PROVIDER)(tx) ||
    hasPassedStateFn(STATE_REVIEWED)(tx)
  );
};

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  [
    ...getTransitionsToState(STATE_REVIEWED),
    ...getTransitionsToState(STATE_REVIEWED_NO_PAYOUT),
  ].includes(transition);

export const getReview1Transition = (isCustomer, hasBeenPaidOut) => {
  if (hasBeenPaidOut) {
    return isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;
  } else {
    return isCustomer
      ? TRANSITION_REVIEW_1_BY_CUSTOMER_NO_PAYOUT
      : TRANSITION_REVIEW_1_BY_PROVIDER_NO_PAYOUT;
  }
};

export const getReview2Transition = (isCustomer, hasBeenPaidOut) => {
  if (hasBeenPaidOut) {
    return isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;
  } else {
    return isCustomer
      ? TRANSITION_REVIEW_2_BY_CUSTOMER_NO_PAYOUT
      : TRANSITION_REVIEW_2_BY_PROVIDER_NO_PAYOUT;
  }
};

export const getChangeBookingTransition = transaction => {
  if (txIsAccepted(transaction)) {
    return TRANSITION_CHANGE_ACCEPTED_BOOKING;
  }
  if (txIsNonRefundable(transaction)) {
    return TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING;
  }
  if (txIsPaidOut(transaction)) {
    return TRANSITION_CHANGE_PAID_OUT_BOOKING;
  }
  if (txIsRequested(transaction)) {
    return TRANSITION_CHANGE_BOOKING_REQUEST;
  }
};

export const getCancelBookingTransition = transaction => {
  if (txIsRequested(transaction)) {
    return TRANSITION_ABORT_BY_CUSTOMER;
  }
  if (txIsAccepted(transaction)) {
    return TRANSITION_CANCEL_BY_CUSTOMER;
  }
  if (txIsNonRefundable(transaction)) {
    return TRANSITION_CANCEL_BY_CUSTOMER_WITHOUT_REFUND;
  }
};

export const getCompleteEarlyTransition = transaction => {
  // TODO: remove once all bookings are created in 'customer-cancel' transaction process
  if (txIsAccepted(transaction)) {
    return TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT;
  }
  if (txIsNonRefundable(transaction)) {
    return TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT;
  }
  if (txIsPaidOut(transaction)) {
    return TRANSITION_COMPLETE_EARLY_AFTER_PAYOUT;
  }
};

export const txIsPayoutManuallyAdjusted = transaction => {
  return !!transaction.attributes.metadata?.payoutAdjustment;
};
export const txIsFeeManuallyAdjusted = transaction => {
  return !!transaction.attributes.metadata?.feeAdjustment;
};
export const txIsFeeGstManuallyAdjusted = transaction => {
  return !!transaction.attributes.metadata?.feeGstAdjustment;
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    TRANSITION_CHANGE_ACCEPTED_BOOKING,
    TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING,
    TRANSITION_CHANGE_PAID_OUT_BOOKING,
    TRANSITION_CHANGE_BOOKING_REQUEST,
    TRANSITION_ABORT,
    TRANSITION_ABORT_BY_CUSTOMER,
    TRANSITION_CANCEL,
    TRANSITION_CANCEL_BY_CUSTOMER,
    TRANSITION_CANCEL_FOR_CUSTOMER,
    TRANSITION_CANCEL_BY_PROVIDER,
    TRANSITION_CANCEL_BY_CUSTOMER_WITHOUT_REFUND,
    TRANSITION_CANCEL_FOR_CUSTOMER_WITH_REFUND,
    TRANSITION_CANCEL_FOR_CUSTOMER_WITHOUT_REFUND,
    TRANSITION_CANCEL_FOR_PROVIDER_WITH_REFUND,
    TRANSITION_CANCEL_AFTER_COMPLETE,
    TRANSITION_CANCEL_AFTER_REVIEW_BY_CUSTOMER,
    TRANSITION_CANCEL_AFTER_REVIEW_BY_PROVIDER,
    TRANSITION_CANCEL_AFTER_REVIEWS,
    TRANSITION_PAYOUT,
    TRANSITION_PAYOUT_BEFORE_COMPLETE,
    TRANSITION_PAYOUT_AFTER_COMPLETE,
    TRANSITION_PAYOUT_AFTER_REVIEW_BY_CUSTOMER,
    TRANSITION_PAYOUT_AFTER_REVIEW_BY_PROVIDER,
    TRANSITION_PAYOUT_AFTER_REVIEWS,
    TRANSITION_COMPLETE,
    TRANSITION_COMPLETE_BEFORE_PAYOUT,
    TRANSITION_COMPLETE_EARLY_BEFORE_PAYOUT,
    TRANSITION_COMPLETE_AFTER_PAYOUT,
    TRANSITION_COMPLETE_EARLY_AFTER_PAYOUT,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_CUSTOMER_NO_PAYOUT,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_PROVIDER_NO_PAYOUT,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER_NO_PAYOUT,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER_NO_PAYOUT,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_CUSTOMER_NO_PAYOUT,
    TRANSITION_REVIEW_2_BY_CUSTOMER_NO_PAYOUT,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_PROVIDER_NO_PAYOUT,
    TRANSITION_REVIEW_2_BY_PROVIDER_NO_PAYOUT,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_CONFIRM_PAYMENT,
  ].includes(transition);
};

export const paymentConfirmedAt = transaction =>
  transaction.attributes.transitions.find(t => t.transition === TRANSITION_CONFIRM_PAYMENT)
    ?.createdAt;

export const txCanTransition = tx => userCanTransition(tx.customer);

export const userCanTransition = u => !u.attributes.banned && !u.attributes.deleted;
