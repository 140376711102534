import React from 'react';
import { string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemSubTotalMaybe } from '../../util/bookingBreakdown/lineItemSubTotalMaybe';

import css from './BookingBreakdown.module.css';

const LineItemSubTotalMaybe = props => {
  const { transaction, unitType, userRole, intl } = props;

  const { label, price, showSubTotal } = lineItemSubTotalMaybe(
    transaction,
    unitType,
    userRole,
    intl
  );

  return price && showSubTotal ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{price}</span>
      </div>
    </>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
