import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './SectionForBusinessHero.module.css';

import image from '../../assets/for-owners-hero.png';

const SectionForBusinessHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const createListingLink = (
    <NamedLink className={css.heroButton} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="SectionForBusinessHero.createListing" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroMainTitleDiv}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionForBusinessHero.title" />
          </h1>
          {createListingLink}
        </div>
        <div className={css.imageDiv}>
          <img src={image} alt="trailer" className={css.image} />
        </div>
      </div>
    </div>
  );
};

SectionForBusinessHero.defaultProps = { rootClassName: null, className: null };

SectionForBusinessHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForBusinessHero;
