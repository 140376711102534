import React from 'react';
import { Field } from 'react-final-form';
import { FieldSelect } from '..';
import config from '../../config';

import css from './FieldLeadTimeSelect.module.css';

const FieldLeadTimeSelect = props => {
  const { name, id } = props;
  const leadTimePlaceholder = 'Select the notice required for new booking requests';

  const leadTimeOptions = config.custom.leadTimeOptions;

  return (
    <Field name={name} id={id} parse={value => Number(value)}>
      {({ input }) => (
        <FieldSelect {...input} className={css.category}>
          <option disabled value="">
            {leadTimePlaceholder}
          </option>
          {leadTimeOptions.map(option => (
            <option key={option.key} value={option.minutes}>
              {option.label}
            </option>
          ))}
        </FieldSelect>
      )}
    </Field>
  );
};

export default FieldLeadTimeSelect;
