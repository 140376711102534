import React from 'react';
import { compose } from 'redux';
import { FieldTextInput, FieldCheckbox, LocationAutocompleteInputField } from '../../components';
import * as validators from '../../util/validators';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { bool, string, object } from 'prop-types';

const identity = v => v;

import css from './FieldGroupAddress.module.css';
import classNames from 'classnames';

const FieldGroupAddressComponent = props => {
  const { formId, addressValue, building, addressConfirmed, intl, fieldName } = props;

  const addressLabel = intl.formatMessage({
    id: 'FieldGroupAddress.addressLabel',
  });
  const addressPlaceholder = intl.formatMessage({
    id: 'FieldGroupAddress.addressPlaceholder',
  });
  const addressRequiredMessage = intl.formatMessage({
    id: 'FieldGroupAddress.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'FieldGroupAddress.addressNotRecognized',
  });

  const optionalText = intl.formatMessage({
    id: 'FieldGroupAddress.optionalText',
  });
  const buildingMessage = intl.formatMessage(
    {
      id: 'FieldGroupAddress.building',
    },
    { optionalText: optionalText }
  );
  const buildingPlaceholderMessage = intl.formatMessage({
    id: 'FieldGroupAddress.buildingPlaceholder',
  });
  const addressConfirmLabel = intl.formatMessage({
    id: 'FieldGroupAddress.addressConfirmLabel',
  });

  const types = ['address'];

  const startsWithHouseNumber = address => {
    return !address || /^\d+/.test(address?.trim());
  };
  const addressContainsNumber =
    !!building || startsWithHouseNumber(addressValue?.selectedPlace?.address);

  const addressConfirmedInfo = !addressContainsNumber ? (
    <>
      <span className={css.addressUnconfirmed}>
        Please enter a full address including house or building number.
      </span>
      {fieldName === 'residentialAddress' && (
        <FieldCheckbox
          id={formId ? `${formId}.addressConfirm` : 'addressConfirm'}
          name="addressConfirm"
          label={addressConfirmLabel}
          value="addressConfirmed"
        />
      )}

      {fieldName === 'location' && (
        <FieldCheckbox
          id={formId ? `${formId}.locationConfirm` : 'locationConfirm'}
          name="locationConfirm"
          label={addressConfirmLabel}
          value="locationConfirmed"
        />
      )}
    </>
  ) : null;

  const fieldGroupStyle = fieldName === 'residentialAddress' ? css.addressFieldGroup : null;
  const confirmAddressStyle =
    !addressConfirmed && !addressContainsNumber ? css.confirmAddress : null;

  const classes = classNames(css.root, confirmAddressStyle, fieldGroupStyle);

  return (
    <div className={classes}>
      <LocationAutocompleteInputField
        className={css.locationAddress}
        inputClassNames={[css.locationAutocompleteInput]}
        iconClassName={css.locationAutocompleteInputIcon}
        predictionsClassName={css.predictionsRoot}
        validClassName={css.validLocation}
        transparentBackground={true}
        name={fieldName}
        label={addressLabel}
        placeholder={addressPlaceholder}
        useDefaultPredictions={false}
        format={identity}
        valueFromForm={addressValue}
        validate={validators.composeValidators(
          validators.autocompleteSearchRequired(addressRequiredMessage),
          validators.notEmptyAddress(addressNotRecognizedMessage)
        )}
        id={formId ? `${formId}.${fieldName}` : fieldName}
        types={types}
      />

      <FieldTextInput
        type="text"
        name="building"
        id="building"
        label={buildingMessage}
        placeholder={buildingPlaceholderMessage}
      />

      {addressConfirmedInfo}
    </div>
  );
};

FieldGroupAddressComponent.propTypes = {
  formId: string,
  building: string,
  addressValue: object,
  addressConfirmed: bool,
  intl: intlShape.isRequired,
  fieldName: string.isRequired,
};

FieldGroupAddressComponent.defaultProps = { fieldName: 'residentialAddress' };

const FieldGroupAddress = compose(injectIntl)(FieldGroupAddressComponent);
FieldGroupAddress.displayName = 'FieldGroupAddress';

export default FieldGroupAddress;
