import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import { Button } from '../../components';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import css from './SectionOwnerPrintables.module.css';

import newStickerDesign from './assets/trailer-sticker-2023-design-low-res.png';

const SectionOwnerPrintablesComponent = props => {
  const { rootClassName, className, qrCodeShortUrl, currentUser } = props;
  const classes = classNames(rootClassName || className);
  const slug = currentUser?.attributes?.profile?.publicData?.slug;
  const slugOrUserId = !!slug ? slug : currentUser?.id?.uuid;
  const customUrl = `https://${process.env.REACT_APP_DOMAIN}/u/${slugOrUserId}`;
  const fileName = !!slug
    ? `Local Trailer Hire – ${slug} QR Code Sticker.pdf`
    : `Local Trailer Hire – QR Code Sticker.pdf`;

  const handleDownloadQRCode = () => {
    const doc = new jsPDF({ unit: 'mm', format: [90, 90] });

    const element = document.getElementById('qr-code');
    const x = 5;
    const y = 5;
    const width = 80;
    const height = 80;

    window.gtag?.('event', 'download_qr_code_sticker');

    doc
      .svg(element, {
        x,
        y,
        width,
        height,
      })
      .then(() => {
        doc.save(fileName);
      });
  };

  return (
    <React.Fragment>
      <div className={classes}>
        <h3 className={css.heading}>Print your own customised stickers</h3>

        <p>
          Make it easy for people to book your trailer by adding your own QR code stickers to your
          trailer. This QR code directs people straight to <a href={customUrl}>your profile</a>.
        </p>

        <ol className={css.downloadList}>
          <li>
            Download your QR code artwork file.
            <div className={css.qrCodeWrapper}>
              <QRCodeSVG
                id="qr-code"
                value={qrCodeShortUrl}
                size={70}
                level={'H'}
                includeMargin={false}
                className={css.qrCode}
              />
              <div className={css.qrCodeColumn}>
                <Button className={css.downloadButton} onClick={handleDownloadQRCode}>
                  Download file
                </Button>
              </div>
            </div>
          </li>
          <li>
            Order your{' '}
            <a href="https://www.stickeroo.com.au/products/squarestickers">
              9cm square stickers with rounded corners from Stickeroo
            </a>
            . Choose the file from step 1.{' '}
          </li>
          <li>
            If you have the new Local Trailer Hire stickers with a QR code on them already, you can
            stick your QR codes over the top!
            <br />
            <img
              src={newStickerDesign}
              alt="sticker for trailer with QR code"
              className={css.image}
            />
            <br />
            Or, stick your QR codes on your trailer next to your Local Trailer Hire stickers.
          </li>
        </ol>
      </div>
    </React.Fragment>
  );
};
SectionOwnerPrintablesComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionOwnerPrintablesComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionOwnerPrintablesComponent.propTypes = {
  rootClassName: string,
  className: string,
  qrCodeShortUrl: string,
  slug: string,
};

export default SectionOwnerPrintablesComponent;
