import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionMotorbikeTrailers.module.css';

const SectionMotorbikeTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.motorbikeTrailersContent}>
        <h3 className={css.heading}>
          How to find the right motorbike trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired motorbike trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.motorbikeTrailersContent}>
        <h3 className={css.heading}>Motorbike trailers FAQ</h3>
        <h4 className={css.subheading}>What is a motorbike trailer?</h4>
        <p>
          Motorbike trailers are designed specifically for hauling motorcycles. They often have
          features like wheel chocks, tie-down points, and ramps to make loading and unloading bikes
          easier. They've got all the features you need to keep your bikes safe and secure during
          the journey. These trailers come in different sizes and setups to fit various types of
          motorcycles. So, if you need to transport your trusty two-wheeler to a race, bike event,
          or new digs, hiring a motorbike trailer from Local Trailer Hire is the way to go!
        </p>
      </div>
      <div className={css.motorbikeTrailersContent}>
        <h4 className={css.subheading}>What can a motorbike trailer be used for?</h4>
        <p>
          Motorbike trailers are perfect for transporting motorcycles to races, bike events, or when
          moving to a new location. They provide a safe and secure way to transport bikes without
          the need for riding them long distances. Whether you're a passionate biker attending a
          motocross event or relocating with your beloved two-wheeler, you can find a suitable
          motorbike trailer for hire on Local Trailer Hire.
        </p>
      </div>
      <div className={css.motorbikeTrailersContent}>
        <h4 className={css.subheading}>Can anyone operate a motorbike trailer?</h4>
        <p>
          As long as you have a vehicle with a suitable tow ball and sufficient towing capacity, you
          can operate a motorbike trailer. If you have any doubts or questions, reach out to the
          trailer owner on Local Trailer Hire to clarify the requirements.
        </p>
      </div>

      <div className={css.motorbikeTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right size motorbike trailer?</h4>
        <p>
          Choosing the right size motorbike trailer depends on the number and size of motorcycles
          you intend to transport. Consider the dimensions and weight of your bikes to ensure they
          will fit comfortably and safely on the trailer. Motorbike trailers have varying weight
          capacities and configurations, so it's important to select one that meets your specific
          needs. Local Trailer Hire provides detailed information about the trailer's weight
          capacity, dimensions, and features, allowing you to make an informed decision that suits
          your motorbike transport requirements.
        </p>
      </div>
      <div className={css.motorbikeTrailersContent}>
        <h4 className={css.subheading}>
          Why are the motorbike trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          The prices of motorbike trailers on Local Trailer Hire can vary since each trailer owner
          sets their own rates. The price usually reflects things like the trailer's size,
          condition, and extra features. Features on motorbike trailers may include ramps, tie-down
          points, and additional security measures. The price may also be influenced by the
          trailer's weight capacity and overall quality. To find a motorbike trailer within your
          budget, you can use the search filters on Local Trailer Hire to refine your options and
          compare prices.
        </p>
      </div>
      <div className={css.motorbikeTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('car')}</li>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('enclosed')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};
SectionMotorbikeTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionMotorbikeTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionMotorbikeTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMotorbikeTrailersComponent;
