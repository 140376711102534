import React from 'react';
import Invoice from '../Invoice/Invoice';
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape, injectIntl } from '../../util/reactIntl';

import css from './DownloadInvoiceButton.module.css';

const DownloadInvoiceButtonComponent = props => {
  const { transaction, userRole, unitType, booking, dateType, timeZone, intl } = props;

  const document = (
    <Invoice
      transaction={transaction}
      userRole={userRole}
      unitType={unitType}
      booking={booking}
      dateType={dateType}
      timeZone={timeZone}
      intl={intl}
    />
  );

  const [instance, updateInstance] = usePDF({ document: document });

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {error}</div>;

  return (
    <a href={instance.url} download="localtrailerhire-invoice.pdf" className={css.downloadButton}>
      Download invoice
    </a>
  );
};

const { string, oneOf } = PropTypes;

DownloadInvoiceButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  dateType: null,
  timeZone: null,
};

DownloadInvoiceButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape,
};

const DownloadInvoiceButton = injectIntl(DownloadInvoiceButtonComponent);

DownloadInvoiceButton.displayName = 'DownloadInvoiceButton';

export default DownloadInvoiceButton;
