import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemTotalPrice } from '../../util/bookingBreakdown/lineItemTotalPrice';
import { styles } from '../Invoice/InvoiceStyles';

const styleSheet = styles;

const LineItemTotalPricePdf = props => {
  const { transaction, isProvider, intl } = props;
  const { label, price, zeroTotal, totalCommissionTax } = lineItemTotalPrice(
    transaction,
    isProvider
  );

  return (
    <>
      <View
        style={[
          styleSheet.row,
          styleSheet.boldFont,
          styleSheet.row,
          styleSheet.borderTop,
          styleSheet.marginAboveLarge,
          styleSheet.paddingAboveLarge,
        ]}
      >
        <View style={styleSheet.tColumnLeftWide}>
          <Text>{label}</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>{price}</Text>
        </View>
      </View>
      {zeroTotal ? null : (
        <View style={styleSheet.row}>
          <View style={styleSheet.tColumnLeftWide}>
            <Text>Includes GST of</Text>
          </View>
          <View style={styleSheet.rightAligned}>
            <Text>{totalCommissionTax}</Text>
          </View>
        </View>
      )}
    </>
  );
};

LineItemTotalPricePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape,
};

export default LineItemTotalPricePdf;
