import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionCageTrailers.module.css';

const SectionCageTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.cageTrailersContent}>
        <h3 className={css.heading}>
          How to find the right cage trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired cage trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.cageTrailersContent}>
        <h3 className={css.heading}>Cage trailers FAQ</h3>
        <h4 className={css.subheading}>What is a cage trailer?</h4>
        <p className={css.paragraph}>
          If you're looking to move some bulky items or need to transport materials or equipment, a
          cage trailer might be the solution. A cage trailer is a type of trailer with a high cage
          or mesh sides and an open top, providing extra security and safety for your load while
          being towed. These trailers are designed to be towed by a vehicle, usually a car or ute,
          and come in various sizes and styles.
        </p>
      </div>
      <div className={css.cageTrailersContent}>
        <h4 className={css.subheading}>What can a cage trailer be used for?</h4>
        <p className={css.paragraph}>
          Cage trailers are versatile and can be used to transport a range of items, such as green
          waste, gardening tools, timber, furniture, and household appliances. The cage structure
          allows you to stack items safely without worrying about them falling off the trailer
          during transport. If you're into camping or fishing, you can even use a cage trailer to
          transport your gear.
        </p>
      </div>
      <div className={css.cageTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right size cage trailer?</h4>
        <p>
          Selecting the right size cage trailer is essential, and there are a few factors to
          consider when making your decision. Firstly, consider the size and weight of the items you
          want to transport. Secondly, think about the type of vehicle you have and its towing
          capacity. The size of the cage trailer will also determine the amount of space you have
          available to carry your load. Generally, cage trailers come in sizes ranging from 6x4ft to
          10x6ft. You can use the search filters on Local Trailer Hire to find a cage trailer that
          is the size you need.
        </p>
      </div>
      <div className={css.cageTrailersContent}>
        <h4 className={css.subheading}>
          Why are the cage trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          Each trailer owner sets the price for their trailer, so the price of a cage trailer will
          vary. Trailer owners generally set their price depending on the size of the trailer, the
          condition of the trailer, and the type of trailer. You can use the search filters on Local
          Trailer Hire to find a cage trailer that is the price you need.
        </p>
      </div>
      <div className={css.cageTrailersContent}>
        <h4 className={css.subheading}>Are there different types of cage trailers?</h4>
        <p>
          There are several types of cage trailers available, and each has its own unique features.
          For example, some cage trailers have a tilt mechanism that allows you to easily load and
          unload your cargo. Others come with brakes, which are useful when towing heavier loads.
          Some cage trailers even have toolboxes or spare wheel holders attached for added
          convenience.
        </p>
      </div>
      <div className={css.cageTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('box')}</li>
          <li>{categoryLink('enclosed')}</li>
          <li>{categoryLink('car')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};
SectionCageTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCageTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCageTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCageTrailersComponent;
