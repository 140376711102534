import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';

import css from './SectionSuperOwnerStats.module.css';
import IconSuccess from '../IconSuccess/IconSuccess';
import NamedLink from '../NamedLink/NamedLink';

const SectionSuperOwnerStats = props => {
  const { currentUser } = props;

  const tipsLink = <NamedLink name="TipsPage">Tips Section</NamedLink>;
  const listingsLink = <NamedLink name="ManageListingsPage">Listings availability </NamedLink>;

  if (
    currentUser.attributes.profile.privateData?.responseStatsLast12Months?.superOwnerCriteria ===
      undefined ||
    currentUser.attributes.profile.privateData?.responseStatsLast12Months?.superOwnerCriteria
      ?.numHires === 0
  ) {
    return (
      <div className={css.section}>
        <p>
          Super Owners are top-rated, experienced owners who provide exceptional service and make
          hiring easy. Super Owner status is updated daily, based on your booking data from the past
          12 months. When you achieve Super Owner status, a special badge will appear on your
          trailer listings and profile.
        </p>
        <p>
          We don't have any data for you yet. Come back after your first hire to check your stats.
        </p>
        <p>Check out the {tipsLink} if you're looking to get more hires!</p>
      </div>
    );
  }

  const superOwner = currentUser.attributes.profile.publicData?.superOwner;
  const {
    acceptanceRateSuperOwnerCriterion,
    acceptanceRateSuperOwnerValue,
    avgReviewRatingSuperOwnerCriterion,
    avgReviewRatingSuperOwnerValue,
    numHires,
    numHiresSuperOwnerCriterion,
    meanBookingResponseDelayExcludingOutliersCriterion,
    meanBookingResponseDelayExcludingOutliersValue,
  } = currentUser.attributes.profile.privateData?.responseStatsLast12Months?.superOwnerCriteria;

  const bookingResponseDelayTimeString = meanBookingResponseDelayExcludingOutliersValue;
  // convert bookingResponseDelayTimeString to number of minutes
  const duration = moment.duration(bookingResponseDelayTimeString);
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const bookingResponseDelayTotalMinutes = hours * 60 + minutes + seconds / 60;

  const numCriteriaMet =
    acceptanceRateSuperOwnerCriterion +
    avgReviewRatingSuperOwnerCriterion +
    numHiresSuperOwnerCriterion +
    meanBookingResponseDelayExcludingOutliersCriterion;

  return (
    <div className={css.section}>
      <p>
        Super Owners are top-rated, experienced owners who provide exceptional service and make
        hiring easy. Super Owner status is updated daily, based on your booking data from the past
        12 months. When you achieve Super Owner status, a special badge will appear on your trailer
        listings and profile.
      </p>

      {superOwner ? (
        <>
          <p>Congratulations! You're a Super Owner! 🎉 </p>
          <p>Check out your stats below and keep up the good work!</p>
        </>
      ) : numCriteriaMet > 0 ? (
        <p>
          You're doing great! You've already met {numCriteriaMet} out of 4 criteria to become a
          Super Owner. Take a look at your stats below to see how close you are to achieving Super
          Owner status.
        </p>
      ) : (
        <p>Take a look at your stats below to see how you can build towards Super Owner status.</p>
      )}
      <div>
        <h3 className={css.dashboardSectionSubTitle}>Number of Hires</h3>
        <p>
          {numHiresSuperOwnerCriterion ? (
            <>
              <IconSuccess className={css.successIcon} />
              <FormattedMessage
                id="SectionSuperOwnerStats.numHiresMet"
                values={{
                  numHires,
                  meetingOrExceeding: numHires === 5 ? 'meeting' : 'exceeding',
                }}
              />
            </>
          ) : (
            <FormattedMessage
              id="SectionSuperOwnerStats.numHiresUnmet"
              values={{ numHires, tipsLink }}
            />
          )}
        </p>
        {!numHiresSuperOwnerCriterion ? (
          <div className={css.progressBar}>
            <div
              className={css.progressFill}
              style={{
                width: `${(numHires / 5) * 100}%`,
                backgroundColor: '#ffaa00',
              }}
            />
          </div>
        ) : null}
      </div>
      <div>
        <h3 className={css.dashboardSectionSubTitle}>Review Rating</h3>
        <p>
          {avgReviewRatingSuperOwnerCriterion ? (
            <>
              <IconSuccess className={css.successIcon} />
              <FormattedMessage
                id="SectionSuperOwnerStats.reviewRatingMet"
                values={{
                  avgReviewRating: avgReviewRatingSuperOwnerValue.toFixed(2),
                  meetsOrExceeds:
                    parseFloat(avgReviewRatingSuperOwnerValue) === 4.5 ? 'meets' : 'exceeds',
                }}
              />
            </>
          ) : avgReviewRatingSuperOwnerValue === null ? (
            <FormattedMessage id="SectionSuperOwnerStats.reviewRatingUnmetNoReviews" />
          ) : (
            <FormattedMessage
              id="SectionSuperOwnerStats.reviewRatingUnmet"
              values={{ avgReviewRating: avgReviewRatingSuperOwnerValue.toFixed(2) }}
            />
          )}
        </p>
        {!avgReviewRatingSuperOwnerCriterion ? (
          <div className={css.progressBar}>
            <div
              className={css.progressFill}
              style={{
                width: `${(avgReviewRatingSuperOwnerValue / 5) * 100}%`,
                backgroundColor: '#ffaa00',
              }}
            />
            <div
              className={css.targetMarker}
              style={{
                left: `${(4.5 / 5) * 100}%`,
              }}
            />
          </div>
        ) : null}
      </div>
      <div>
        <h3 className={css.dashboardSectionSubTitle}>Acceptance Rate</h3>
        <p>
          {acceptanceRateSuperOwnerCriterion ? (
            <>
              <IconSuccess className={css.successIcon} />
              <FormattedMessage
                id="SectionSuperOwnerStats.acceptanceRateMet"
                values={{
                  acceptanceRate: Math.floor(acceptanceRateSuperOwnerValue),
                  meetingOrExceeding:
                    Math.floor(acceptanceRateSuperOwnerValue) === 90 ? 'meeting' : 'exceeding',
                }}
              />
            </>
          ) : (
            <FormattedMessage
              id="SectionSuperOwnerStats.acceptanceRateUnmet"
              values={{
                acceptanceRate: Math.floor(acceptanceRateSuperOwnerValue),
                listingsLink,
              }}
            />
          )}
        </p>
        {acceptanceRateSuperOwnerValue !== 100 ? (
          <div className={css.progressBar}>
            <div
              className={css.progressFill}
              style={{
                width: `${acceptanceRateSuperOwnerValue}%`,
                backgroundColor: acceptanceRateSuperOwnerValue >= 90 ? '#2ecc71' : '#ffaa00',
              }}
            />
            <div
              className={css.targetMarker}
              style={{
                left: '90%',
              }}
            />
          </div>
        ) : null}
      </div>
      <div>
        <h3 className={css.dashboardSectionSubTitle}>Response Delay</h3>
        <p>
          {meanBookingResponseDelayExcludingOutliersCriterion ? (
            <>
              <IconSuccess className={css.successIcon} />
              <FormattedMessage
                id="SectionSuperOwnerStats.responseDelayMet"
                values={{
                  bookingResponseDelayTotalMinutes: Math.ceil(bookingResponseDelayTotalMinutes),
                }}
              />
            </>
          ) : (
            <FormattedMessage
              id="SectionSuperOwnerStats.responseDelayUnmet"
              values={{
                bookingResponseDelayTotalMinutes: Math.ceil(bookingResponseDelayTotalMinutes),
              }}
            />
          )}
        </p>
        {!meanBookingResponseDelayExcludingOutliersCriterion ? (
          <div className={css.progressBar}>
            {/* Green part of the progress bar up to the target */}
            <div
              className={css.progressFill}
              style={{
                width: `${(30 / bookingResponseDelayTotalMinutes) * 100}%`,
                backgroundColor: '#2ecc71',
                height: '100%',
                position: 'absolute',
              }}
            />

            {/* Orange part of the progress bar after the target */}
            <div
              className={css.progressFill}
              style={{
                width: `${100 - (30 / bookingResponseDelayTotalMinutes) * 100}%`,
                backgroundColor: '#ffaa00',
                height: '100%',
                position: 'absolute',
                left: `${(30 / bookingResponseDelayTotalMinutes) * 100}%`,
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
            />
            <div
              className={css.targetMarker}
              style={{
                left: `${(30 / bookingResponseDelayTotalMinutes) * 100}%`,
              }}
            >
              <span className={css.targetLabel}>30</span>
            </div>
            {bookingResponseDelayTotalMinutes > 34 ? (
              <span className={css.targetLabel}>{Math.ceil(bookingResponseDelayTotalMinutes)}</span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionSuperOwnerStats;
