import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionEnclosedTrailers.module.css';

const SectionEnclosedTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.enclosedTrailersContent}>
        <h3 className={css.heading}>
          How to find the right enclosed trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired enclosed trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.enclosedTrailersContent}>
        <h3 className={css.heading}>Enclosed trailers FAQ</h3>
        <h4 className={css.subheading}>What is an enclosed trailer?</h4>
        <p>
          An enclosed trailer is a type of trailer with a closed structure designed to protect your
          cargo from weather, theft, and damage during transport. These trailers can come in various
          sizes and are commonly used for hauling goods that require extra protection, especially
          during bad weather. Enclosed trailers can be either fully enclosed or partially enclosed,
          and they may come with features such as ramps, tie-down points, and shelving to make
          loading and unloading easier. Whether you're transporting delicate items, moving during a
          rainy season, or don't want to use a tarp, an enclosed trailer can offer the peace of mind
          you need to ensure your cargo arrives safely.
        </p>
      </div>
      <div className={css.enclosedTrailersContent}>
        <h4 className={css.subheading}>What could I use an enclosed trailer for?</h4>
        <p>
          An enclosed trailer is the perfect way to transport all your gear, toys, and treasures
          with ease! Load up your bikes, camping gear, and fishing equipment for a weekend in the
          great outdoors. Need to move your favourite gaming chair or precious family heirlooms? No
          problem! With an enclosed trailer, your cargo is safe from the elements and protected from
          prying eyes. So whether you're hauling your stuff for work or play, an enclosed trailer is
          a simple and versatile way to go.
        </p>
      </div>
      <div className={css.enclosedTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right size enclosed trailer?</h4>
        <p>
          Choosing the right size enclosed trailer can make all the difference in getting your cargo
          safely from point A to point B. First, measure the items you need to transport, including
          their height, to determine the minimum size trailer you'll need. You can use the size
          filters on Local Trailer Hire to narrow down your search to trailers that are the right
          size. Check the internal dimensions of the trailer you're interested in. The trailer
          listing will have internal trailer dimension information, making it easy to compare
          different options. If you're not sure from the listing, don't hesitate to send an enquiry
          to the trailer owner to get more details. Keep in mind that the listed dimensions may not
          include the space taken up by any cabinets or shelving, so be sure to factor that in when
          calculating how much space you'll need. By taking the time to choose the right size
          enclosed trailer, you can ensure that your cargo is transported safely and efficiently.
        </p>
      </div>
      <div className={css.enclosedTrailersContent}>
        <h4 className={css.subheading}>
          Why are the enclosed trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          The cost of hiring an enclosed trailer listed on Local Trailer Hire is determined by the
          trailer owner. The size, condition, and any extra features like shelving and cabinets can
          all be factors for deciding on the price. But don't worry! You can easily find a trailer
          that fits your budget by using the search filters on Local Trailer Hire. Simply set your
          price range and let the site do the rest. With so many options available, you're sure to
          find an enclosed trailer that meets your needs without breaking the bank.
        </p>
      </div>
      <div className={css.enclosedTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('box')}</li>
          <li>{categoryLink('car')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};

SectionEnclosedTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionEnclosedTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionEnclosedTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionEnclosedTrailersComponent;
