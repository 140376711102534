import React, { useState } from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { Button, FieldSelect, FieldTextInput, Form } from '../../components';

import css from './DeclineMessageForm.module.css';

const DeclineMessageFormComponent = props => {
  const [showOptionalMessageFieldState, setShowOptionalMessageFieldState] = useState(false);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          handleSubmit,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          formId,
        } = formRenderProps;

        const { intl } = props;
        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;

        const personalMessageLabel = intl.formatMessage({ id: 'DeclineMessageForm.messageLabel' });
        const declineMessagePlaceholder = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsPlaceholder',
        });
        const declineOptionsLabel = intl.formatMessage({
          id: 'DeclineMessageForm.declineOptionsLabel',
        });
        const handleShowOptionalMessageChange = value => {
          setShowOptionalMessageFieldState(value === 'other');
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldSelect
              className={css.declineMessage}
              id={formId ? `${formId}.declineMessage` : 'declineMessage'}
              name="declineMessage"
              label={declineOptionsLabel}
              // validate={declineMessageRequired}
              onChange={handleShowOptionalMessageChange}
            >
              <option disabled value="">
                {declineMessagePlaceholder}
              </option>
              {config.custom.declineMessageOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>
            {showOptionalMessageFieldState ? (
              <FieldTextInput
                className={css.message}
                inputRootClass={css.messageInput}
                hotjarWhitelist={true}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                label={personalMessageLabel}
              />
            ) : null}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

DeclineMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

DeclineMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
};

const DeclineMessageForm = DeclineMessageFormComponent;

export default DeclineMessageForm;
