import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../types';

const label = (unitType, intl) => {
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.pricePerNight'
    : isDaily
    ? 'BookingBreakdown.pricePerDay'
    : 'BookingBreakdown.pricePerQuantity';

  const unitPurchaseLabel = <FormattedMessage id={translationKey} />;

  return unitPurchaseLabel;
};

export const lineItemUnitPriceMaybe = (transaction, unitType, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const unitPurchaseLabel = !!intl ? label(unitType, intl) : 'Price per day (24 hours)';

  const unitPrice = unitPurchase ? unitPurchase.unitPrice : null;
  const formattedUnitPrice = !!intl
    ? formatMoney(intl, unitPrice)
    : `$${(unitPrice.amount / 100).toFixed(2)}`;

  if (unitPurchase) {
    return { label: unitPurchaseLabel, price: formattedUnitPrice };
  }
};
