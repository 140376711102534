import classNames from 'classnames';
import React from 'react';
import { Button, InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TransactionPanel.module.css';

const ChangeButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButton,
    onClick,
    onCancelClick,
    showCancelLink,
    onReturnedEarlyClick,
    showReturnedEarlyButton,
    cancelBookingError,
    isBookingRequest,
  } = props;

  const classes = classNames(rootClassName || css.changeButtonWrapper, className);
  const bookingType = isBookingRequest ? 'booking request' : 'booking';
  const buttons = showButton ? (
    <div className={classes}>
      <Button onClick={onClick} className={css.changeButton}>
        <FormattedMessage id="TransactionPanel.changeButton" />
      </Button>

      {showReturnedEarlyButton ? (
        <div className={css.changeButtonLinkContainer}>
          <InlineTextButton onClick={onReturnedEarlyClick} className={css.changeButtonLink}>
            <FormattedMessage id="TransactionPanel.returnedEarlyButton" />
          </InlineTextButton>
        </div>
      ) : null}
    </div>
  ) : null;

  const cancelLink = showCancelLink ? (
    <div className={css.changeButtonLinkContainer}>
      <InlineTextButton className={css.cancelButtonLink} onClick={onCancelClick}>
        {isBookingRequest ? (
          <FormattedMessage id="TransactionPanel.cancelRequestButton" />
        ) : (
          <FormattedMessage id="TransactionPanel.cancelButton" />
        )}
      </InlineTextButton>
    </div>
  ) : null;

  const cancelErrors = cancelBookingError ? (
    <p className={css.cancelBookingError}>
      {cancelBookingError.status === 409 ? (
        <FormattedMessage
          id="TransactionPanel.cancelBookingFailedDueToConflict"
          values={{ bookingType }}
        />
      ) : (
        <FormattedMessage id="TransactionPanel.cancelBookingFailed" values={{ bookingType }} />
      )}
    </p>
  ) : null;

  return (
    <React.Fragment>
      {buttons}
      {cancelErrors}
      {cancelLink}
    </React.Fragment>
  );
};

export default ChangeButtonMaybe;
