import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemSubTotalMaybe } from '../../util/bookingBreakdown/lineItemSubTotalMaybe';
import { styles } from '../Invoice/InvoiceStyles';

const styleSheet = styles;

const LineItemSubTotalMaybePdf = props => {
  const { transaction, unitType, userRole, intl } = props;
  const { label, price, showSubTotal, isRefund } = lineItemSubTotalMaybe(
    transaction,
    unitType,
    userRole,
    intl
  );

  const subTotal = isRefund ? '$0.00' : price;

  return price && showSubTotal ? (
    <>
      <View style={styleSheet.row}>
        <View style={styleSheet.tColumnLeftWide}>
          <Text>GST</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>$0.00</Text>
        </View>
      </View>
      <View style={[styleSheet.row, styleSheet.boldFont]}>
        <View style={styleSheet.tColumnLeftWide}>
          <Text>{label}</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>{subTotal}</Text>
        </View>
      </View>
    </>
  ) : null;
};

LineItemSubTotalMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape,
};

export default LineItemSubTotalMaybePdf;
