import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import { txIsPayoutManuallyAdjusted } from '../transaction';

const { Money } = sdkTypes;

const manualPayoutMoney = amount => new Money(amount * 100, config.currency);

export const lineItemManualPayoutMaybe = (transaction, intl) => {
  const manualPayoutLineItems = transaction.attributes.metadata?.payoutAdjustment ?? 0;

  const formattedPayoutLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.manualPayout" />
  ) : (
    'Adjustment'
  );

  const formattedPayout = () => {
    if (!!manualPayoutLineItems) {
      return !!intl
        ? formatMoney(intl, manualPayoutMoney(manualPayoutLineItems))
        : `$${manualPayoutLineItems.toFixed(2)}`;
    }
  };

  const showManualPayoutAdjustment = txIsPayoutManuallyAdjusted(transaction);

  return { label: formattedPayoutLabel, price: formattedPayout(), showManualPayoutAdjustment };
};
