import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingOverviewForm.module.css';

const CustomSizeSelectFieldMaybe = props => {
  const { name, id, categories, intl } = props;
  const sizeLabel = intl.formatMessage({
    id: 'EditListingOverviewForm.sizeLabel',
  });
  const sizePlaceholder = intl.formatMessage({
    id: 'EditListingOverviewForm.sizePlaceholder',
  });
  const sizeRequired = required(
    intl.formatMessage({
      id: 'EditListingOverviewForm.sizeRequired',
    })
  );
  return categories ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={sizeLabel}
      validate={sizeRequired}
    >
      <option disabled value="">
        {sizePlaceholder}
      </option>
      {categories.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomSizeSelectFieldMaybe;
