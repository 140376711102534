import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemCustomerCommissionRefundMaybe } from '../../util/bookingBreakdown/lineItemCustomerCommissionRefundMaybe';

import css from './BookingBreakdown.module.css';

const LineItemCustomerCommissionRefundMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const { label, price, showRefund } = lineItemCustomerCommissionRefundMaybe(
    transaction,
    isCustomer,
    intl
  );

  return isCustomer && showRefund && price ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemCustomerCommissionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionRefundMaybe;
