import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedFiltersCount,
    selectedSecondaryFiltersCount,
    isAustraliaSearch,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0 && selectedFiltersCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;

  const thisArea = isAustraliaSearch ? 'Australia' : 'this area';

  const bunningsLink = (
    <a href="https://www.bunnings.com.au/our-services/in-store/get-it-home">Bunnings</a>
  );

  const uhaulLink = <a href="https://www.trailerrentals.com.au/location/near-by">U-Haul</a>;

  const moveyourselfLink = (
    <a href="https://www.moveyourself.com.au/index.php?page=depot">Move Yourself</a>
  );

  const emailLink = (
    <a href="mailto:hello@localtrailerhire.com.au">hello@localtrailerhire.com.au</a>
  );

  const url =
    'https://localtrailerhire.us20.list-manage.com/subscribe/post?u=d950236a0409c1813684f0b0b&amp;id=71eaf1a8c1';
  const CustomForm = ({ status, message, onValidated }) => {
    let email, postCode;
    const submit = () =>
      email &&
      postCode &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
        POSTCODE: postCode.value,
      });

    return (
      <div>
        <input ref={node => (postCode = node)} type="text" placeholder="Post code" />
        <br />
        <input ref={node => (email = node)} type="email" placeholder="Your email" />
        <br />
        <button className={css.subscribeButton} onClick={submit}>
          Subscribe to local trailer alerts
        </button>
        {status === 'sending' && <p style={{ color: 'blue' }}>Please wait...</p>}
        {status === 'error' && (
          <p style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <p style={{ color: 'green' }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{
                  count: resultsCount,
                  filtersActive: selectedFiltersCount > 0,
                  area: thisArea,
                }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent}
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <h2>
            <FormattedMessage id="SearchFilters.noResultsHeading" />
          </h2>
          <p>
            <FormattedMessage id="SearchFilters.noResults" />
          </p>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
          <h2>
            <FormattedMessage id="SearchFilters.noResultsGetATrailerHeading" />
          </h2>
          <p>
            <FormattedMessage id="SearchFilters.noResultsGetATrailer" values={{ emailLink }} />
          </p>
          <h2>
            <FormattedMessage id="SearchFilters.noResultsAlternativesHeading" />
          </h2>
          <p>
            <FormattedMessage
              id="SearchFilters.noResultsAlternatives"
              values={{ bunningsLink, uhaulLink, moveyourselfLink }}
            />
          </p>
        </div>
      ) : (
        <div className={css.filters}>
          {children}
          {toggleSecondaryFiltersOpenButton}
        </div>
      )}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  selectedFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  isAustraliaSearch: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  selectedFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
