import React from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal, NamedLink } from '..';
import { propTypes } from '../../util/types';

import css from './CallBackModal.module.css';
import { CallBackForm } from '../../forms';

const CallBackModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    currentUser,
    onSubmitCallMeBack,
    submitInProgress,
    callMeBackError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = 'Close';

  const handleSubmit = values => {
    const { name, phoneNumber, reasonForCallBack, otherInfo, email } = values;
    onSubmitCallMeBack(name, phoneNumber, reasonForCallBack, otherInfo, email);
  };

  const currentUserFullName = currentUser
    ? `${currentUser.attributes?.profile?.firstName} ${currentUser.attributes?.profile?.lastName}`
    : null;

  const currentUserPhoneNumber = currentUser
    ? currentUser.attributes?.profile?.protectedData?.phoneNumber
    : null;

  const currentUserEmail = currentUser ? currentUser.attributes?.email : null;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>Request a call back</p>
      <p className={css.modalMessage}>
        If you can’t find the answer to your question in our{' '}
        <NamedLink name="HelpPage">
          <FormattedMessage id="Footer.toHelpPage" />
        </NamedLink>
        , please provide us with some information and we’ll respond as soon as we can. Our usual
        operating hours for routine (non-emergency) support are 9-5 on weekdays and 9-2 on
        Saturdays.
      </p>
      <CallBackForm
        initialValues={{
          name: currentUserFullName,
          phoneNumber: currentUserPhoneNumber,
          email: currentUserEmail,
        }}
        onSubmit={handleSubmit}
        submitInProgress={submitInProgress}
        callMeBackError={callMeBackError}
      />
    </Modal>
  );
};

const { string } = PropTypes;

CallBackModal.defaultProps = {
  className: null,
  rootClassName: null,
  isRefundableTransaction: true,
  currentUser: null,
};

CallBackModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOpen: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  currentUser: propTypes.currentUser,
  onSubmitCallMeBack: func.isRequired,
  submitInProgress: bool.isRequired,
  callMeBackError: propTypes.error,
};

export default injectIntl(CallBackModal);
