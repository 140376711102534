import React from 'react';
import { compose } from 'redux';
import { FieldBirthdayInput, FieldSelect, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { object, string } from 'prop-types';
import config from '../../config';

import css from './FieldGroupDriversLicence.module.css';

const FieldGroupDriversLicenceComponent = props => {
  const { formId, driversLicenceExpiryDate, licenceYears, intl } = props;

  // driversLicenceNumber
  const driversLicenceNumberLabel = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceNumberLabel',
  });
  const driversLicenceNumberPlaceholder = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceNumberPlaceholder',
  });
  const driversLicenceNumberRequiredMessage = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceNumberRequired',
  });
  const driversLicenceNumberRequired = validators.required(driversLicenceNumberRequiredMessage);
  const driversLicenceNumberMinLengthMessage = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceNumberMinLength',
  });
  const driversLicenceNumberMinLength = validators.minLength(
    driversLicenceNumberMinLengthMessage,
    6
  );

  // driversLicenceIssuedBy
  const driversLicenceIssuedByLabel = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceIssuedByLabel',
  });
  const driversLicenceIssuedByPlaceholder = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceIssuedByPlaceholder',
  });
  const driversLicenceIssuedByRequiredMessage = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceIssuedByRequired',
  });
  const driversLicenceIssuedByRequired = validators.required(driversLicenceIssuedByRequiredMessage);
  const states = config.custom.states;

  // driversLicenceExpiryDate
  const driversLicenceExpiryDateLabel = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceExpiryDateLabel',
  });
  const driversLicenceExpiryDateRequiredMessage = intl.formatMessage({
    id: 'FieldGroupDriversLicence.driversLicenceExpiryDateRequired',
  });
  const driversLicenceExpiryDateRequired = validators.required(
    driversLicenceExpiryDateRequiredMessage
  );
  const minExpiryRequired = validators.dateIsInFuture('Expiry date cannot be in the past', 0);

  return (
    <div className={css.fieldGroup}>
      <FieldTextInput
        className={css.driversLicenceNumber}
        type="text"
        id={formId ? `${formId}.driversLicenceNumber` : 'driversLicenceNumber'}
        name="driversLicenceNumber"
        label={driversLicenceNumberLabel}
        placeholder={driversLicenceNumberPlaceholder}
        validate={validators.composeValidators(
          driversLicenceNumberRequired,
          driversLicenceNumberMinLength
        )}
      />
      <label
        htmlFor={formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'}
        className={css.driversLicenceLabel}
      >
        {driversLicenceExpiryDateLabel}
      </label>
      <FieldBirthdayInput
        id={formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'}
        name="driversLicenceExpiryDate"
        className={css.driversLicenceExpiryDate}
        label={'Day'}
        labelForMonth={'Month'}
        labelForYear={'Year'}
        format={v => v}
        valueFromForm={driversLicenceExpiryDate}
        years={licenceYears || 'lth'}
        validate={validators.composeValidators(driversLicenceExpiryDateRequired, minExpiryRequired)}
      />
      <FieldSelect
        className={css.driversLicenceIssuedBy}
        id={formId ? `${formId}.driversLicenceIssuedBy` : 'driversLicenceIssuedBy'}
        name="driversLicenceIssuedBy"
        label={driversLicenceIssuedByLabel}
        validate={driversLicenceIssuedByRequired}
      >
        <option disabled value="">
          {driversLicenceIssuedByPlaceholder}
        </option>
        {states.map(c => (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        ))}
      </FieldSelect>
    </div>
  );
};

FieldGroupDriversLicenceComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  driversLicenceExpiryDate: object,
  licenceYears: string,

  intl: intlShape.isRequired,
};

const FieldGroupDriversLicence = compose(injectIntl)(FieldGroupDriversLicenceComponent);
FieldGroupDriversLicence.displayName = 'FieldGroupDriversLicence';

export default FieldGroupDriversLicence;
