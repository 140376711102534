import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import * as log from '../../util/log';
import { shortUrl } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/FlyerPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/FlyerPage/SHOW_LISTING_ERROR';

export const FETCH_QR_CODE_SHORT_URL_REQUEST = 'app/FlyerPage/FETCH_QR_CODE_SHORT_URL_REQUEST';
export const FETCH_QR_CODE_SHORT_URL_SUCCESS = 'app/FlyerPage/FETCH_QR_CODE_SHORT_URL_SUCCESS';
export const FETCH_QR_CODE_SHORT_URL_ERROR = 'app/FlyerPage/FETCH_QR_CODE_SHORT_URL_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  qrCodeShortUrl: null,
};

const flyerPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_QR_CODE_SHORT_URL_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
      };
    case FETCH_QR_CODE_SHORT_URL_SUCCESS: {
      const qrCodeShortUrl = payload.shortURL;
      return {
        ...state,
        qrCodeShortUrl,
        fetchInProgress: false,
        pageReady: true,
      };
    }
    case FETCH_QR_CODE_SHORT_URL_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchError: payload, pageReady: true };
    default:
      return state;
  }
};

export default flyerPageReducer;

// ================ Action creators ================ //

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

const fetchQrCodeShortUrlRequest = () => ({ type: FETCH_QR_CODE_SHORT_URL_REQUEST });
const fetchQrCodeShortUrlSuccess = response => ({
  type: FETCH_QR_CODE_SHORT_URL_SUCCESS,
  payload: response,
});
const fetchQrCodeShortUrlError = e => ({
  type: FETCH_QR_CODE_SHORT_URL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': ['variants.landscape-crop6x'],
  };

  return sdk.listings
    .show(params)
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const loadData = (params, search) => async dispatch => {
  const listingId = new UUID(params.id);

  const listing = await dispatch(showListing(listingId));
  const userId = listing.data.included.find(item => item.type === 'user').id.uuid;

  const customUrl = `https://${process.env.REACT_APP_DOMAIN}/u/${userId}`;
  const bodyParams = {
    url: customUrl,
    utmSource: 'qrcode-flyer',
    params: {},
  };
  dispatch(fetchQrCodeShortUrlRequest());
  try {
    const response = await shortUrl({ bodyParams });
    dispatch(fetchQrCodeShortUrlSuccess(response));
  } catch (e) {
    log.error(e, 'fetching-qr-code-short-url-failed');
    dispatch(fetchQrCodeShortUrlError(e));
  }
};
