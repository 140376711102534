import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionForBusinessBenefits.module.css';

import priceImage from './images/price.svg';
import workImage from './images/work.svg';
import guaranteeImage from './images/guarantee.svg';
import transferImage from './images/transfer.svg';

const SectionForBusinessBenefits = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.subheading}>Why use localtrailerhire.com.au?</h2>
      <div className={css.benefitsContainer}>
        <div className={css.benefitFirst}>
          <img src={transferImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Expanded Reach</h3>
            <div>
              With over 15,000 users actively searching for trailer rentals, Local Trailer Hire
              provides a substantial audience for your listings. Our platform attracts individuals
              and businesses who are specifically looking for trailer hire, meaning no tyre kickers
              - people on Local Trailer Hire want to hire!
            </div>
          </div>
        </div>

        <div className={css.benefit}>
          <img src={priceImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Cost-Effective Marketing</h3>
            <div>
              Our platform allows you to market your trailers to a targeted audience at no cost.
              Unlike traditional advertising channels that can be expensive, Local Trailer Hire
              offers a free service for trailer owners, ensuring that you can reach potential
              customers without straining your marketing budget.
            </div>
          </div>
        </div>

        <div className={css.benefit}>
          <img src={workImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Convenient Hiring Process</h3>
            <div>
              Local Trailer Hire offers a user-friendly online interface to simplify the hiring
              process. It complements your existing booking system and allows customers to browse
              and request trailers easily. We take care of payments and contracts too, and you still
              have the option for customers to complete your hiring forms.
            </div>
          </div>
        </div>

        <div className={css.benefitLast}>
          <img src={guaranteeImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Trust and Security</h3>
            <div>
              We prioritise safety and security, ensuring that all interactions on our platform are
              conducted with integrity. Our verification processes and user reviews help build trust
              among customers, giving them the confidence to hire trailers from reputable companies
              like yours.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionForBusinessBenefits.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForBusinessBenefits.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForBusinessBenefits;
