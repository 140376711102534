import { storableError } from '../../util/errors';
import * as log from '../../util/log';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/FlyersPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/FlyersPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/FlyersPage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryInProgress: true,
  listings: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryListingsError: null,
        listings: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: null,
        listings: payload,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: {},
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response.data.data,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  return sdk.ownListings
    .query()
    .then(response => {
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(queryListingsError(storableError(error)));
      log.error(error, 'fetch-listings-failed');
    });
};
