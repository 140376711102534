import React, { useState } from 'react';
import { func, string, bool, node, oneOfType } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldSelect, FieldTextInput, FieldCheckbox, Form, WarningButton } from '../../components';
import * as validators from '../../util/validators';

import css from './CancelRequestMessageForm.module.css';

const CancelRequestMessageFormComponent = props => {
  const [showOptionalMessageFieldState, setShowOptionalMessageFieldState] = useState(false);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          pristine,
          saveActionMsg,
          ready,
          updated,
          cancelInProgress,
          formId,
          providerName,
          cancelMessageOptions,
          isRefundableTransaction,
          invalid,
          values,
          form,
        } = formRenderProps;

        const { intl } = props;
        const classes = classNames(rootClassName || css.root, className);
        const cancelReady = (updated && pristine) || ready;

        // termsAccept
        // TODO this message is not currently used because FieldCheckbox doesn't deal with validations
        const termsAcceptRequiredMessage = intl.formatMessage({
          id: 'CancelRequestMessageForm.termsAcceptRequired',
        });

        const showAcceptTermsCheckbox = !isRefundableTransaction;
        const termsAcceptRequired = validators.requiredCheckbox(termsAcceptRequiredMessage);
        const termsAcceptLabel = (
          <span className={css.termsText}>
            {intl.formatMessage({
              id: 'CancelRequestMessageForm.termsAcceptLabel',
            })}
          </span>
        );
        const cancelDisabled = cancelInProgress || invalid;

        const personalMessageLabel = intl.formatMessage({
          id: 'CancelRequestMessageForm.messageLabel',
        });
        const cancelMessagePlaceholder = intl.formatMessage({
          id: 'CancelRequestMessageForm.messagePlaceholder',
        });
        const cancelOptionsLabel = intl.formatMessage(
          { id: 'CancelRequestMessageForm.cancelOptionsLabel' },
          { providerName: providerName }
        );
        const handleShowOptionalMessageChange = value => {
          setShowOptionalMessageFieldState(value === 'other');
        };

        const messageRequiredMessage = intl.formatMessage({
          id: 'CancelRequestMessageForm.messageRequired',
        });
        const messageRequired = validators.required(messageRequiredMessage);

        const customMessageRequiredMessage = intl.formatMessage({
          id: 'CancelRequestMessageForm.customMessageRequired',
        });
        const customMessageRequired = validators.required(customMessageRequiredMessage);

        const formResetAndHandleSubmit = async values => {
          // reset the final form to initialValues
          await handleSubmit(values);
          form.reset();
        };

        return (
          <Form className={classes} onSubmit={formResetAndHandleSubmit}>
            <FieldSelect
              className={css.cancelMessage}
              id={formId ? `${formId}.cancelMessage` : 'cancelMessage'}
              name="cancelMessage"
              label={cancelOptionsLabel}
              onChange={handleShowOptionalMessageChange}
              validate={messageRequired}
            >
              <option disabled value="">
                {cancelMessagePlaceholder}
              </option>
              {cancelMessageOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>
            {showOptionalMessageFieldState ? (
              <FieldTextInput
                className={css.message}
                inputRootClass={css.messageInput}
                hotjarWhitelist={true}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                label={personalMessageLabel}
                validate={customMessageRequired}
              />
            ) : null}
            {showAcceptTermsCheckbox ? (
              <div className={css.cancelTermsAccept}>
                <FieldCheckbox
                  id={formId ? `${formId}.cancelTermsAccept` : 'cancelTermsAccept'}
                  name="cancelTermsAccept"
                  label={termsAcceptLabel}
                  validate={termsAcceptRequired}
                  value="cancelTermsAccept"
                />
              </div>
            ) : null}
            <WarningButton
              className={css.submitButton}
              type="submit"
              inProgress={cancelInProgress}
              disabled={cancelDisabled}
              ready={cancelReady}
            >
              {saveActionMsg}
            </WarningButton>
          </Form>
        );
      }}
    />
  );
};

CancelRequestMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  isRefundableTransaction: true,
};

CancelRequestMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  intl: intlShape.isRequired,
  providerName: oneOfType([node, string]).isRequired,
  cancelInProgress: bool,
};

const CancelRequestMessageForm = CancelRequestMessageFormComponent;

export default CancelRequestMessageForm;
