import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemBasePriceMaybe } from '../../util/bookingBreakdown/lineItemBasePriceMaybe';
import moment from 'moment';
import { styles } from '../Invoice/InvoiceStyles';
import { string } from 'prop-types';

const styleSheet = styles;

const LineItemBasePriceMaybePdf = props => {
  const { transaction, unitType, timeZone, intl } = props;
  const { label, price } = lineItemBasePriceMaybe(transaction, unitType, intl);

  const trailerTitle = transaction.listing.attributes.title;

  // Convert the date to the desired timezone and format it for the invoice
  const bookingStartDate = moment(transaction.booking.attributes.displayStart);
  const bookingEndDate = moment(transaction.booking.attributes.displayEnd);
  const formattedDate = date => date.tz(timeZone).format('ddd, MMM D, h:mm A');

  return price ? (
    <>
      <View style={[styleSheet.row, styleSheet.marginAboveLarge]}>
        <Text style={[styleSheet.boldFont, styleSheet.tColumnLeft]}>Trailer Hire Fee</Text>
        <View style={styleSheet.tColumnCentre}>
          <Text>{label}</Text>
        </View>
        <View style={styleSheet.rightAligned}>
          <Text>{price}</Text>
        </View>
      </View>
      <View style={styleSheet.row}>
        <View style={styleSheet.row}>
          <Text>Hire of {trailerTitle}</Text>
        </View>
      </View>
      <View style={styleSheet.row}>
        <View style={styleSheet.row}>
          <Text>
            {formattedDate(bookingStartDate)} – {formattedDate(bookingEndDate)}
          </Text>
        </View>
      </View>
    </>
  ) : null;
};

LineItemBasePriceMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  timeZone: string,
  intl: intlShape,
};

export default LineItemBasePriceMaybePdf;
