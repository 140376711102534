import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemManualPayoutMaybe } from '../../util/bookingBreakdown/lineItemManualPayoutMaybe';

import css from './BookingBreakdown.module.css';

const LineItemManualPayoutMaybe = props => {
  const { transaction, intl } = props;

  const { label, price, showManualPayoutAdjustment } = lineItemManualPayoutMaybe(transaction, intl);

  return price && showManualPayoutAdjustment ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemManualPayoutMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemManualPayoutMaybe;
