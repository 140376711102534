import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May 14, 2022</p>

      <h2>Your privacy is critically important to us. At localtrailerhire.com.au we have a few fundamental principles:</h2>
      <ul className="list">
        <li>We don’t ask you for your personal data unless we truly need it.</li>
        <li>We don’t share your personal data with anyone except to comply with the law, develop our products, or protect our rights.</li>
        <li>We don’t store personal data on our servers unless required for the ongoing operation of our services.</li>
      </ul>

      <p>localtrailerhire.com.au operates the www.localtrailerhire.com.au website, which provides the SERVICE.</p>

      <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information of anyone who uses our Service, the localtrailerhire.com.au website.</p>

      <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

      <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at www.localtrailerhire.com.au, unless otherwise defined in this Privacy Policy. </p>

      <h2>Information Collection and Use</h2>

      <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect may be used to contact or identify you.</p>

      <p>We collect, hold, use and disclose personal information for the purpose of providing the service (connecting trailer hirers with trailer owners) and sending automated emails to trailer hirers and trailer owners.</p>
      <p>We use personal email addresses to deliver automated email messages about activity on localtrailerhire.com.au (for example, to alert trailer owners about new booking requests for their trailers). This data is held by Sharetribe, based in Finland, and subject to their privacy policy.</p>
      <p>We also collect email addresses for a mailing list for marketing messages (for example, to alert subscribers when new trailers are listed in their local area). This data is held by Mailchimp, based in the US, and subject to their privacy policy. </p>
      <p>We use Stripe for processing credit card payments. Data relating to credit card payments is held by Stripe, based in the US, and subject to their privacy policy.</p>
      <p>We collect Users' personal data through different means, which are explained below.</p>
      <p>Most of the personal data we process is collected directly from the Users. localtrailerhire.com.au may collect following personal data in the process of providing the Service:</p>
      <ul>
        <li>basic information such as name, residential address, email address, phone number, drivers licence number, expiry date and issuing state;</li>
        <li>authentication information such as localtrailerhire.com.au username and password, and if any third party service (e.g. Facebook) is used to sign in, the user ID of that service;</li>
        <li>information relating to the customer relationship such as bought services, their start and end times and information on their use;</li>
        <li>billing information.</li>
      </ul>
      <p>The following data about a trailer hirer is shared with a trailer owner when a booking request is made:</p>
      <ul>
        <li>Full name</li>
        <li>Drivers licence number and issuing state</li>
      </ul>
      <p>The following data is processed by our payments processor (currently Stripe, subject to change):</p>
      <ul>
      <li>Payment data, such as credit card number and expiration date</li>
      </ul>

      <p>localtrailerhire.com.au automatically collects and processes the following technical data about the User:</p>
      <ul>
      <li>IP address</li>
      <li>Geographical location data based on the IP address</li>
      <li>Service access times</li>
      <li>Statistics on page views</li>
      <li>Any other automatically collected technical data</li>
      </ul>

      <p>Furthermore, localtrailerhire.com.au and Sharetribe may collect anonymised statistics about the way the User uses the Services provided by localtrailerhire.com.au.</p>

      <h2>Log Data</h2>

      <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

      <h2>Cookies</h2>

      <p>Cookies are pieces of data which are received and transmitted by a User's device when the User is using localtrailerhire.com.au’s Services or website. localtrailerhire.com.au may use cookies and similar techniques to provide functionality of the Services, to enhance the user experience and to identify Users and how they use the Services. We use cookies, for example, to login and logout authentication, to remember session allowing Users to continue the session in case of time-out and to prevent unauthorized use of the Services. The User may prohibit the use of cookies or remove cookies from the browser settings. However, cookies are important part of how the Service works, so limiting the use of cookies may affect the functionality of the Service.</p>
      <p>localtrailerhire.com.au uses third party cookies for various purposes. We may use third party cookies for statistical purposes to compile anonymous, aggregated statistics, such as to estimate number of Users and detect which parts of the Service Users find most useful, and identify features that could be improved. For such purposes, we use third party cookies, such as Google Analytics, to help analyse how our Users use the Service. The technical usage information (including IP address) generated by the cookie about use of the Service is transmitted to such third parties to compile statistical reports.</p>
      <p>Sharetribe may also use third party cookies for marketing purposes to collect information about Users' browsing habits and to remember visits in order to make advertising more relevant to that User and to his or her interests. These cookies are usually placed by third party advertising networks and social media providers, such as Doubleclick, Google Adwords and Twitter. A list of used third parties is available here.</p>

      <h2>Service Providers</h2>

      <p>We may employ third-party companies and individuals due to the following reasons:</p>

      <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
      </ul>

      <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

      <h2>Security</h2>

      <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <h2>Links to Other Sites</h2>

      <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

      <h2>Children’s Privacy</h2>

      <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

      <h2>Accessing, correcting, or requesting deletion of information we hold</h2>
      <p>Anyone may access and correct, or request deletion of, any information that localtrailerhire.com.au holds about them by contacting us at hello@localtrailerhire.com.au.</p>

      <h2>Retention and deletion</h2>
      <p>The User's personal data will be retained only for as long as necessary to fulfill the purposes defined in this Privacy Policy. When the User's personal data is no longer required by law or rights or obligations by us or the User, we will delete the personal data. In most cases, personal data will be deleted upon termination or expiry of the agreement between the User and localtrailerhire.com.au or upon the User’s written request. Please note that localtrailerhire.com.au and Sharetribe may have a right to retain some data even after the termination of the agreement for billing purposes.</p>
      <p>Exceptionally, localtrailerhire.com.au may retain personal data for a longer period if we have a legitimate reason or an obligation to store data for the purposes of criminal investigation or other corresponding reason.</p>


      <h2>Complaints</h2>
      <p>Anyone may complain about a breach of the Australian Privacy Principles by contacting us using the details below. We will aim to respond to your complaint within 2 business days. Alternatively, you may directly contact the Office of the Australian Information Commissioner.</p>

      <h2>Changes to This Privacy Policy</h2>

      <p>We may update our Privacy Policy from time to time. We advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page. We will provide the date of the Privacy Policy to allow Users to see changes. </p>


      <h2>Contact</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>

      <p>You may contact us at <a href="mailto:hello@localtrailerhire.com.au">hello@localtrailerhire.com.au</a> or by post to:</p>
      <p>The Local Hire Group Pty Ltd<br/>
      Level 8, 607 Bourke Street, Melbourne VIC 3000</p>


    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
