import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { timeOfDayStringTo12HourClock } from './dates';

const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);
const printHalfHourStrings = h => (h > 9 ? `${h}:30` : `0${h}:30`);

export const getEntryBoundaries = (values, dayOfWeek, intl, findStartHours) => index => {
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = Number.parseInt(startTime.split(':')[0]);
      let endHour = Number.parseInt(endTime.split(':')[0]);

      const startMins = Number.parseInt(startTime.split(':')[1]);
      const endMins = Number.parseInt(endTime.split(':')[1]);

      if (endMins > 0) {
        endHour++;
      }

      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      const halfHoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHalfHourStrings(startHour + i));

      let allBetween = [...hoursBetween, ...halfHoursBetween].sort();

      if (startMins > 0 && endMins > 0) {
        return allHours.concat(allBetween.slice(1, -1));
      }
      if (startMins > 0) {
        return allHours.concat(allBetween.slice(1));
      }
      if (endMins > 0) {
        return allHours.concat(allBetween.slice(0, -1));
      }
      return allHours.concat(allBetween);
    }

    return allHours;
  }, []);
};

export const isToday = (date, timeZone) => {
  const today = new Date();
  return moment(date, timeZone).isSame(today, 'day');
};

export const openingHoursForDate = (date, timeZone, openingHours) => {
  const dayOfWeek = moment(date, timeZone)
    .format('ddd')
    .toLowerCase();
  return openingHoursForDayOfWeek(dayOfWeek, openingHours);
};

export const hasOpeningHoursForDate = (date, timeZone, openingHours) => {
  const dayOfWeek = moment(date, timeZone)
    .format('ddd')
    .toLowerCase();
  return hasOpeningHoursForDayOfWeek(dayOfWeek, openingHours);
};

export const dayOfWeek = (date, timeZone) => {
  return moment(date, timeZone)
    .format('ddd')
    .toLowerCase();
};

export const getOpeningHoursForDate = (date, timeZone, openingHours) => {
  return openingHours.entries.filter(entry => {
    return entry.dayOfWeek === dayOfWeek(date, timeZone);
  });
};

export const openingHoursForDayOfWeek = (dayOfWeek, openingHours) => {
  const entries = openingHours.entries.filter(entry => {
    return entry.dayOfWeek === dayOfWeek;
  });

  if (entries.length === 0) {
    return null;
  }

  return entries
    .map(entry => {
      return (
        <span key={entry.startTime}>
          {timeOfDayStringTo12HourClock(entry.startTime)} -{' '}
          {timeOfDayStringTo12HourClock(entry.endTime)}
        </span>
      );
    })
    .reduce((prev, curr) => [prev, <br key={prev.key + 'br'} />, curr]);
};

export const hasOpeningHoursForDayOfWeek = (dayOfWeek, openingHours) => {
  const entries = openingHours.entries.filter(entry => {
    return entry.dayOfWeek === dayOfWeek;
  });

  return entries.length > 0;
};
