import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionForHirersSteps.module.css';

import convenientImage from './images/convenient-location.png';
import greatPriceImage from './images/great-price-wallet.png';
import quickImage from './images/quick-booking.png';

const SectionForHirersSteps = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.stepsContainer}>
        <div className={classNames(css.stepImage, css.box1)}>
          <img src={greatPriceImage} alt="wallet" className={css.hireImage} />
        </div>
        <div className={classNames(css.stepWords, css.box2)}>
          <h4 className={css.stepNumHeading}>Cheaper</h4>
          <div>
            Trailers are available on localtrailerhire.com.au at amazing prices. The overheads found
            in traditional hiring channels don’t exist on localtrailerhire.com.au so you are always
            going to get a great deal. If you are looking for a longer term hire, you’ll find an
            even better deal!
          </div>
        </div>
        <div className={classNames(css.stepImage, css.box3)}>
          <img src={quickImage} alt="calendar and stopwatch" className={css.hireImage} />
        </div>
        <div className={classNames(css.stepWords, css.box4)}>
          <h4 className={css.stepNumHeading}>Easier</h4>
          <div>
            It's easy to find a trailer using the search on localtrailerhire.com.au. Once you’ve
            found the perfect trailer, submitting a booking request takes just a few clicks.
          </div>
        </div>
        <div className={classNames(css.stepRectangleImage, css.box5)}>
          <img src={convenientImage} alt="location on mapen" className={css.hireImageRectangular} />
        </div>
        <div className={classNames(css.stepWords, css.box6)}>
          <h4 className={css.stepNumHeading}>Better</h4>
          <div>
            localtrailerhire.com.au is all about locals sharing their trailers with other locals.
            It’s highly likely that you’ll be hiring a trailer from someone just around the corner.
            It could not be more convenient.
          </div>
        </div>
      </div>
    </div>
  );
};

SectionForHirersSteps.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForHirersSteps.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForHirersSteps;
