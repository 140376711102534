import React, { useState, useEffect, useRef } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, LocationAutocompleteInput, NamedLink } from '../../components';

import css from './LocationSearchForm.module.css';

const identity = v => v;

const LocationSearchFormComponent = props => {
  const centredClasses = [css.searchInput, css.centred, 'data-hj-whitelist', 'LoNotSensitive'];
  const leftAlignedClasses = [css.searchInput, 'data-hj-whitelist', 'LoNotSensitive'];

  const _mounted = useRef(true);
  const [inputClassNamesState, setInputClassNamesState] = useState(centredClasses);
  const [currentLocationErrorState, setCurrentLocationErrorState] = useState(false);

  const handleChange = location => {
    if (location.selectedPlace && !location.selectedPlace.error) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      props.onSubmit({ location });
    }
  };

  useEffect(() => {
    return () => {
      _mounted.current = false;
    };
  });

  const handleFocus = search => {
    if (search === '') {
      setInputClassNamesState(leftAlignedClasses);
    }
  };

  const handleBlur = location => {
    if (location.search === '') {
      setInputClassNamesState(centredClasses);
    }
  };

  const handleChangeOrClick = location => {
    if (location.search !== undefined && _mounted.current) {
      if (inputClassNamesState.length === centredClasses.length && location.search.length > 0) {
        setInputClassNamesState(leftAlignedClasses);
      } else if (
        inputClassNamesState.length === leftAlignedClasses.length &&
        location.search === ''
      ) {
        setInputClassNamesState(centredClasses);
      }
    }
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, intl } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        // Allow form submit only when the place has changed
        const preventFormSubmit = e => e.preventDefault();

        return (
          <div className={css.searchFormContainer}>
            <Form className={classes} onSubmit={preventFormSubmit}>
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    setCurrentLocationErrorState(value?.selectedPlace?.error);
                    if (!value?.selectedPlace?.error) {
                      onChange(value);
                      handleChange(value);
                      handleChangeOrClick(value);
                    }
                  };

                  const searchInput = {
                    ...restInput,
                    onChange: searchOnChange,
                    onFocus: handleFocus,
                    onBlur: handleBlur,
                  };
                  const types = ['place', 'locality', 'postcode', 'country', 'region'];
                  return (
                    <LocationAutocompleteInput
                      placeholder={intl.formatMessage({ id: 'LocationSearchForm.placeholder' })}
                      iconClassName={css.searchInputIcon}
                      inputClassNames={inputClassNamesState}
                      predictionsClassName={css.searchPredictions}
                      input={searchInput}
                      meta={meta}
                      types={types}
                      showSearchButton={true}
                      geoLocationError={currentLocationErrorState}
                    />
                  );
                }}
              />
            </Form>
          </div>
        );
      }}
    />
  );
};

LocationSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LocationSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LocationSearchForm = injectIntl(LocationSearchFormComponent);

export default LocationSearchForm;
