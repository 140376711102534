import React from 'react';
import { arrayOf, string } from 'prop-types';
import { IconReviewStar } from '..';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';

import css from './CustomerStats.module.css';
import { txHasBeenCompletedAndNotCanceled } from '../../util/transaction';

const CustomerStats = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    salesForSameCustomer,
    fetchSalesForSameCustomerInProgress,
    fetchSalesForSameCustomerError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const statsFullyLoaded = !fetchSalesForSameCustomerInProgress;

  const numHires = user.attributes.profile.publicData?.numCustomerHires || 0;
  const numberOfReviews =
    user.attributes.profile.publicData?.customerReviewStats?.numCustomerReviews || 0;
  const averageRating =
    user.attributes.profile.publicData?.customerReviewStats?.avgCustomerReviewRating?.toFixed(2) ||
    0;

  const hireCountForCurrentProvider =
    salesForSameCustomer?.filter(txHasBeenCompletedAndNotCanceled).length || 0;
  const userIsCurrentUser = user?.id?.uuid === currentUser?.id?.uuid;
  const gt100ResultsForCurrentProvider = salesForSameCustomer?.length > 100;

  const loadingResults = (
    <div className={css.loadingSpinner}>
      <IconSpinner />
    </div>
  );

  const customerStatsSection = (
    <React.Fragment>
      <div className={css.customerStats}>
        {numberOfReviews > 0 ? (
          <React.Fragment>
            <span>
              <IconReviewStar rootClassName={css.star} />
              {averageRating}
            </span>
            <span className={css.linkSeparator}>•</span>
          </React.Fragment>
        ) : null}
        <span>
          <FormattedMessage id="CustomerStats.numberOfReviews" values={{ numberOfReviews }} />
        </span>
        <span className={css.linkSeparator}>•</span>
        <span>
          <FormattedMessage id="CustomerStats.numberOfHires" values={{ numHires }} />
        </span>
      </div>
      <div className={css.customerStatLong}>
        {/* TODO show hire count from current provider inline with total hire count where space allows
        TODO show date of most recent hire? Link to old transactions? */}
        {numHires > 0 && !userIsCurrentUser && !fetchSalesForSameCustomerError ? (
          <p>
            ({gt100ResultsForCurrentProvider ? 'At least ' : null}
            <FormattedMessage
              id="CustomerStats.hireCountForCurrentProvider"
              values={{ hireCount: hireCountForCurrentProvider }}
            />
            )
          </p>
        ) : null}
        {fetchSalesForSameCustomerError ? (
          <div className={css.errorContainer}>
            <p className={css.error}>
              <FormattedMessage
                id="CustomerStats.fetchStatsFailed"
                values={{ name: user.attributes.profile.displayName }}
              />
            </p>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );

  return <div className={classes}>{statsFullyLoaded ? customerStatsSection : loadingResults}</div>;
};

CustomerStats.defaultProps = {
  rootClassName: null,
  className: null,
};

CustomerStats.propTypes = {
  rootClassName: string,
  className: string,
  ownerUser: propTypes.user,
  salesForSameCustomer: arrayOf(propTypes.transaction),
};

export default CustomerStats;
