import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { IconReviewStar } from '..';

import { REVIEW_RATINGS } from '../../util/types';

import css from './TestimonialCard.module.css';

const TestimonialCard = props => {
  const { rootClassName, className, name, location, review, image } = props;

  const stars = REVIEW_RATINGS;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.testimonialWrapper}>
      <div className={css.testimonial}>
        <div>
          {stars.map(star => (
            <IconReviewStar key={`star-${star}`} className={css.reviewStar} isFilled={star <= 5} />
          ))}
        </div>
        <div>"{review}"</div>
        <div className={css.testimonialGiver}>
          {image ? <img src={image} alt={name} className={css.testimonialPhoto} /> : null}
          <div>
            <div className={css.testimonialName}>{name}</div>
            <div className={css.testimonialLocationAndDate}>{location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

TestimonialCard.defaultProps = {
  rootClassName: null,
  className: null,
  name: null,
  location: null,
  review: null,
  image: null,
};

TestimonialCard.propTypes = {
  rootClassName: string,
  className: string,
  name: string,
  location: string,
  review: string,
  image: string,
};

export default TestimonialCard;
