import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionCampingTrailers.module.css';

const SectionCampingTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.campingTrailersContent}>
        <h3 className={css.heading}>
          How to find the right camping trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that have the right features, are the
            right size and price.
          </li>
          <li>Choose your desired camping trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.campingTrailersContent}>
        <h3 className={css.heading}>Camping trailers FAQ</h3>
        <h4 className={css.subheading}>What is a camping trailer?</h4>
        <p>
          A camping trailer is an absolute ripper when it comes to hitting the great outdoors.
          Camping trailers listed on Local Trailer Hire come in a variety of shapes and sizes.
          You'll find small pod trailers with a lockable waterproof lid for keeping your gear safe
          and dry. And if you need extra space for your camping essentials, there are larger
          trailers with plenty of storage features.
        </p>
      </div>
      <div className={css.campingTrailersContent}>
        <h4 className={css.subheading}>What could I use a camping trailer for?</h4>
        <p>
          Camping trailers are perfect for all your outdoor adventures. Whether you're planning a
          weekend camping trip, a beach getaway, or exploring the bush, one of these trailers could
          be your trusty sidekick. They come with a bunch of awesome features like covers, lockable
          boxes, gas cylinder storage, water tank storage, fuel drum storage, racks for surfboards
          or kayaks, and even attachments for mountain bikes. Some are designed to handle all
          terrain types, so you can take 'em anywhere your camping heart desires. Pack your camping
          gear, cooking essentials, and even your trusty surfboard, and you're ready to roll!
        </p>
      </div>
      <div className={css.campingTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right camping trailer?</h4>
        <p>
          Choosing the right camping trailer is all about matching it to your specific needs. Start
          by thinking about how much gear you'll be carrying and how many people you'll be camping
          with. If you're a solo adventurer or a couple, a smaller pod trailer might do the trick.
          But if you've got a whole tribe of campers or need extra space for equipment, go for a
          larger trailer with ample storage features. Make sure to check the available features on
          Local Trailer Hire, like lockable boxes, racks, and storage compartments. And hey, if
          you're unsure which one to pick, the trailer owners on Local Trailer Hire can give you
          some top-notch advice to find the perfect camping trailer for your epic outdoor escapades.
        </p>
      </div>
      <div className={css.campingTrailersContent}>
        <h4 className={css.subheading}>
          Why are the camping trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          The prices of camping trailers on Local Trailer Hire can vary. Each camping trailer is
          listed by its owner, who gets to set the price. The cost of a camping trailer can depend
          on a few factors. Firstly, it can be influenced by the overall condition of the trailer. A
          brand-spankin'-new trailer might have a higher price tag compared to a well-loved,
          seasoned camper. Secondly, the features of the trailer play a role. If a camping trailer
          comes with extra goodies like awnings, kitchen setups, or fancy lockable boxes, it might
          be priced a bit higher. Lastly, the demand for different types of camping trailers can
          also affect the price. If a particular trailer type is in high demand during peak camping
          season, the price might be a touch higher. So, keep an eye out for the right camping
          trailer that suits your budget and has the features you need.
        </p>
      </div>
      <div className={css.campingTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('enclosed')}</li>
          <li>{categoryLink('specialty')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};

SectionCampingTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCampingTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCampingTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCampingTrailersComponent;
