import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import {
  txIsCanceled,
  txIsDelivered,
  txIsDeclined,
  txIsReviewsOpen,
  txIsPayoutManuallyAdjusted,
} from '../transaction';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION_TAX } from '../../util/types';

const { Money } = sdkTypes;

const label = (transaction, isProvider, intl) => {
  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction) || txIsReviewsOpen(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  return totalLabel;
};

const amountToMoney = amount => new Money(amount, config.currency);

const adjustedTotal = (transaction, total, isProvider) => {
  const payoutAdjustment = transaction.attributes.metadata?.payoutAdjustment ?? 0;
  const feeAdjustment = transaction.attributes.metadata?.feeAdjustment ?? 0;
  const feeGstAdjustment = transaction.attributes.metadata?.feeGstAdjustment ?? 0;

  const totalAmount = payoutAdjustment + feeAdjustment + feeGstAdjustment;
  const manualTotals = isProvider ? payoutAdjustment : totalAmount;
  return amountToMoney(manualTotals * 100 + total);
};

const adjustedCommissionTotal = (transaction, commissionTax) => {
  const feeGstAdjustment = transaction.attributes.metadata?.feeGstAdjustment ?? 0;

  return amountToMoney(feeGstAdjustment * 100 + commissionTax);
};

export const lineItemTotalPrice = (transaction, isProvider, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354
  const totalLabel = !!intl ? label(transaction, isProvider, intl) : 'Total';

  const adjustedTotalPayin = txIsPayoutManuallyAdjusted(transaction)
    ? adjustedTotal(transaction, transaction.attributes.payinTotal.amount, isProvider)
    : transaction.attributes.payinTotal;

  const adjustedTotalPayout = txIsPayoutManuallyAdjusted(transaction)
    ? adjustedTotal(transaction, transaction.attributes.payoutTotal.amount, isProvider)
    : transaction.attributes.payoutTotal;

  const customerCommissionTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX && !item.reversal
  );
  const commissionTax = customerCommissionTaxLineItem?.lineTotal.amount ?? 0;

  const totalPrice = isProvider ? adjustedTotalPayout : adjustedTotalPayin;
  const zeroTotal = totalPrice.amount === 0;

  const formattedTotalPrice = !!intl
    ? formatMoney(intl, totalPrice)
    : `$${(totalPrice.amount / 100).toFixed(2)}`;

  const formattedTotalCommissionTax = !!intl
    ? formatMoney(intl, adjustedCommissionTotal(transaction, commissionTax))
    : `$${(adjustedCommissionTotal(transaction, commissionTax).amount / 100).toFixed(2)}`;

  return {
    label: totalLabel,
    price: formattedTotalPrice,
    zeroTotal,
    totalCommissionTax: formattedTotalCommissionTax,
  };
};
