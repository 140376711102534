import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { types as sdkTypes } from '../sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION } from '../types';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

export const lineItemCustomerCommissionMaybe = (transaction, isCustomer, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  if (isCustomer && customerCommissionLineItem) {
    const commission = customerCommissionLineItem.lineTotal;
    const customerCommissionLabel = !!intl ? (
      <FormattedMessage id="BookingBreakdown.commission" />
    ) : (
      'Service fee'
    );

    const formattedCustomerCommission = !!intl
      ? formatMoney(intl, commission)
      : `$${(commission.amount / 100).toFixed(2)}`;
    if (!isValidCommission(customerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', customerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or positive');
    } else {
      return { label: customerCommissionLabel, price: formattedCustomerCommission };
    }
  }
  return {
    label: null,
    price: null,
  };
};
