import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, DASHBOARD_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import config from '../../config';
import { YearType } from '../../util/earnings';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, currentUserHasListings } = props;
  const classes = classNames(rootClassName || css.root, className);

  const ownerDashboardTab = {
    text: <FormattedMessage id="UserNav.dashboard" />,
    selected: DASHBOARD_PAGES.includes(selectedPageName),
    disabled: false,
    linkProps: {
      name: 'DashboardOverviewPage',
    },
  };

  const tabs = [
    {
      text: <FormattedMessage id="ManageListingsPage.yourListings" />,
      selected: selectedPageName === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettingsPage" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.contactDetailsPage" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  return currentUserHasListings ? (
    <LinkTabNavHorizontal
      className={classes}
      tabRootClassName={css.tab}
      tabs={[tabs[0], ownerDashboardTab, tabs[1], tabs[2]]}
      skin="dark"
    />
  ) : (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  currentUserHasListings: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUserHasListings } = state.user;
  return { currentUserHasListings };
};

export default compose(connect(mapStateToProps))(UserNav);
