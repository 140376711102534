import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import Decimal from 'decimal.js';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION_TAX,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../types';
import { TRANSITION_DECLINE, TRANSITION_EXPIRE } from '../transaction';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(item => !isCommission(item) && item.reversal);
};

export const lineItemRefundMaybe = (transaction, intl) => {
  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(transaction);

  const refund = lineItemsTotal(refundLineItems);

  const formattedRefundLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.refund" />
  ) : (
    'Refund'
  );

  const formattedRefund = () => {
    if (refundLineItems.length > 0) {
      return !!intl ? formatMoney(intl, refund) : `$${(refund.amount / 100).toFixed(2)}`;
    }
  };

  const showRefund =
    transaction.attributes.lastTransition !== TRANSITION_EXPIRE &&
    transaction.attributes.lastTransition !== TRANSITION_DECLINE;

  return { label: formattedRefundLabel, price: formattedRefund(), showRefund };
};
