import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import logo from '../../containers/FlyerPage/lth-logo-trans-bg.png';
import BookingBreakdownPdf from '../BookingBreakdownPdf/BookingBreakdownPdf';
import { propTypes } from '../../util/types';
import { styles } from './InvoiceStyles';
import {
  TRANSITIONS_WITH_CAPTURE_CHARGE,
  TRANSITIONS_WITH_REFUND,
  txIsPayoutManuallyAdjusted,
} from '../../util/transaction';
import moment from 'moment';
import { abbreviateState, splitAddress } from '../../util/address';

const styleSheet = styles;

const Invoice = props => {
  const { transaction, userRole, unitType, booking, dateType, timeZone } = props;

  const customerName = `${transaction.attributes.protectedData.firstName} ${transaction.attributes.protectedData.lastName}`;
  const customerAddress = transaction.attributes.protectedData.residentialAddress;
  const customerAddressSplit = splitAddress(customerAddress);
  const customerAddressStreet = [
    customerAddressSplit.line1,
    customerAddressSplit.line2,
    customerAddressSplit.line3,
    customerAddressSplit.line4,
    customerAddressSplit.line5,
  ].join(' ');
  const customerAddressSuburb = customerAddressSplit.suburb;
  const customerAddressState = abbreviateState(customerAddressSplit.state);
  const customerAddressPostCode = customerAddressSplit.postCode;

  const refundTransitions = TRANSITIONS_WITH_REFUND;
  const paymentTransitions = TRANSITIONS_WITH_CAPTURE_CHARGE;

  const filteredRefundTransactions = transaction.attributes.transitions.filter(tran =>
    refundTransitions.includes(tran.transition)
  );

  const invoiceNumber = () => {
    const date = dateOfIssue
      ? moment(dateOfIssue)
          .tz(timeZone)
          .format('YYMMDD')
      : '';
    transaction.id.uuid;
    const transactionIdShortened = transaction.id.uuid
      .substring(transaction.id.uuid.length - 6)
      .toUpperCase();

    const refundMaybe = filteredRefundTransactions.length > 0 ? 'R' : '';
    const adjustedMaybe = txIsPayoutManuallyAdjusted(transaction) ? 'A' : '';

    return refundMaybe + adjustedMaybe + date + transactionIdShortened;
  };

  const findDateOfIssue = transaction => {
    if (filteredRefundTransactions.length > 0) {
      return filteredRefundTransactions[0].createdAt;
    }

    const filteredPaymentTransactions = transaction.attributes.transitions.filter(tran =>
      paymentTransitions.includes(tran.transition)
    );

    if (filteredPaymentTransactions.length > 0) {
      return filteredPaymentTransactions[0].createdAt;
    }

    return null;
  };

  const dateOfIssue = findDateOfIssue(transaction);

  const formattedDateOfIssue = dateOfIssue
    ? moment(dateOfIssue)
        .tz(timeZone)
        .format('DD/MM/YYYY')
    : '';

  const InvoiceTitle = () => (
    <View>
      <View style={styleSheet.titleContainer}>
        <View style={styleSheet.spaceBetween}>
          <Text style={styleSheet.largeTitle}>Tax Invoice</Text>
          <Image style={styleSheet.logo} src={logo} />
        </View>
      </View>
      <View style={styleSheet.titleContainer}>
        <View style={styleSheet.column}>
          <View style={styleSheet.row}>
            <Text style={styleSheet.boldFont}>Invoice number: </Text>
            <Text style={styleSheet.normalFont}>{invoiceNumber()}</Text>
          </View>
          <View style={styleSheet.row}>
            <Text style={styleSheet.boldFont}>Date of issue: </Text>
            <Text style={styleSheet.normalFont}>{formattedDateOfIssue}</Text>
          </View>
        </View>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styleSheet.spaceBetween}>
      <View style={styleSheet.addressWrapper}>
        <View style={styleSheet.marginAbove}>
          <Text>Bill from</Text>
          <Text style={styleSheet.boldFont}>The Local Hire Group Pty Ltd</Text>
          <Text>Level 8, 607 Bourke Street</Text>
          <Text>Melbourne VIC 3000</Text>
          <Text>AUSTRALIA</Text>
        </View>
        <View style={styleSheet.marginAbove}>
          <Text>e: hello@localtrailerhire.com.au</Text>
          <Text>ABN: 60 631 399 981</Text>
        </View>
      </View>
    </View>
  );

  const UserAddress = () => (
    <View style={styleSheet.addressWrapper}>
      <View>
        <Text>Bill to </Text>
        <Text style={styleSheet.boldFont}>{customerName}</Text>
        <Text>{customerAddressStreet}</Text>
        <Text>
          {customerAddressSuburb} {customerAddressState} {customerAddressPostCode}
        </Text>
        <Text>AUSTRALIA</Text>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={[styleSheet.row]}>
      <View style={[styleSheet.theader, styleSheet.theaderWide]}>
        <Text>Description</Text>
      </View>
      <View style={[styleSheet.theader, styleSheet.rightAligned]}>
        <Text>Amount</Text>
      </View>
    </View>
  );

  const Footer = () => (
    <View style={styleSheet.footer}>
      <Text>Thank you for using localtrailerhire.com.au</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styleSheet.page}>
        <InvoiceTitle />
        <View style={styleSheet.row}>
          <Address />
          <UserAddress />
        </View>

        <TableHead />
        <BookingBreakdownPdf
          transaction={transaction}
          userRole={userRole}
          unitType={unitType}
          timeZone={timeZone}
        />
        <Footer />
      </Page>
    </Document>
  );
};

const { string, oneOf } = PropTypes;

Invoice.defaultProps = {
  dateType: null,
  timeZone: null,
};

Invoice.propTypes = {
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,
};

export default Invoice;
