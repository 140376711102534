import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/EarningsPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCHED_ALL_SALES = 'app/EarningsPage/FETCHED_ALL_SALES';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/EarningsPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/EarningsPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

const uniqueRefs = refs => {
  return [...new Set(refs.map(JSON.stringify))].map(JSON.parse);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCHED_ALL_SALES:
      return { ...state, fetchInProgress: false };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        transactionRefs: uniqueRefs([...state.transactionRefs, ...entityRefs(transactions)]),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchedAllSales = () => ({ type: FETCHED_ALL_SALES });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const MAX_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  return numPages(dispatch, sdk).then(numPages => {
    const pages = [...Array(numPages).keys()].map(i => i + 1);
    return Promise.all(pages.map(p => fetchPage(dispatch, sdk, p))).then(() =>
      dispatch(fetchedAllSales())
    );
  });
};

const numPages = (dispatch, sdk) => {
  const apiQueryParams = {
    only: 'sale',
    per_page: MAX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      return response.data.meta.totalPages;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

const fetchPage = (dispatch, sdk, page) => {
  const apiQueryParams = {
    only: 'sale',
    include: ['booking', 'listing'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payoutTotal',
      'metadata',
    ],
    page,
    per_page: MAX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
