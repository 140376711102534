import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton, FieldGroupDriversLicence, NamedLink } from '../../components';
import { propTypes } from '../../util/types';
import { getAddress } from '../../util/address';

import css from './LicenceDetailsForm.module.css';

class LicenceDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveProfileError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
          } = fieldRenderProps;
          const { driversLicenceExpiryDate } = values;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { protectedData } = user.attributes.profile;
          const currentDriversLicenceExpiryDate = protectedData.driversLicenceExpiryDate;

          // has the expiry date changed
          const driversLicenceExpiryDateChanged =
            currentDriversLicenceExpiryDate !== driversLicenceExpiryDate;

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristineSinceLastSubmit || inProgress || !driversLicenceExpiryDateChanged;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <FieldGroupDriversLicence
                formId={formId}
                driversLicenceExpiryDate={values.driversLicenceExpiryDate}
                licenceYears={'lth'}
                intl={intl}
                values={values}
              />

              <p className={css.driversLicenceLabel}>Address</p>
              <p className={css.licenceValue}>
                {getAddress(protectedData?.building, protectedData?.residentialAddress)}
              </p>
              <p>
                If this is not the address listed on your licence, you can update your address on
                your <NamedLink name="ContactDetailsPage">Account page</NamedLink>.
              </p>

              <div className={css.bottomWrapper}>
                {!!saveProfileError ? (
                  <span className={css.error}>
                    <FormattedMessage id="LicenceDetailsForm.licenceExpiryUpdateFailure" />
                  </span>
                ) : null}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="LicenceDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

LicenceDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  saveProfileError: null,
  currentUser: null,
};

LicenceDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  saveProfileError: propTypes.error,
};

const LicenceDetailsForm = compose(injectIntl)(LicenceDetailsFormComponent);

LicenceDetailsForm.displayName = 'LicenceDetailsForm';

export default LicenceDetailsForm;
