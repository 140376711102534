import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemManualCustomerCommissionTaxMaybe } from '../../util/bookingBreakdown/lineItemManualCustomerCommissionTaxMaybe';
import { styles } from '../Invoice/InvoiceStyles';
import { bool } from 'prop-types';

const styleSheet = styles;

const LineItemManualCustomerCommissionTaxMaybePdf = props => {
  const { transaction, intl } = props;
  const { label, price, showManualFeeGstAdjustment } = lineItemManualCustomerCommissionTaxMaybe(
    transaction,
    intl
  );

  return showManualFeeGstAdjustment ? (
    <View style={styleSheet.row}>
      <View style={styleSheet.tColumnLeftWide}>
        <Text>{label}</Text>
      </View>
      <View style={styleSheet.rightAligned}>
        <Text>{price}</Text>
      </View>
    </View>
  ) : null;
};

LineItemManualCustomerCommissionTaxMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape,
};

export default LineItemManualCustomerCommissionTaxMaybePdf;
