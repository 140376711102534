import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v1/TDTjCH39JjVycIF24TlO-Q.ttf',
  fontStyle: 'normal',
  fontWeight: 'normal',
});

Font.register({
  family: 'Poppins-Bold',
  src: 'https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf',
  fontWeight: 'bold',
});

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
    fontFamily: 'Poppins',
  },
  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
  },
  boldFont: { fontSize: 11, fontFamily: 'Poppins-Bold' },

  normalFont: { fontSize: 11, fontFamily: 'Poppins' },

  rightAligned: { width: 50, flexDirection: 'row', justifyContent: 'flex-end' },

  marginAbove: { marginTop: 4 },

  marginAboveLarge: { marginTop: 10 },
  paddingAboveLarge: { paddingTop: 10 },

  paddingAbove: { paddingTop: 4 },

  titleContainer: {
    flexDirection: 'row',
    marginTop: 24,
    marginBottom: 24,
    fontFamily: 'Poppins-Bold',
  },

  largeTitle: { fontSize: 16, fontFamily: 'Poppins-Bold' },
  smallFont: { fontSize: 9 },

  logo: { width: 200 },

  addressWrapper: { width: 200 },

  tableContainer: { marginTop: 4 },

  borderTop: { borderTopWidth: 1 },

  theader: {
    marginTop: 20,
    fontSize: 11,
    fontFamily: 'Poppins-Bold',
    paddingTop: 4,
    height: 24,
    borderBottomWidth: 1,
    width: 50,
    flexDirection: 'row',
  },

  theaderWide: { width: 500 },

  tbody1: { width: 500 },
  tbody3: { width: 50 },
  tbody4: { width: 240 },

  tColumnLeft: { width: 370 },
  tColumnLeftWide: {
    width: 500,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  tColumnCentre: { width: 130, flexDirection: 'row', justifyContent: 'flex-end', marginRight: 20 },

  footer: {
    fontFamily: 'Poppins-Bold',
    marginTop: 24,
    textAlign: 'center',
  },
});
