import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBookmark.module.css';

const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';

const IconBookmark = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);
  if (size === SIZE_SMALL) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        style={{
          display: 'block',
          height: '8px',
          width: '8px',
          fill: 'currentColor',
          stroke: 'currentColor',
        }}
        aria-hidden="true"
        role="presentation"
        focusable="false"
        className={classes}
      >
        <path d="M12.5 0h-9A1.5 1.5 0 0 0 2 1.5v13.98l6-4.36 6 4.36V1.5A1.5 1.5 0 0 0 12.5 0z"></path>
      </svg>
    );
  } else if (size === SIZE_BIG) {
    return (
      <svg
        className={classes}
        strokeWidth="2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        style={{ display: 'block', height: '24px', width: '24px', fill: 'currentColor' }}
      >
        <path d="M12.5 0h-9A1.5 1.5 0 0 0 2 1.5v13.98l6-4.36 6 4.36V1.5A1.5 1.5 0 0 0 12.5 0z"></path>
      </svg>
    );
  } else {
    return null;
  }
};

IconBookmark.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconBookmark.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconBookmark;
