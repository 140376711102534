import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionOwnerPrintableFlyers.module.css';

const SectionOwnerPrintableFlyersComponent = props => {
  const { rootClassName, className, listings } = props;
  const classes = classNames(rootClassName || className);

  const [isDownloadClicked, setIsDownloadClicked] = useState(null);

  useEffect(() => {
    const onViewFlyerClick = (event, listingId) => {
      event.preventDefault();
      // Add Google Analytics event
      window.gtag?.('event', 'view_flyer');
      window.location.href = `/api/flyer?id=${listingId}`;
    };

    const onDownloadFlyerClick = (event, listingId, title) => {
      event.preventDefault();
      // Add Google Analytics event
      window.gtag?.('event', 'download_flyer');
      download(`/api/flyer?id=${listingId}`, title);
      setIsDownloadClicked(listingId);
    };

    listings.forEach(listing => {
      const viewFlyerLink = document.getElementById(`viewFlyerLink_${listing.id.uuid}`);
      const downloadFlyerLink = document.getElementById(`downloadFlyerLink_${listing.id.uuid}`);

      if (viewFlyerLink) {
        viewFlyerLink.addEventListener('click', event => onViewFlyerClick(event, listing.id.uuid));
      }

      if (downloadFlyerLink) {
        downloadFlyerLink.addEventListener('click', event =>
          onDownloadFlyerClick(event, listing.id.uuid, listing.attributes.title)
        );
      }
    });

    function download(url, title) {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${title}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    return () => {
      listings.forEach(listing => {
        const viewFlyerLink = document.getElementById(`viewFlyerLink_${listing.id.uuid}`);
        const downloadFlyerLink = document.getElementById(`downloadFlyerLink_${listing.id.uuid}`);

        if (viewFlyerLink) {
          viewFlyerLink.removeEventListener('click', onViewFlyerClick);
        }

        if (downloadFlyerLink) {
          downloadFlyerLink.removeEventListener('click', onDownloadFlyerClick);
        }
      });
    };
  }, [listings]);

  return (
    <React.Fragment>
      <div className={classes}>
        <h3 className={css.heading}>Print your own customised flyers</h3>
        <p>
          Advertise your trailer on community noticeboards, at your local shops, or around your
          neighbourhood.
        </p>
        <div>
          <table className={css.listingsTable}>
            <tbody>
              {listings.map(listing => (
                <tr key={listing.id.uuid}>
                  <td>{listing.attributes.title}</td>
                  <td className={css.listingsTableColumn}>
                    <a
                      href={`/api/flyer?id=${listing.id.uuid}`}
                      id={`viewFlyerLink_${listing.id.uuid}`}
                    >
                      View flyer
                    </a>
                  </td>
                  <td className={css.listingsTableColumn}>
                    <a
                      className={css.buttonLink}
                      href={`/api/flyer?id=${listing.id.uuid}`}
                      download={`${listing.attributes.title}.pdf`}
                      id={`downloadFlyerLink_${listing.id.uuid}`}
                    >
                      {isDownloadClicked === listing.id.uuid ? 'Download initiated' : 'Download'}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

SectionOwnerPrintableFlyersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf, shape } = PropTypes;

SectionOwnerPrintableFlyersComponent.propTypes = {
  rootClassName: string,
  className: string,
  listings: arrayOf(
    shape({
      id: shape({
        uuid: string.isRequired,
      }).isRequired,
      attributes: shape({
        title: string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
};

export default SectionOwnerPrintableFlyersComponent;
