import React from 'react';
import { string } from 'prop-types';
import { IconReviewStar } from '..';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';

import css from './OwnerStats.module.css';

import superOwnerIcon from '../../assets/super-owner-icon.png';

const OwnerStats = props => {
  const { rootClassName, className, ownerUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  const numHires = ownerUser.attributes.profile.publicData?.bookingStatsAllTime?.numHires || 0;
  const numberOfReviews = ownerUser.attributes.profile.publicData?.reviewStats?.numReviews || 0;
  const averageRating =
    ownerUser.attributes.profile.publicData?.reviewStats?.avgReviewRating.toFixed(2) || 0;
  const responseDelay =
    ownerUser.attributes.profile.publicData?.responseStatsLast12Months
      ?.meanBookingResponseDelayDisplay || '';
  const acceptanceRate =
    ownerUser.attributes.profile.publicData?.bookingStatsLast3Months?.acceptanceRate || '';
  const ownerOrBusinessName = ownerUser.attributes.profile.publicData?.businessName
    ? ownerUser.attributes.profile.publicData?.businessName
    : ownerUser.attributes.profile.displayName;

  const isSuperOwner = !!ownerUser?.id && ownerUser?.attributes?.profile?.publicData?.superOwner;
  const superOwnerStatus = isSuperOwner ? (
    <div className={css.superOwnerWrapper}>
      <img src={superOwnerIcon} alt="super owner badge" className={css.superOwnerIcon} />
      <div>
        <p className={css.superOwnerTitle}>{ownerOrBusinessName} is a Super Owner</p>
        <p className={css.superOwnerExplanation}>
          Super Owners are top-rated, experienced owners who make hiring easy.
        </p>
      </div>
    </div>
  ) : null;

  return numHires > 1 ? (
    <div className={classes}>
      <div className={css.ownerStats}>
        {numberOfReviews > 0 ? (
          <React.Fragment>
            <span>
              <IconReviewStar rootClassName={css.star} />
              {averageRating}
            </span>
            <span className={css.linkSeparator}>•</span>
          </React.Fragment>
        ) : null}
        <span>{numberOfReviews} reviews</span>
        <span className={css.linkSeparator}>•</span>
        <span>{numHires} hires</span>
        <div className={css.ownerStatLong}>
          {config.custom.featureToggles[config.env].ownerAcceptanceRate &&
          acceptanceRate &&
          responseDelay ? (
            <p>
              Accepts {(acceptanceRate * 100).toFixed(0)}% of the time, usually within{' '}
              {responseDelay}.
            </p>
          ) : null}
          {!config.custom.featureToggles[config.env].ownerAcceptanceRate && responseDelay ? (
            <p>Usually responds within {responseDelay}.</p>
          ) : null}
          {superOwnerStatus}
        </div>
      </div>
    </div>
  ) : null;
};

OwnerStats.defaultProps = {
  rootClassName: null,
  className: null,
};

OwnerStats.propTypes = {
  rootClassName: string,
  className: string,
  ownerUser: propTypes.user,
};

export default OwnerStats;
