import React from 'react';
import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';
import { IconSuccess } from '../../components';

import css from './InfoBox.module.css';

const InfoBox = props => {
  const { rootClassName, className, content, infoType } = props;

  const classes = classNames(rootClassName || css.root, className, {
    [css.infoBoxInformation]: infoType === 'information',
    [css.infoBoxSuccess]: infoType === 'success',
    [css.infoBoxWarning]: infoType === 'warning',
  });

  return (
    <div className={classes}>
      {infoType === 'success' && <IconSuccess className={css.icon} />}
      <>{content}</>
    </div>
  );
};

InfoBox.defaultProps = {
  rootClassName: null,
  className: null,
  infoType: 'information',
};

const { string } = PropTypes;

InfoBox.propTypes = {
  rootClassName: string,
  className: string,
  content: PropTypes.node.isRequired,
  infoType: PropTypes.oneOf(['information', 'success', 'warning']),
};

export default InfoBox;
