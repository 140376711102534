import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Slider from 'react-slick';

import { IconReviewStar } from '../../components';
import css from './SectionForOwnersTestimonials.module.css';

import { REVIEW_RATINGS } from '../../util/types';
import leahImage from './images/leah.jpeg';
import justinImage from './images/justin.jpg';
import philImage from './images/phil.jpeg';
import graemeImage from './images/graeme.jpeg';
import minhImage from './images/minh.jpeg';
import leiciaImage from './images/leicia.jpeg';

const SectionForOwnersTestimonials = props => {
  const { rootClassName, className } = props;

  const stars = REVIEW_RATINGS;
  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    accessibility: true,
  };

  return (
    <div className={classes}>
      <h3 className={css.heading}>What trailer owners have to say</h3>
      <div className={css.content}>
        <Slider {...settings}>
          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “localtrailerhire.com.au is a terrific business providing us with trailer hirers who
                are pleasant to deal with and considerate. The fact that they take care of all the
                payments makes transacting very simple. The team offer personal service and their
                help to solve problems is greatly appreciated. I would recommend the service.”
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={graemeImage}
                  alt="photo of graeme's car trailer"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Graeme Barry</div>
                  <div className={css.testimonialLocationAndDate}>Mitcham</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “Using localtrailerhire has been great! Not only has it given me the chance to find
                renters easily, reliably and hassle free, it's also helping pay for my expensive
                trailer. Duncan and the team have delivered a great experience all round."
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={minhImage}
                  alt="photo of minh's cage trailer"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Minh Cao</div>
                  <div className={css.testimonialLocationAndDate}>Tarneit</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “Listing our trailer on localtrailerhire.com.au has been a great experience. Having
                the website take care of payment, bookings, scheduling and reminders makes the whole
                transaction smooth and hassle-free. Highly recommended”
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={justinImage}
                  alt="photo of Justin, a happy trailer owner"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Justin Carroll</div>
                  <div className={css.testimonialLocationAndDate}>Bacchus Marsh</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                <p>
                  “Hi, I’m Leah - a stay at home mum living in beautiful Far North Queensland with
                  my husband and our two young boys. Needing to hire a trailer recently, I came
                  across localtrailerhire.com.au. The whole process was so simple, from booking to
                  picking up and returning. The trailer was great, the trailer owner was helpful and
                  localtrailerhire.com.au took care of everything else; so much better than a
                  service station.
                </p>
                <p>
                  “I wasn’t looking for a side hustle but I was so impressed by the simplicity of
                  the model I thought 'why am I not doing this myself?' That was it – I bought a
                  trailer, listed it on localtrailerhire.com.au and haven’t looked back. It’s been
                  amazing. I’d never used a trailer on my own before, but with a bit of practice,
                  it’s really not that hard. I’ve only been doing this a short time but the return
                  has been so good I’m already thinking of buying another trailer. Anyone can do it,
                  why not give it a go!”
                </p>
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={leahImage}
                  alt="photo of Leah, a happy trailer owner with trees in the background"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Leah B</div>
                  <div className={css.testimonialLocationAndDate}>Cairns</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                "Our ute had finally packed it in and we were in desperate need of a tip run so we
                looked to hiring a trailer, and that's how we found localtrailerhire.com.au. We saw
                the link for "add your trailer" so instead of hiring, we purchased and haven't
                looked back since! With a booking nearly every week it's been a great little
                sideline and has nearly paid for itself! For us it has been a positive and enjoyable
                experience so with a simple booking interface and a friendly team I say go for
                it!!""
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={leiciaImage}
                  alt="photo of leicia's cage trailer"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Leicia</div>
                  <div className={css.testimonialLocationAndDate}>Bundaberg</div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “I purchased a new Tandem Trailer (10x5 with cage) about 10 months ago. Came across
                localtrailerhire.com.au and this seemed like a good way to get back some of the
                cost. I found it a breeze to add my new trailer and now it's often booked two or
                more days each week. I have had no problems with the hirers apart from a couple who
                were late returning the trailer and didn't bother to text or phone. The whole
                process is very smooth and the website makes communication easy."
              </div>
              <div className={css.testimonialGiver}>
                <img
                  src={philImage}
                  alt="photo of Phillip, a happy trailer owner"
                  className={css.testimonialPhoto}
                />
                <div>
                  <div className={css.testimonialName}>Phillip Urquhart</div>
                  <div className={css.testimonialLocationAndDate}>Carnegie</div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

SectionForOwnersTestimonials.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForOwnersTestimonials.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForOwnersTestimonials;
