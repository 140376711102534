import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionBoxTrailers.module.css';

const SectionBoxTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.boxTrailersContent}>
        <h3 className={css.heading}>
          How to find the right box trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired box trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.boxTrailersContent}>
        <h3 className={css.heading}>Box trailers FAQ</h3>
        <h4 className={css.subheading}>What is a box trailer?</h4>
        <p>
          The box shape of the trailer gives it its name. A box trailer is a type of trailer with a
          rectangular or square-shaped cargo area, and low sides. These trailers are commonly used
          for tip runs, transporting household items, moving gardening equipment, collecting
          construction materials, and more. Because of its simple design with often an open top, a
          box trailer can be used to carry a wide variety of items in different ways.
        </p>
      </div>
      <div className={css.boxTrailersContent}>
        <h4 className={css.subheading}>What could I use a box trailer for?</h4>
        <p>
          Box trailers are great for hauling all sorts of things, from couches to construction gear
          to your favourite two-wheeler. Just remember: the open top design means you'll need to tie
          everything down extra tight to keep it from flying out. And if you're headed out in bad
          weather, make sure you've got a tarp or cover handy to keep your goods dry. It's a minor
          hassle, but totally worth it for the convenience of using a box trailer.
        </p>
      </div>
      <div className={css.boxTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right size box trailer?</h4>
        <p>
          Looking for the perfect box trailer size is like finding the right pair of shoes - it
          needs to fit just right! So, before you choose your trailer, think about what you want to
          carry, and how much of it there is. Also, make sure your car can handle towing the trailer
          size you want. You can find a range of box trailer sizes on Local Trailer Hire, from
          smaller ones for light loads to larger ones for big hauls.
        </p>
      </div>
      <div className={css.boxTrailersContent}>
        <h4 className={css.subheading}>
          Why are the box trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          The price of a box trailer for hire on Local Trailer Hire is set by the trailer owner. The
          cost of hiring a box trailer may vary depending on factors such as the trailer's size,
          condition, and extra features like a ramp. You can use the search filters on Local Trailer
          Hire to find the best box trailer that fits your budget.
        </p>
      </div>
      <div className={css.boxTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('enclosed')}</li>
          <li>{categoryLink('car')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};

SectionBoxTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionBoxTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBoxTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBoxTrailersComponent;
