import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './RentalTerms.module.css';

const RentalTerms = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 5 April, 2024</p>
      <h2>1.	Governing Terms and Conditions</h2>
      <p>1.1.	Your contract to hire a Trailer from Local Trailer Hire (<b>Rental Contract</b>) consists of:</p>
      <p className={css.subpoint}>(a)	these rental Terms and Conditions (<b>Rental Terms and Conditions</b>);</p>
      <p className={css.subpoint}>(b)	the Rental Agreement;</p>
      <p className={css.subpoint}>(c)	the Website Terms of Service; and </p>
      <p className={css.subpoint}>(d)	the Privacy Policy.</p>
      <p>1.2.	The Rental Contract is governed by the laws of the state of Victoria and You agree that courts in that state have non-exclusive jurisdiction to determine any dispute that arises between You and Us.</p>
      <p>1.3.	The Australian Consumer Law applies to the Rental Contract and it provides You with rights that are not excluded, restricted or modified by the Rental Contract and any provision in this contract is subject to the specific protections and guarantees in that and any corresponding Federal, State or Territory legislation. </p>
      <h2>2.	Our Guarantee</h2>
      <p>2.1.	<b>Trailer Guarantee</b></p>
      <p>To give You confidence in Our services Local Trailer Hire will pay up to $2,000 to repair, replace or contribute to the replacement of the Trailer if it is damaged, stolen or written off whilst on a hire booked by You through www.localtrailerhire.com.au</p>
      <p>2.2.	<b>Quality Assurance</b> </p>
      <p>Local Trailer Hire assures You of a quality rental service at all times. </p>
      <p>2.3.	<b>Service</b> </p>
      <p>We are committed to providing You with the highest standards of service.</p>
      <p>2.4.	<b>Trailer Guarantee Conditions</b></p>
      <p className={css.subpoint}>(a)	To be eligible for the Trailer Guarantee You and the Owner <b>must</b> take the photos referred to in:</p>
      <p className={css.subsubpoint}>(i)	clause 4.2(d) at handover; and </p>
      <p className={css.subsubpoint}>(ii)	clause 8.4(c) when the Trailer is returned; and</p>
      <p className={css.subpoint}>(b)	an additional  Damage Excess of up to $1,000 will be deducted from the payment card You used for payment, if the Trailer is damaged, stolen or written off.</p>
      <h2>3.	Conditions of Use</h2>
      <p>3.1.	The Trailer is rented to You by the Owner subject to Your acceptance and compliance with these Rental Terms and Conditions. </p>
      <p>3.2.	The Trailer <b>must</b> only be towed by You. </p>
      <p>3.3.	It is a Major Breach of the Rental Contract if You let anyone else tow the Trailer.  If there is a Major Breach of the Rental Contract there is no entitlement to the benefit of the Trailer Guarantee and the Trailer may be repossessed.</p>
      <p>3.4.	There is a minimum and maximum age limits for those renting a Trailer.  You <b>must</b> be at least 21 and not over 75 years of age and have no less than 12 months driving experience, unless We have agreed to a variation of those restrictions before the start of the Rental Period and it is shown in the Rental Agreement.</p>
      <p>3.5.	You <b>must</b> also have a valid drivers' licence to tow the Trailer which is issued in an Australian state or territory and not subject to any restriction or condition.  Learner drivers and provisional and probationary licence holders are not acceptable and <b>must not</b> tow the Trailer. </p>
      <p>3.6.	The Trailer <b>must not</b> be towed if Your licence has been cancelled within 2 years of the date of the Rental Agreement.</p>
      <p>3.7.	The Trailer <b>must not</b> be towed by You:</p>
      <p className={css.subpoint}>(a)	if You are intoxicated or under the influence of drugs or alcohol or have a blood alcohol content or any urine or oral fluid sample that exceeds the limit set by law;</p>
      <p className={css.subpoint}>(b)	recklessly or dangerously; or</p>
      <p className={css.subpoint}>(c)	whilst the Trailer is damaged or unsafe.</p>
      <p>3.8.	You and <b>must not</b>:</p>
      <p className={css.subpoint}>(a)	fail or refuse to undergo any breath, blood, urine or oral fluid test or drug impairment assessment;</p>
      <p className={css.subpoint}>(b)	use the Trailer:</p>
      <p className={css.subsubpoint}>(i)	for any illegal purpose;</p>
      <p className={css.subsubpoint}>(ii)	to move dangerous, hazardous, inflammable goods or substances that pollute or contaminate, in quantities above that used for domestic purposes;</p>
      <p className={css.subsubpoint}>(iii)	in connection with the motor trade for experiments, tests, trials or demonstration purposes; or</p>
      <p className={css.subsubpoint}>(iv)	in an unsafe or un-roadworthy condition. </p>
      <p>3.9.	You <b>must not</b>:</p>
      <p className={css.subpoint}>(a)	damage the Trailer deliberately or recklessly or allow anyone else to do so;</p>
      <p className={css.subpoint}>(b)	modify the Trailer in any way;</p>
      <p className={css.subpoint}>(c)	sell, rent, lease or dispose of the Trailer; or</p>
      <p className={css.subpoint}>(d)	register or claim to be entitled to register any interest in the Trailer under the Personal Property Securities Act 2009. </p>
      <p>3.10.	The Trailer <b>must never</b> be towed on:</p>
      <p className={css.subpoint}>(a)	an Unsealed Road; </p>
      <p className={css.subpoint}>(b)	Off Road; or</p>
      <p className={css.subpoint}>(c)	above the snow line in Victoria or New South Wales between 1 May and 31 October.</p>
      <p>3.11.	The Trailer <b>must not</b> be used in any area that is prohibited by Us.  Prohibited areas include: </p>
      <p className={css.subpoint}>(a)	roads that are prone to flooding or are flooded;</p>
      <p className={css.subpoint}>(d)	beaches, streams, rivers, creeks, dams and floodwaters;</p>
      <p className={css.subpoint}>(e)	any road where the police or an authority has issued a warning;</p>
      <p className={css.subpoint}>(f)	any road that is closed; and</p>
      <p className={css.subpoint}>(g)	any road where it would be unsafe to tow the Trailer.</p>
      <p>3.12.	The Trailer <b>must never</b> be towed or used on any island that is off mainland Australia, except Phillip Island, unless We have given Our prior written permission prior to the start of the Rental Period and it is shown in the Rental Agreement.</p>
      <p>3.13.	You <b>must</b>:</p>
      <p className={css.subpoint}>(a)	ensure:</p>
      <p className={css.subsubpoint}>(i)	the aggregate Trailer Mass (Trailer plus load) does not exceed the maximum towing capacity of the Trailer as specified by the Trailer manufacturer.  The towing capacity of a vehicle is usually provided in the vehicle operator's handbook and You <b>must</b> also check the towbar’s manufacturer's specification plate on the towbar as the two may differ;</p>
      <p className={css.subsubpoint}>(ii)	the Trailer is correctly and safely connected to the towing vehicle and the safety chains are correctly fitted;</p>
      <p className={css.subsubpoint}>(iii)	the Trailer remains connected to the towing vehicle at all times whilst it is under hire;</p>
      <p className={css.subsubpoint}>(iv)	the coupling is attached to the towing vehicle at all times when loading, whilst loaded and during unloading, and You acknowledge the jockey wheel is for raising and lowering the Trailer and <b>must not</b> be used to manoeuvre the Trailer;</p>
      <p className={css.subsubpoint}>(v)	the coupling handle is correctly down;</p>
      <p className={css.subsubpoint}>(vi)	the Trailer's tyres are inflated to the recommended PSI;</p>
      <p className={css.subsubpoint}>(vii)	the Trailer lights are working correctly; and</p>
      <p className={css.subsubpoint}>(viii)	when parked, the Trailer is always parked securely in a way in which it can not be removed from the towing vehicle.</p>
      <p className={css.subpoint}>(b)	have insurance that provides:</p>
      <p className={css.subsubpoint}>(i)	cover for personal injury; and </p>
      <p className={css.subsubpoint}>(ii)	indemnity for third party loss, and </p>
      <p className={css.subpoint}>and take reasonable steps to have an insurance policy that provides cover for Damage to the Trailer, arising from Your use of the Trailer during the Rental Period.</p>
      <p className={css.subpoint}>(c)	secure any property, goods, stock or equipment carried in the Trailer and use suitable tie down materials to ensure that under no circumstances will they fall from the Trailer during transit; and</p>
      <p className={css.subpoint}>(d)	comply with all road rules and regulations and You are always responsible for the correct and safe handling of the Trailer. </p>
      <p>3.14.	You <b>must not</b> tow the Trailer if the unladen weight of the towing vehicle is less than the total weight of the Trailer and any property, goods, stock or equipment or load on the Trailer.</p>
      <p>3.15.	You <b>must</b> take reasonable care of the Trailer by:</p>
      <p className={css.subpoint}>(a)	preventing it from being damaged;</p>
      <p className={css.subpoint}>(b)	making sure that it is protected from the weather;</p>
      <p className={css.subpoint}>(c)	making sure it is not overloaded; and</p>
      <p className={css.subpoint}>(d)	when parked, ensuring the Trailer is always parked securely in a way in which it can not be removed from the towing vehicle.</p>
      <p>3.16.	If the Trailer develops a fault during the Rental Period You <b>must</b> inform Us immediately and not tow the Trailer unless We have authorised You to do so and You <b>must not</b> let anyone else repair or work on the Trailer or towing or salvage of it without Our prior written authority to do so. </p>
      <p>3.17.	You <b>must</b> immediately report any loss or damage to the Trailer (or loss involving the Trailer) to the Owner and Local Trailer Hire.</p>
      <p>3.18.	If You wish to extend the Rental Period You <b>must</b> make another booking as rentals cannot be extended.  Any new booking is subject to the Trailer not having been booked by another renter and it not being in use by the Owner. </p>
      <h2>4.	Trailer handover procedure - Renter</h2>
      <p>4.1.	You <b>must</b> attend the Collection Location at the time and date agreed with the Owner to pick up the Trailer.  Before proceeding with the hire You may inspect the Trailer and, should it not meet the advertised specification, You are under no obligation to complete the hire.</p>
      <p>4.2.	At the handover of the Trailer, You <b>must</b>, in the Owner's presence:</p>
      <p className={css.subpoint}>(a)	produce Your Driver’s Licence for inspection by the Owner;</p>
      <p className={css.subpoint}>(b)	check that:</p>
      <p className={css.subsubpoint}>(i)	the Trailer is in a roadworthy, serviceable and in a safe condition;</p>
      <p className={css.subsubpoint}>(ii)	the Trailer is clean; and</p>
      <p className={css.subsubpoint}>(iii)	the Owner has removed all personal belongings from the Trailer.</p>
      <p className={css.subpoint}>(c)	review the Rental Agreement; and</p>
      <p className={css.subpoint}>(d)	<b>Special Notice:</b>  <br />to be eligible for Our Trailer Guarantee in clause 2.1 at handover You and the Owner <b>must</b> take high definition digital photographs of the Trailer, using flash if the handover is not in daylight, showing its condition including:</p>
      <p className={css.subsubpoint}>(i)	the front and rear;</p>
      <p className={css.subsubpoint}>(ii)	each of the wheels; and</p>
      <p className={css.subsubpoint}>(iii)	the sides; </p>
      <p className={css.subpoint}>and photos of the same areas <b>must</b> be taken when the Trailer is returned.</p>
      <p>4.3.	If at the time of hand over to You the Owner fails to take the photographs required by clause 4.2(d) neither You nor the Owner will have the benefit of the Trailer Guarantee.</p>
      <h2>5.	Financial Obligations - Renter</h2>
      <p>5.1.	You <b>must</b> pay Local Trailer Hire the Rental Fee shown on the Rental Agreement which includes:</p>
      <p className={css.subpoint}>(a)	the Owner Rate; and</p>
      <p className={css.subpoint}>(b)	Service Fee.</p>
      <p>5.2.	Upon receipt of your booking request, Local Trailer Hire will pre-authorise your payment card for the anticipated Rental Fee and, upon the Owner's acceptance of your booking request, your payment card will be debited the confirmed Rental Fee.</p>
      <p>5.3.	Amounts payable to Local Trailer Hire by You under these Rental Terms and Conditions:</p>
      <p className={css.subpoint}>(a)	must be paid to Local Trailer Hire on or before the due date; and</p>
      <p className={css.subpoint}>(b)	accrue interest on any overdue amounts daily, from the date when payment becomes due, until the date of payment, at a rate of two and a half percent (2.5%) per calendar month (and at Local Trailer Hire’s sole discretion such interest shall compound monthly at such a rate) as well as before any judgment. </p>
      <p>5.4.	Payment card authority</p>
      <p>You authorise Us to maintain Your payment card details in accordance with the Local Trailer Hire Privacy Policy and if any amount is due under the Rental Contract or remains unpaid, including:</p>
      <p className={css.subpoint}>(a)	the Rental Fees;</p>
      <p className={css.subpoint}>(b)	tolls;</p>
      <p className={css.subpoint}>(c)	speeding and traffic fines and infringements; </p>
      <p className={css.subpoint}>(d)	fines or charges imposed for parking; </p>
      <p className={css.subpoint}>(e)	Damage under clause 9.2; </p>
      <p className={css.subpoint}>(f)	bank fees incurred by Us as a result of any dishonoured or rejected payment card transaction;</p>
      <p className={css.subpoint}>(g)	all costs incurred by Us in the recovery of outstanding amounts, including interest, debt collectors’ costs and commission and legal expenses;</p>
      <p className={css.subpoint}>(h)	cleaning fees to return the trailer to the same level of cleanliness as at time of pick up;</p>
      <p className={css.subpoint}>(i)	tipping fees if there is waste left in the trailer when returned;</p>
      <p className={css.subpoint}>(j)	fees for the recovery of the trailer if not returned to the collection location;</p>
      <p className={css.subpoint}>(k)	administration fees to coordinate these activities or process charges,</p>
      <p>You authorise Us to debit Your payment card with that amount upon it becoming due and payable. </p>
      <h2>6.	Cancellation</h2>
      <p>6.1.	Bookings may only be cancelled in accordance with this clause 6. </p>
      <p>6.2.	Any booking request that has not been confirmed by the Owner can be cancelled at any time before confirmation.</p>
      <p>If You cancel a confirmed booking 24 hours or more prior to the start of the Rental Period You will be refunded all prepaid Rental Charges. However, if You:</p>
      <p className={css.subpoint}>(a)	cancel a booking within 24 hours prior to the start of the Rental Period; </p>
      <p className={css.subpoint}>(b)	fail to pick up the Trailer within 30 minutes of the start of the Rental Period as set out in the confirmed booking; or</p>
      <p className={css.subpoint}>(c)	fail to notify Us of Your intended cancellation prior to the start of the Rental Period and fail to pick up the Trailer, </p>
      <p>the booking will be cancelled and You will be charged the Rental Charges for the Rental Period as booked.</p>
      <p>6.3.	Your cancellation is not effective until acknowledged and confirmed by Us.</p>
      <p>6.4.	Local Trailer Hire may cancel a Rental Agreement without penalty to it or You if the Trailer is unavailable as a result of unforeseen circumstance, such as it having been involved in a prior accident.</p>
      <p>6.5.	If the Owner cancels a Rental Agreement after the Commencement of Rental and You are not in arrears, the Owner agrees that, in addition to the Owner being bound by the cancellation terms of the Owner Contract, You may keep the Trailer until such time as Local Trailer Hire has found a like for like replacement Trailer. </p>
      <h2>7.	Traffic Offences, Fines and Fees</h2>
      <p>7.1.	You <b>must</b> pay all fines, penalties, traffic infringements, tolls and costs incurred during the Rental Period or any period outside the Rental Period in relation to which You remain in possession of, or are responsible for, the Trailer. </p>
      <p className={css.subpoint}>(a)	a processing fee of $50 per toll is payable to Local Trailer Hire for each unpaid toll incurred, in addition to the toll amount and all other charges levied by the toll provider.</p>
      <p className={css.subpoint}>(b)	a processing fee of $50 is payable to Local Trailer Hire for each fine, penalty or traffic infringement notice, in addition to any charges levied by the issuer of the fine, penalty or traffic infringement.  </p>
      <p>7.2.	You <b>must</b> notify Local Trailer Hire and the Owner in writing in accordance with clause 13 as soon as You become aware that a fine, penalty, traffic infringement or toll has been incurred. </p>
      <p>7.3.	Upon receipt of a fine, penalty, traffic infringement or toll notice the Owner will complete the required documentation to absolve themselves from liability and nominate You as the responsible party and return the documentation and any statutory declaration to the responsible authority that issued the notice within 7 days of receipt of the notice. </p>
      <p>7.4.	In the event that You challenge any fine, penalty, infringement or toll notice, You do so at Your own expense.</p>
      <h2>8.	Trailer Condition and Return</h2>
      <p>8.1.	You <b>must</b> return the Trailer to the Owner at the Return Location strictly at the time and on the date agreed between the Owner and You.  If the Trailer is returned more than 30 minutes, but less than 24 hours, after the agreed time for its return, a late fee of up to one full day's rental will apply. If returned later than 24 hours after the agreed time for its return, a late fee of the 24 hour rate plus a 25% premium will be charged per 24 hour period.</p>
      <p>8.2.	You remain responsible for the Trailer until it is returned to the Owner in accordance with clause 8.1 and these Rental Terms and Conditions continue to apply, regardless of whether it is returned during, at the end of, or after the expiry of, the relevant Rental Period. </p>
      <p>8.3.	Subject to clause 6, if You return the Trailer to the Owner before the end of the Rental Period You <b>must</b> pay Local Trailer Hire the amounts in clause 5.1 for the duration of the Rental Period unless otherwise agreed in writing by the Owner and Local Trailer Hire. </p>
      <p>8.4.	At the time of hand over to the Owner at the Return Location You <b>must</b>:</p>
      <p className={css.subpoint}>(a)	return the Trailer to the Owner in the same condition as it was at the beginning of the Rental Period, save for any reasonable wear and tear;</p>
      <p className={css.subpoint}>(b)	ensure that the Trailer has been cleaned prior to hand over to the Owner; </p>
      <p className={css.subpoint}>(c)	check that any photographs of the Trailer taken by the Owner, if taken, are as close as practicable to those areas photographed at the handover to You at the Commencement of the Rental Period and in the manner required by clause 4.2(d); and</p>
      <p className={css.subpoint}>(d)	<b>Special Notice:</b> <br />to be eligible for the Local Trailer Hire Trailer Guarantee in clause 2.1 You and the Owner <b>must</b>:</p>
      <p className={css.subsubpoint}>(i)	at handover have taken the photos referred to in clause 4.2(d); and </p>
      <p className={css.subsubpoint}>(ii)	on the return of the Trailer to the Owner take photos of the same areas that were taken at handover.  </p>
      <p>8.5.	If at the time of hand over back to the Owner, the Owner fails to take the photographs required by clause 8.4(d) neither You nor the Owner will have the benefit of the Trailer Guarantee.</p>
      <p>8.6.	If the Trailer is not returned at the time and date specified in the Rental Agreement or as agreed between the Owner and You the Trailer may immediately be reported as stolen and may be de-registered. </p>
      <h2>9.	No cover for Damage to the Trailer, theft or third party loss</h2>
      <p>9.1.	As the Rental Contract provides no damage or insurance cover, subject to the terms of the Trailer Guarantee (if applicable), You are responsible for all Damage to the Trailer, save for reasonable wear and tear, its loss through theft and any third party loss that occurs during the Rental Period which results from Your use of the Trailer.</p>
      <p>9.2.	The following amounts are payable if there is Damage or the Trailer is stolen:</p>
      <p className={css.subpoint}>(a)	Damage to the wheels and tyres: $100 per wheel;  </p>
      <p className={css.subpoint}>(b)	Damage to the electrical trailer connector: $50; </p>
      <p className={css.subpoint}>(c)	Any other amount for damage or theft up to the market value of the Trailer; or</p>
      <p className={css.subpoint}>(d)	the Trailer Guarantee Damage Excess (if applicable) for major Damage or theft of the Trailer: $1,000.</p>
      <p>9.3.	You <b>must</b> pay Local Trailer Hire the Rental Fee at the daily rate shown in the Rental Agreement from the date of an accident or theft of the Trailer until the earlier of:</p>
      <p className={css.subpoint}>(a)	payment of the Damage Excess;</p>
      <p className={css.subpoint}>(b)	the completion of repairs if the Trailer is damaged;</p>
      <p className={css.subpoint}>(c)	the Trailer is replaced because it is declared a total loss because it is damaged beyond repair; or because it is stolen.</p>
      <p>9.4.	You agree that entitlement to the benefit of the Trailer Guarantee is subject to:</p>
      <p className={css.subpoint}>(a)	a valid Rental Contract;</p>
      <p className={css.subpoint}>(b)	payment (per incident) of the Damage Excess;</p>
      <p className={css.subpoint}>(c)	compliance with these Rental Terms and Conditions;</p>
      <p className={css.subpoint}>(d)	You not being covered under any other policy of insurance; and</p>
      <p className={css.subpoint}>(e)	You providing all reasonable information and assistance as may be requested and, if necessary, authorising Local Trailer Hire to bring, defend or settle legal proceedings, and You agree that Local Trailer Hire shall at all times have sole conduct of any legal proceedings. </p>
      <p>9.5.	If there has been a successful recovery from a third party, the Damage Excess is refundable to You.  Any entitlement to a refund is subject to costs being recovered from third parties and is at the sole discretion of the Local Trailer Hire Insurer and not Local Trailer Hire.  If there is no third party loss and the cost of repairs is less than the Damage Excess You will be reimbursed the difference. </p>
      <p>9.6.	You <b>must</b> report all claims to Local Trailer Hire and the Owner immediately of any Damage, theft or third party loss. </p>
      <p>9.7.	In the event of an accident or damage, it is Your responsibility to return the trailer to the collection location at Your expense.</p>
      <p>9.8.	You <b>must</b> also report all accidents to the police if:</p>
      <p className={css.subpoint}>(a)	any person is injured;</p>
      <p className={css.subpoint}>(b)	the other driver leaves the scene of the accident without exchanging names and addresses; or</p>
      <p className={css.subpoint}>(c)	the other driver appears to be affected by drugs or alcohol. </p>
      <p>9.9.	In the event of an accident, You acknowledge that Local Trailer Hire or the Owner may insist that the Trailer be moved to the nearest repairer to secure it. </p>
      <p>9.10.	If the Trailer is damaged or stolen and, provided that You have not committed a Major Breach of the Rental Terms and Conditions, Local Trailer Hire may at its option:</p>
      <p className={css.subpoint}>(a)	cancel the Rental Agreement and, subject to the availability of a similar Trailer at a similar rental fee, grant You the option of entering into a new rental agreement for a term no less than the remaining term of the original Rental Agreement; or </p>
      <p className={css.subpoint}>(b)	cancel the Rental Agreement if no similar Trailer is available at a similar rental fee.</p>
      <p>9.11.	If the Rental Agreement is cancelled under clause 9.9 and notwithstanding clause 6, the Agreement is deemed to have been terminated by mutual consent and no action for damages or penalties will accrue to either Local Trailer Hire or the Owner as the result of that cancellation.</p>
      <h2>10.	No insurance cover for goods or property carried</h2>
      <p>The Rental Contract provides no insurance cover for:</p>
      <p className={css.subpoint}>(a)	loss or damage to goods or property carried in the Trailer whether owned by You or a third party and You agree to fully indemnify Local Trailer Hire for any claims for third party loss that arise from Your use of the Trailer during the Rental Period;</p>
      <p className={css.subpoint}>(b)	third party loss resulting from goods or property falling from the Trailer; or</p>
      <p className={css.subpoint}>(c)	personal items that are left in or stolen from the Trailer or for loss or damage to property belonging to or in the custody of:</p>
      <p className={css.subsubpoint}>(i)	You;</p>
      <p className={css.subsubpoint}>(ii)	any relative, friend or associate of Yours ordinarily residing with You or with whom You ordinarily reside; or </p>
      <p className={css.subsubpoint}>(iii)	Your employees.</p>
      <h2>11.	Termination of the Rental Agreement and Repossession </h2>
      <p>11.1.	Local Trailer Hire may terminate the Rental Agreement at any time if You commit a Major Breach of the Rental Terms and Conditions and Local Trailer Hire <b>must</b> notify You and Owner, in accordance with clause 13, immediately of the termination of the Rental Agreement. </p>
      <p>11.2.	The Owner may terminate the Rental Agreement at any time if You commit a Major Breach of the Rental Terms and Conditions and the Owner <b>must</b> notify You and Local Trailer Hire in accordance with clause 13, immediately of the termination of the Rental Agreement. </p>
      <p>11.3.	If the Rental Agreement is terminated pursuant to clauses 11.1 or 11.2:</p>
      <p className={css.subpoint}>(a)	Local Trailer Hire or the Owner may repossess the Trailer from You; and</p>
      <p className={css.subpoint}>(b)	You <b>must</b> pay the cancellation fees pursuant to clause 6.</p>
      <p>11.4.	You may terminate the Rental Agreement at any time if the Owner commits a Major Breach of the Rental Terms and Conditions and You <b>must</b> notify the Owner and Local Trailer Hire, in accordance with clause 13, immediately of the termination of the Rental Agreement.  </p>
      <p>11.5.	Notwithstanding clauses 11.1 or 11.2, the Owner may immediately repossess the Trailer without notice to You if:</p>
      <p className={css.subpoint}>(a)	You have illegally parked the Trailer for longer than 24 hours;</p>
      <p className={css.subpoint}>(b)	You have committed a reckless breach of road or traffic legislation, including but not limited to:</p>
      <p className={css.subsubpoint}>(i)	the accrual of demerit points that result or will result in immediate suspension of Your licence;</p>
      <p className={css.subsubpoint}>(ii)	the accrual by You of more than 6 demerit points in any 24 hour period; or</p>
      <p className={css.subsubpoint}>(iii)	the imposition of demerit points arising from three or more incidents or apprehensions by a regulatory authority in any 24 hour period;</p>
      <p className={css.subpoint}>(c)	the Trailer is apparently abandoned; </p>
      <p className={css.subpoint}>(d)	payments are in arrears or are not received within 24 hours of the due date; or</p>
      <p className={css.subpoint}>(e)	the Trailer has not been returned to the Owner at the time and date specified in the Rental Agreement,</p>
      <p>and You <b>must</b> pay the cancellation fees pursuant to clause 6.2.</p>
      <p>11.6.	If the Owner repossesses the Trailer directly You agree to indemnify the Owner for the costs incurred by the Owner. </p>
      <h2>12.	Local Trailer Hire Responsibility and Liability</h2>
      <p>12.1.	Local Trailer Hire enters into the Rental Agreement with You as agent of the Owner with respect to payments and receipts only. </p>
      <p>12.2.	Notwithstanding clause 12.1, Local Trailer Hire, as agent of the Owner, has the right to enforce these Rental Terms and Conditions against You in the event of any failure by You to comply with these Rental Terms and Conditions. </p>
      <p>12.3.	Local Trailer Hire makes no warranty to You that the Trailer is in a roadworthy condition.</p>
      <p>12.4.	Local Trailer Hire accepts no liability in respect of and shall not be responsible to You for:</p>
      <p className={css.subpoint}>(a)	the condition of the Trailer;</p>
      <p className={css.subpoint}>(b)	any information or content provided by the Owner;</p>
      <p className={css.subpoint}>(c)	death or personal injury;</p>
      <p className={css.subpoint}>(d)	any damage to any property, or damage to or loss of any Trailer;</p>
      <p className={css.subpoint}>(e)	any damage or loss of any belongings left in any Trailer;</p>
      <p className={css.subpoint}>(f)	any incidental expenses;</p>
      <p className={css.subpoint}>(g)	any charges or fines mentioned in clause 7;</p>
      <p className={css.subpoint}>(h)	any theft or breakdown of any Trailer; or</p>
      <p className={css.subsubpoint}>(i)	any failure of the Owner to honour these Rental Terms and Conditions;</p>
      <p>12.5.	Subject to the Australian Consumer Law Local Trailer Hire is not liable to You under or in connection with these Rental Terms and Conditions, for:</p>
      <p className={css.subpoint}>(a)	loss or damage incurred by You in connection with any claims made by a third party;</p>
      <p className={css.subpoint}>(b)	loss of profit, goodwill, business opportunity or anticipated saving suffered by You; or</p>
      <p className={css.subpoint}>(c)	any indirect or consequential loss or damage suffered by You.</p>
      <h2>13.	Notices</h2>
      <p>Any notice to be given to the Owner, Renter or Local Trailer Hire shall be deemed to be given upon it being posted to the address or sent by email to the email address of the Owner, Renter, or Local Trailer Hire (as the case may be) set out in the Rental Agreement. </p>
      <h2>14.	Privacy Act 1988</h2>
      <p>14.1.	As specified in clause 1.1 of these Rental Terms and Conditions the Privacy Policy forms part of Rental Contract.  </p>
      <p>14.2.	You consent to Local Trailer Hire, collecting, using and disclosing Your personal information in accordance with the Privacy Policy.  </p>
      <p>14.3.	When We collect Your personal information We will do so only for the purpose of providing rental services to You. If You choose not to provide this information to Us We may not be able to provide those rental services to You.</p>
      <p>14.4.	We take reasonable steps to make sure Your personal information is accurate, up to date and complete and that it is protected from misuse, loss or unauthorised access, modification or disclosure.</p>
      <h2>15.	Definitions</h2>
      <p>In these Rental Terms and Conditions:</p>
      <p><b><b>Collection Location</b></b> means the location specified in the Rental Agreement for collection of the Trailer, or any other location as agreed between the Owner and You. </p>
      <p><b><b>Damage</b></b> means:</p>
      <p className={css.subpoint}>(a)	any damage to the Trailer including its parts, components and accessories;</p>
      <p className={css.subpoint}>(b)	towing and salvage fees and assessing fees;</p>
      <p className={css.subpoint}>(c)	Loss of Use as a result of that damage or theft,</p>
      <p>and for the removal of doubt, any damage to the Trailer that makes it unroadworthy is not fair wear and tear.</p>
      <p><b>Damage Excess</b> means the amount of $1,000 payable if there is Damage, theft of the Trailer or third party loss and <b>must</b> be paid to obtain the benefit of the Trailer Guarantee.</p>
      <p><b>Local Trailer Hire</b> means The Local Hire Group Pty Ltd trading as Local Trailer Hire ABN 60 631 399 981. </p>
      <p><b>Loss of Use</b> means the loss suffered because the Trailer identified in the Rental Agreement is being repaired or replaced as a result of an accident or because it has been stolen and is being replaced.  The amount is calculated on a daily basis at the rate shown in the Rental Agreement.</p>
      <p><b>Major Breach</b> means a breach of any of clauses 3.2, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 3.10, 3.11, 3.12, 3.13, 3.14, 3.15, 3.16, or 3.17 that causes Damage, theft of the Trailer or third party loss. .</p>
      <p><b>Owner</b> means a Person who has agreed to hire out their Trailer to a Renter in accordance with the contract between Local Trailer Hire and the Owner (<b>Owner Contract</b>).</p>
      <p><b>Owner Rate</b> means the amount payable by the Owner as identified on the Rental Agreement. </p>
      <p>Person includes an individual, the estate of an individual, a body politic, a corporation, an association (incorporated or unincorporated) and a statutory or other authority.</p>
      <p><b>Parties</b> means Local Trailer Hire, the Owner and You. </p>
      <p><b>Privacy Policy</b> means the privacy policy of Local Trailer Hire published on the Website.</p>
      <p><b>Rental Agreement</b> means that part of the Rental Contract between Local Trailer Hire, as the Owner's agent, and You that specifies and identifies the key operational aspects of the hire, including the relevant Rental Period, the Collection Location, the Return Location, the Owner Rate, the Rental Fees payable and the Trailer hired.  </p>
      <p><b>Rental Fee</b> means the amount payable by You to Local Trailer Hire as set out in the Rental Agreement, and any other fees and charges, including late fees, incurred by You during the Rental Period.</p>
      <p><b>Rental Period</b> means the period of time that the Owner and Renter have agreed for use of the Trailer as set out in the Rental Agreement.</p>
      <p><b>Rental Terms and Conditions</b> means these terms and conditions which govern the operation of the Rental Contract. </p>
      <p><b>Renter</b> means a Person who has agreed to hire a Trailer from the Owner in accordance with the Rental Contract. </p>
      <p><b>Return Location</b> means the location specified in the Rental Agreement for return of the Trailer, or any other location as agreed between the Owner and You. </p>
      <p><b>Trailer</b> means the trailer hired by You pursuant to the Rental Contract and includes the Trailer's parts, components and accessories.  </p>
      <p><b>Trailer Guarantee</b> means the guarantee pursuant to clause 2.1 and subject to the conditions in clause 2.4.</p>
      <p><b>Website</b> means the www.LocalTrailerHire.com.au website and the content, features and services offered through it. </p>
      <p><b>You</b> means a Person who has agreed to hire a Trailer pursuant to the Rental Contract. </p>

    </div>
  );
};

RentalTerms.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

RentalTerms.propTypes = {
  rootClassName: string,
  className: string,
};

export default RentalTerms;
