import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionGotOrNeedATrailer.module.css';

import theGreatAustralianTrailerMusterIcon from './images/TheGreatAustralianMusterIcon.png';
import gotImage from '../../assets/for-owners-hero.png';

const SectionGotOrNeedATrailer = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const forOwnersLink = (
    <NamedLink name="ForOwnersPage" className={css.link}>
      <span className={css.forOwners}>
        <FormattedMessage id="SectionGotOrNeedATrailer.forOwners" />
      </span>
    </NamedLink>
  );

  const referLink = (
    <NamedLink name="MusteringPage" className={css.wideLink}>
      <span className={css.refer}>
        <FormattedMessage id="LandingPage.referLinkText" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
          <img src={gotImage} alt="a trailer not earning you money" className={css.image} />
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionGotOrNeedATrailer.gotTitle" />
          </h2>
          <p>
            <FormattedMessage id="SectionGotOrNeedATrailer.gotText" />
          </p>
          {forOwnersLink}
        </div>

        <div className={css.step}>
          <img
            src={theGreatAustralianTrailerMusterIcon}
            alt="badge for the Great Australian Trailer Muster"
            className={css.image}
          />
          <h2 className={css.stepTitle}>
            <FormattedMessage id="LandingPage.referTitle" />
          </h2>
          <p>Help them list and get yourself a $50 referral bonus!</p>
          {referLink}
        </div>
      </div>
    </div>
  );
};

SectionGotOrNeedATrailer.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionGotOrNeedATrailer.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionGotOrNeedATrailer;
