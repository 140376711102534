import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../types';

const label = (unitType, unitPrice, quantity, intl) => {
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  const unitPurchaseLabel = (
    <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
  );

  return unitPurchaseLabel;
};

export const lineItemBasePriceMaybe = (transaction, unitType, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;

  if (unitPurchase) {
    const unitPrice = () => {
      if (!unitPurchase) {
        return;
      } else {
        return !!intl
          ? formatMoney(intl, unitPurchase.unitPrice)
          : (unitPurchase.unitPrice.amount / 100).toFixed(2);
      }
    };

    const basePurchaseLabel = !!intl
      ? label(unitType, unitPrice(), quantity, intl)
      : `$${unitPrice()} x ${quantity} day(s)`;
    const formattedBasePrice = !!intl
      ? formatMoney(intl, unitPurchase.lineTotal)
      : `$${(unitPurchase.lineTotal.amount / 100).toFixed(2)}`;
    return { label: basePurchaseLabel, price: formattedBasePrice, quantity: quantity };
  }
};
