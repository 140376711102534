import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemUnitPriceMaybe } from '../../util/bookingBreakdown/lineItemUnitPriceMaybe';

import css from './BookingBreakdown.module.css';

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl } = props;

  const { label, price } = lineItemUnitPriceMaybe(transaction, unitType, intl);

  return price ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
