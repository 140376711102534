import React from 'react';
import PropTypes, { bool, func, instanceOf, node, oneOfType } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl, FormattedDate } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import config from '../../config';

import css from './CancelBookingModal.module.css';
import CancelRequestMessageForm from '../../forms/CancelRequestMessageForm/CancelRequestMessageForm';

const CancelBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onCancelBooking,
    providerName,
    listingTitle,
    cancelInProgress,
    isRefundableTransaction,
    bookingStart,
    bookingEnd,
    listingTimeZone,
    isBookingRequest,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'CancelBookingModal.later' });
  const bookingType = isBookingRequest ? 'booking request' : 'booking';

  const timeFormat = {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };
  const dateFormat = {
    month: 'short',
    day: 'numeric',
  };
  const timeZoneMaybe = listingTimeZone ? { timeZone: listingTimeZone } : null;
  const bookingStartDate = (
    <FormattedDate value={bookingStart} {...timeZoneMaybe} {...timeFormat} {...dateFormat} />
  );
  const bookingEndDate = (
    <FormattedDate value={bookingEnd} {...timeZoneMaybe} {...timeFormat} {...dateFormat} />
  );

  const cancelBookingMessageOptions = isRefundableTransaction
    ? config.custom.cancelBookingMessageOptions
    : config.custom.cancelNonRefundableBookingMessageOptions;

  const cancelMessageOptions = isBookingRequest
    ? config.custom.cancelRequestMessageOptions
    : cancelBookingMessageOptions;

  const cancelNonRefundableBooking =
    isRefundableTransaction || isBookingRequest ? null : (
      <p className={css.modalMessage}>
        There are no refunds for bookings cancelled within 24 hours of the start of the hire.
      </p>
    );

  const cancelButtonText = isRefundableTransaction
    ? 'CancelBookingModal.cancelBookingButton'
    : 'CancelBookingModal.cancelBookingButtonWithoutRefund';

  const cancelModalTitle = isRefundableTransaction ? (
    <FormattedMessage id="CancelBookingModal.title" />
  ) : (
    <FormattedMessage id="CancelBookingModal.titleWithoutRefund" />
  );

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>{cancelModalTitle}</p>
      <p className={css.modalMessage}>
        <FormattedMessage id="CancelBookingModal.description" values={{ bookingType }} />
      </p>
      <p className={css.modalMessage}>
        <span className={css.modalMessageTitle}>{listingTitle}</span>
        {bookingEnd && bookingStart ? (
          <span>
            <br />
            {bookingStartDate} – {bookingEndDate}
          </span>
        ) : null}
      </p>
      {cancelNonRefundableBooking}
      <CancelRequestMessageForm
        saveActionMsg={intl.formatMessage(
          {
            id: cancelButtonText,
          },
          { bookingType }
        )}
        onSubmit={onCancelBooking}
        intl={intl}
        providerName={providerName}
        cancelInProgress={cancelInProgress}
        cancelMessageOptions={cancelMessageOptions}
        isRefundableTransaction={isRefundableTransaction}
        formId="CancelBookingModal"
      />
    </Modal>
  );
};

const { string } = PropTypes;

CancelBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
  isRefundableTransaction: true,
};

CancelBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOpen: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  onCancelBooking: func,
  providerName: oneOfType([node, string]).isRequired,
  listingTitle: string,
  bookingStart: instanceOf(Date),
  bookingEnd: instanceOf(Date),
  isRefundableTransaction: bool,
  isBookingRequest: bool,
};

export default injectIntl(CancelBookingModal);
