import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHelpArticles.module.css';

const SectionHelpArticles = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <h2 className={css.helpTitle}>Top articles</h2>
      <div className={classes}>
        <NamedLink name="ExplainWeightsPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>Trailer weights: why do they matter?</h3>
        </NamedLink>
        <NamedLink name="ExplainElectricBrakesPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>Electric brake controllers</h3>
        </NamedLink>
        <NamedLink name="SafeHiringPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>Safe hiring guide</h3>
        </NamedLink>
      </div>
    </>
  );
};

SectionHelpArticles.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHelpArticles.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHelpArticles;
