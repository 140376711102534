// prettier-ignore
import locations from './locations.json';

const activeLocations = locations.filter(location => location.active);

const locationNameSortFn = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const dateSortFn = (a, b) => {
  if (a.dateAdded < b.dateAdded) {
    return 1;
  }
  if (a.dateAdded > b.dateAdded) {
    return -1;
  }
  return locationNameSortFn(a, b);
};

export const locationsForState = state => {
  return activeLocations.filter(location => location.state === state).sort(locationNameSortFn);
};

export const latestLocations = () => {
  return activeLocations.sort(dateSortFn).slice(0, 6);
};
