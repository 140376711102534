import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldGroupAddress,
  FieldGroupDriversLicence,
} from '../../components';
import config from '../../config';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [showReferrerFieldState, setShowReferrerFieldState] = useState(
    props.referrerName === 'crystal'
  );
  const [showPromoTermsState, setShowPromoTermsState] = useState(
    props.promoCode?.toUpperCase() === 'FUEL'
  );

  const howDidYouHearAboutUsOptions = config.custom.howDidYouHearAboutUsOptions;

  const unorderedHowDidYouHearAboutUsOptions = howDidYouHearAboutUsOptions
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .concat({ key: 'other', label: 'Other' });

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenFuelPromoTerms,
          promoCode,
          referrerName,
          values,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // contact phone number
        const phoneNumberLabel = intl.formatMessage({
          id: 'SignupForm.phoneNumberLabel',
        });
        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'SignupForm.phoneNumberPlaceholder',
        });
        const phoneNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberRequired',
        });
        const phoneNumberMinLengthMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberMinLength',
        });
        const phoneNumberPrefixMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberPrefix',
        });
        const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);
        const phoneNumberMinLength = validators.minLength(phoneNumberMinLengthMessage, 10);
        const phoneNumberPrefix = validators.phoneNumberPrefix(phoneNumberPrefixMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // howDidYouHearAboutUs
        const howDidYouHearAboutUsLabel = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsLabel',
        });
        const howDidYouHearAboutUsPlaceholder = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsPlaceholder',
        });
        const howDidYouHearAboutUsRequiredMessage = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsRequired',
        });
        const howDidYouHearAboutUsRequired = validators.required(
          howDidYouHearAboutUsRequiredMessage
        );

        // residentialAddress
        const residentialAddressString = values.residentialAddress?.selectedPlace?.address;
        const startsWithHouseNumber = address => {
          return !address || /^\d+/.test(address?.trim());
        };
        const addressContainsNumber =
          !!values?.building || startsWithHouseNumber(residentialAddressString);
        const addressConfirmed = values?.addressConfirm?.includes('addressConfirmed');

        const optionalText = intl.formatMessage({
          id: 'SignupForm.optionalText',
        });

        // referrerName
        const referrerNameLabel = intl.formatMessage(
          { id: 'SignupForm.referrerNameLabel' },
          { optionalText: optionalText }
        );
        const referrerNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.referrerNamePlaceholder',
        });

        // promoCode
        const promoCodeLabel = intl.formatMessage(
          { id: 'SignupForm.promoCodeLabel' },
          { optionalText: optionalText }
        );

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const handleHowDidYouHearAboutUsChange = newValue => {
          setShowReferrerFieldState(newValue === 'referred');
        };

        const handlePromoCodeChange = e => {
          setShowPromoTermsState(e.target.value.toUpperCase() === 'FUEL');
        };

        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        // termsAccept
        const termsAcceptLabel = (
          <span className={css.termsText}>
            <FormattedMessage id="SignupForm.termsAndConditionsAcceptText" values={{ termsLink }} />
          </span>
        );

        // TODO this message is not currently used because FieldCheckbox doesn't deal with validations
        const termsAcceptRequiredMessage = intl.formatMessage({
          id: 'SignupForm.termsAcceptRequired',
        });

        const termsAcceptRequired = validators.requiredCheckbox(termsAcceptRequiredMessage);

        const fuelPromoTermsLink = (
          <span className={css.termsLink} onClick={onOpenFuelPromoTerms} role="button" tabIndex="0">
            <FormattedMessage id="SignupForm.fuelPromoReadTermsText" />
          </span>
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled =
          invalid || submitInProgress || (!addressContainsNumber && !addressConfirmed);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              if (addressContainsNumber) {
                values.addressConfirm = null;
              }
              handleSubmit(e);
            }}
          >
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              <FieldPhoneNumberInput
                className={css.phoneNumber}
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                autoComplete="mobile"
                label={phoneNumberLabel}
                placeholder={phoneNumberPlaceholder}
                validate={validators.composeValidators(
                  phoneNumberRequired,
                  phoneNumberMinLength,
                  phoneNumberPrefix
                )}
              />
              <FieldGroupDriversLicence
                formId={formId}
                driversLicenceExpiryDate={values.driversLicenceExpiryDate}
                licenceYears={'future'}
                intl={intl}
                values={values}
              />
              <FieldGroupAddress
                formId={formId}
                fieldName="residentialAddress"
                addressValue={values.residentialAddress}
                building={values.building}
                addressConfirmed={addressConfirmed}
                intl={intl}
              />

              {!referrerName ? (
                <FieldSelect
                  className={css.howDidYouHearAboutUs}
                  id={formId ? `${formId}.howDidYouHearAboutUs` : 'howDidYouHearAboutUs'}
                  name="howDidYouHearAboutUs"
                  label={howDidYouHearAboutUsLabel}
                  validate={howDidYouHearAboutUsRequired}
                  onChange={handleHowDidYouHearAboutUsChange}
                >
                  <option disabled value="">
                    {howDidYouHearAboutUsPlaceholder}
                  </option>
                  {unorderedHowDidYouHearAboutUsOptions.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              ) : (
                <FieldTextInput
                  type="hidden"
                  id={formId ? `${formId}.howDidYouHearAboutUs` : 'howDidYouHearAboutUs'}
                  name="howDidYouHearAboutUs"
                  defaultValue="referred"
                />
              )}

              {showReferrerFieldState ? (
                <FieldTextInput
                  className={css.referrerName}
                  type={referrerName ? 'hidden' : 'text'}
                  id={formId ? `${formId}.referrerName` : 'referrerName'}
                  name="referrerName"
                  autoComplete="new-password"
                  label={referrerName ? null : referrerNameLabel}
                  defaultValue={referrerName ? referrerName : null}
                  placeholder={referrerNamePlaceholder}
                />
              ) : null}

              <FieldTextInput
                className={css.promoCode}
                type="text"
                id={formId ? `${formId}.promoCode` : 'promoCode'}
                name="promoCode"
                label={promoCodeLabel}
                defaultValue={promoCode}
                onBlur={handlePromoCodeChange}
              />

              {showPromoTermsState ? (
                <div className={css.promoTerms}>{fuelPromoTermsLink}</div>
              ) : null}

              <div className={css.termsAccept}>
                <FieldCheckbox
                  id={formId ? `${formId}.termsAccept` : 'termsAccept'}
                  name="termsAccept"
                  label={termsAcceptLabel}
                  validate={termsAcceptRequired}
                  value="termsAccept"
                />
              </div>
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}></p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
