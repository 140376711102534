import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { lineItemBasePriceMaybe } from '../../util/bookingBreakdown/lineItemBasePriceMaybe';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl } = props;

  const { label, price, quantity } = lineItemBasePriceMaybe(transaction, unitType, intl);

  return quantity && price ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
