import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './SectionPromoHero.module.css';

import image from '../../assets/for-owners-hero.png';

const SectionPromoHero = props => {
  const { rootClassName, className, referrerName } = props;

  const classes = classNames(rootClassName || css.root, className);

  const signupWithPromoCodeLink = (
    <a href="#form">
      <span className={css.signupLink}>
        <FormattedMessage id="SectionPromoHero.signup" />
      </span>
    </a>
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroMainTitleDiv}>
          <h1 className={css.heroMainTitle}>
            {referrerName ? (
              <FormattedMessage id="SectionPromoHero.referrerTitle" />
            ) : (
              <FormattedMessage id="SectionPromoHero.fuelTitle" />
            )}
          </h1>
          {signupWithPromoCodeLink}
        </div>
        <div className={css.imageDiv}>
          <img src={image} alt="trailer" className={css.image} />
        </div>
      </div>
    </div>
  );
};

SectionPromoHero.defaultProps = { rootClassName: null, className: null };

SectionPromoHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPromoHero;
