import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, FieldLeadTimeSelect, PrimaryButton } from '../../components';

import css from './EditListingBookingRequirementsForm.module.css';

const EditListingBookingRequirementsFormComponent = props => {
  const handleSubmit = values => {
    const leadTime = Number(values.leadTime);
    const updatedValues = { ...values, leadTime };
    props.onSubmit(updatedValues);
  };

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          form,
          formId,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          listingTitle,
        } = formRenderProps;

        const { leadTime } = values;
        const { updateListingError } = fetchErrors || {};
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form id={formId} className={classes} onSubmit={handleSubmit}>
            <h2 className={css.heading}>
              <FormattedMessage
                id="EditListingBookingRequirementsForm.title"
                values={{ listingTitle }}
              />
            </h2>
            <div className={css.section}>
              <p>Minimum advance notice: </p>
              <div className={css.dropdownSelect}>
                <FieldLeadTimeSelect id="leadTime" name="leadTime" initialValue={leadTime} />{' '}
              </div>
            </div>
            <div className={css.submitButton}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingBookingRequirementsForm.updateFailed" />
                </p>
              ) : null}
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="EditListingBookingRequirementsForm.updateBookingRequirements" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingBookingRequirementsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingBookingRequirementsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  disabled: bool,
  ready: bool,
  updated: bool,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingBookingRequirementsFormComponent);
