/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { View } from '@react-pdf/renderer';

import LineItemBasePriceMaybePdf from './LineItemBasePriceMaybePdf';
import LineItemSubTotalMaybePdf from './LineItemSubTotalMaybePdf';
import LineItemCustomerCommissionMaybePdf from './LineItemCustomerCommissionMaybePdf';
import LineItemCustomerCommissionTaxMaybePdf from './LineItemCustomerCommissionTaxMaybePdf';
import LineItemCustomerCommissionRefundMaybePdf from './LineItemCustomerCommissionRefundMaybePdf';
import LineItemCustomerCommissionTaxRefundMaybePdf from './LineItemCustomerCommissionTaxRefundMaybePdf';
import LineItemRefundMaybePdf from './LineItemRefundMaybePdf';
import LineItemTotalPricePdf from './LineItemTotalPricePdf';
import LineItemCustomerCommissionSubtotalMaybePdf from './LineItemCustomerCommissionSubtotalMaybePdf';
import LineItemManualPayoutMaybePdf from './LineItemManualPayoutMaybePdf';
import LineItemManualCustomerCommissionMaybePdf from './LineItemManualCustomerCommissionMaybePdf';
import LineItemManualCustomerCommissionTaxMaybePdf from './LineItemManualCustomerCommissionTaxMaybePdf';

const BookingBreakdownPdf = props => {
  const { userRole, unitType, transaction, timeZone, intl } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  return (
    <View>
      <LineItemBasePriceMaybePdf
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        timeZone={timeZone}
      />
      <LineItemRefundMaybePdf transaction={transaction} intl={intl} />
      <LineItemManualPayoutMaybePdf transaction={transaction} intl={intl} />
      <LineItemSubTotalMaybePdf
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <LineItemCustomerCommissionMaybePdf
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybePdf
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemManualCustomerCommissionMaybePdf
        transaction={transaction}
        intl={intl}
        isProvider={isProvider}
      />
      <LineItemCustomerCommissionTaxMaybePdf
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionTaxRefundMaybePdf
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemManualCustomerCommissionTaxMaybePdf
        transaction={transaction}
        intl={intl}
        isProvider={isProvider}
      />
      <LineItemCustomerCommissionSubtotalMaybePdf
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <LineItemTotalPricePdf transaction={transaction} isProvider={isProvider} intl={intl} />
    </View>
  );
};

BookingBreakdownPdf.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

BookingBreakdownPdf.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  timeZone: string,

  intl: intlShape,
};

export default BookingBreakdownPdf;
