import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionForOwnersSteps.module.css';

import meetupImage from './images/greeting.svg';
import trailerImage from './images/trailer.svg';
import moneyImage from './images/money.svg';
import confirmBookingImage from './images/online-booking.svg';

const SectionForOwnersSteps = props => {
  const { rootClassName, className, forBusiness } = props;

  const classes = classNames(rootClassName || css.root, className);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const addMyTrailerLink = (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="SectionForOwnersSteps.createListing" />
      </span>
    </NamedLink>
  );

  const subheading = forBusiness
    ? 'How to make Local Trailer Hire work for your business'
    : 'Make your trailer work for you!';

  classNames(css.step);
  return (
    <div className={classes}>
      <h2 className={css.subheading}>{subheading}</h2>
      <div className={css.stepsContainer}>
        <div className={css.stepFirst}>
          <img src={trailerImage} role="presentation" className={css.icon} />
          <div className={css.stepWords}>
            <h3 className={css.stepHeading}>1. Sign up</h3>
            <div>
              Join for free and create a listing for your trailer{forBusiness ? 's' : null}. You
              decide the pricing and availability hours for pick-up and return.
            </div>
          </div>
        </div>

        <div className={css.step}>
          <img src={confirmBookingImage} role="presentation" className={css.icon} />
          <div className={css.stepWords}>
            <h3 className={css.stepHeading}>2. Confirm booking</h3>
            <div>
              When you get a booking request, you'll be notified by text message and email. Click on
              the link and confirm or decline the request.
            </div>
          </div>
        </div>

        <div className={css.step}>
          <img src={meetupImage} role="presentation" className={css.icon} />
          <div className={css.stepWords}>
            <h3 className={css.stepHeading}>3. Meet the customer</h3>
            <div>
              Once you’ve confirmed the booking, simply meet the customer at the agreed time, check
              their licence details and{' '}
              {forBusiness ? 'follow your usual process' : 'take some photos of the trailer'}.
            </div>
          </div>
        </div>

        <div className={css.stepLast}>
          <img src={moneyImage} role="presentation" className={css.icon} />
          <div className={css.stepWords}>
            <h3 className={css.stepHeading}>4. Get paid</h3>
            <div>
              The booking fee will land in your bank account within a few days of pick-up.
              {forBusiness
                ? null
                : ' Over time, the dollars rack up. Last year, the average trailer returned $2,344.'}
            </div>
          </div>
        </div>
      </div>
      {forBusiness ? null : <div className={css.addMyTrailerLinkContainer}>{addMyTrailerLink}</div>}
    </div>
  );
};

SectionForOwnersSteps.defaultProps = { rootClassName: null, className: null };

const { string, bool } = PropTypes;

SectionForOwnersSteps.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  forBusiness: bool,
};

export default SectionForOwnersSteps;
