import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { types as sdkTypes } from '../sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION_TAX } from '../types';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

export const lineItemCustomerCommissionTaxMaybe = (transaction, isCustomer, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const customerCommissionTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX && !item.reversal
  );

  if (isCustomer && customerCommissionTaxLineItem) {
    const commissionTax = customerCommissionTaxLineItem.lineTotal;
    const customerCommissionTaxLabel = !!intl ? (
      <FormattedMessage id="BookingBreakdown.commissionTax" />
    ) : (
      'GST'
    );

    const formattedCustomerCommissionTax = !!intl
      ? formatMoney(intl, commissionTax)
      : `$${(commissionTax.amount / 100).toFixed(2)}`;
    if (!isValidCommission(customerCommissionTaxLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission tax  line item:', customerCommissionTaxLineItem);
      throw new Error('Commission tax should be present and the value should be zero or positive');
    } else {
      return { label: customerCommissionTaxLabel, price: formattedCustomerCommissionTax };
    }
  }
  return { label: 'GST', price: '$0.00' };
};
