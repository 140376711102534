import React, { Component } from 'react';
import PropTypes, { number, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import Slider from 'react-slick';
import { compose } from 'redux';
import { withViewport, lazyLoadWithDimensions } from '../../util/contextHelpers';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import boxImage from './images/box-trailer.jpeg';
import cageImage from './images/cage-trailer.jpeg';
import campingImage from './images/camping-trailer.jpeg';
import carImage from './images/car-trailer.jpeg';
import enclosedImage from './images/enclosed-trailer.jpeg';
import flatTopImage from './images/flat-top-trailer.jpeg';
import motorbikeImage from './images/motorbike-trailer.jpeg';
import specialtyImage from './images/specialty-trailer.jpeg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 768;

const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (type, image, searchQuery, imageClass) => {
  const typeText = <span className={css.locationName}>{type}</span>;
  const imageClasses = classNames(imageClass, css.locationImage);
  return (
    <NamedLink key={type} name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={type} className={imageClasses} />
        </div>
        <div className={css.linkText}>
          <FormattedMessage id="SectionLocations.listingsOfType" values={{ type: typeText }} />
        </div>
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className, viewport } = props;
  const { width } = viewport;
  const hasViewport = width > 0;
  const isMobileLayout = hasViewport && width < MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: 'progressive',
    swipeToSlide: true,
    accessibility: true,
    centerMode: true,
    centerPadding: '25px',
  };

  const trailerCategoryLinks = [
    locationLink('Cage', cageImage, trailerCategoryUrls.cage, css.melbourneImage),

    locationLink('Car', carImage, trailerCategoryUrls.car, css.mitchamImage),
    locationLink('Motorbike', motorbikeImage, trailerCategoryUrls.motorbike),
    locationLink('Enclosed', enclosedImage, trailerCategoryUrls.enclosed),
    locationLink('Box', boxImage, trailerCategoryUrls.box),
    locationLink('Camping', campingImage, trailerCategoryUrls.camping),
    // locationLink(
    //   'Boat',
    //   boatImage,
    //   trailerCategoryUrls.boat,
    // ),
    // locationLink(
    //   'Machinery',
    //   machineryImage,
    //   trailerCategoryUrls.machinery,
    // ),
    locationLink('Flat-top', flatTopImage, trailerCategoryUrls.flatTop),
    locationLink('Specialty', specialtyImage, trailerCategoryUrls.speciality),
  ];
  const slider = <Slider {...settings}>{trailerCategoryLinks}</Slider>;

  const allImageLinks = <div className={css.allLinksWrapper}>{trailerCategoryLinks}</div>;

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.content}>{isMobileLayout ? slider : allImageLinks}</div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default compose(withViewport)(SectionLocations);
