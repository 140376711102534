import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionFlatTopTrailers.module.css';

const SectionFlatTopTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.flatTopTrailersContent}>
        <h3 className={css.heading}>
          How to find the right flat-top trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired flat-top trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.flatTopTrailersContent}>
        <h3 className={css.heading}>Flat-top trailers FAQ</h3>
        <h4 className={css.subheading}>What is a flat-top trailer?</h4>
        <p>
          A flat-top trailer is a type of trailer that features a flat deck without any sides or
          roof. It provides an open and spacious platform for transporting various items. With no
          height restrictions or enclosed walls, it offers easy access from all sides, making
          loading and unloading hassle-free. Flat-top trailers come in different sizes and weight
          capacities to accommodate different hauling needs.
        </p>
      </div>
      <div className={css.flatTopTrailersContent}>
        <h4 className={css.subheading}>What are the specifications of a flat-top trailer?</h4>
        <p>
          Flat-top trailers come with different specifications depending on their size and design.
          The deck of a flat-top trailer is typically made of a durable material such as steel,
          ensuring a sturdy and reliable platform for carrying loads. Some flat-top trailers may
          have additional features like ramps for convenient loading and tie-down points to secure
          the cargo. The specific specifications of each flat-top trailer are listed on Local
          Trailer Hire, allowing you to choose one that suits your requirements.
        </p>
      </div>
      <div className={css.flatTopTrailersContent}>
        <h4 className={css.subheading}>What could I use a flat-top trailer for?</h4>
        <p>
          A flat-top trailer can be used for transporting construction materials, furniture,
          equipment, or any other goods that require an open and unrestricted space. If you're
          planning a DIY project and need to move bulky items like timber or flooring materials, a
          flat-top trailer is a practical choice. Likewise, if you're wanting to move large pieces
          of furniture, a flat-top trailer offers the space and flexibility you need. The flat deck
          allows you to customize the setup and secure your load with tie-downs and tarps. With the
          selection of flat-top trailers available for hire on Local Trailer Hire, you can find the
          right one for your specific hauling needs.
        </p>
      </div>
      <div className={css.flatTopTrailersContent}>
        <h4 className={css.subheading}>
          Why are the flat-top trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          Trailer owners set the hiring price for their own trailers listed on Local Trailer Hire.
          The price can be set based on factors such as the trailer's size, condition, and extra
          features like ramps. You can use the search filters on Local Trailer Hire to find the best
          flat-top trailer that fits your budget.
        </p>
      </div>
      <div className={css.flatTopTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('box')}</li>
          <li>{categoryLink('enclosed')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};

SectionFlatTopTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionFlatTopTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFlatTopTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFlatTopTrailersComponent;
