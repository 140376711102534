import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemRefundMaybe } from '../../util/bookingBreakdown/lineItemRefundMaybe';
import { styles } from '../Invoice/InvoiceStyles';

const styleSheet = styles;

const LineItemRefundMaybePdf = props => {
  const { transaction, intl } = props;
  const { label, price, showRefund } = lineItemRefundMaybe(transaction, intl);

  return price && showRefund ? (
    <View style={styleSheet.row}>
      <View style={styleSheet.tColumnLeftWide}>
        <Text>{label}</Text>
      </View>
      <View style={styleSheet.rightAligned}>
        <Text>{price}</Text>
      </View>
    </View>
  ) : null;
};

LineItemRefundMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape,
};

export default LineItemRefundMaybePdf;
