import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '..';
import { ensureCurrentUser } from '../../util/data';
import { LISTING_PAGE_PARAM_TYPE_DRAFT } from '../../util/urlHelpers';
import { createSlug } from '../../util/urlHelpers';

import css from './AlertBanner.module.css';

// Due to the layout structure, do not render the banner on the following pages
const disabledPages = ['SearchPage', 'EditListingPage'];

const AlertBanner = props => {
  const {
    rootClassName,
    className,
    isAuthenticated,
    currentUser,
    currentUserDraftListing,
    currentUserHasDraftListings,
    currentUserHasListings,
    currentPage,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);

  const showBanner =
    user.id &&
    isAuthenticated &&
    currentUserHasDraftListings &&
    !currentUserHasListings &&
    !disabledPages.includes(currentPage);

  const slug = createSlug(currentUserDraftListing?.attributes.title ?? 'draft');

  return showBanner ? (
    <div className={classes}>
      <p className={css.text}>
        <FormattedMessage id="AlertBanner.incompleteListingMessage" />
      </p>
      <div className={css.finishListingDraftButtonWrapper}>
        <NamedLink
          className={css.finishListingDraftLink}
          name="EditListingPage"
          params={{
            id: currentUserDraftListing.id.uuid,
            slug,
            type: LISTING_PAGE_PARAM_TYPE_DRAFT,
            tab: 'photos',
          }}
        >
          <FormattedMessage id="AlertBanner.goToIncompleteListing" />
        </NamedLink>
      </div>
    </div>
  ) : null;
};

AlertBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  currentUserDraftListing: null,
  currentUserHasDraftListings: false,
  currentUserHasListings: false,
};

const { bool, string } = PropTypes;

AlertBanner.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasDraftListings: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentPage: string,
  currentUserDraftListing: propTypes.ownListing,
};

export default AlertBanner;
