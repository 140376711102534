import moment from 'moment';
import { isInRange } from './dates';

export const YearType = {
  Calendar: 'calendar',
  Financial: 'financial',
};

const earningsInRange = (transactions, start, end) => {
  return transactions.reduce((acc, txn) => {
    if (
      txn.attributes.payoutTotal &&
      txn.booking.attributes.displayStart &&
      isInRange(
        moment(txn.booking.attributes.displayStart)
          .add(1, 'days')
          .toDate(),
        start,
        end,
        null,
        'Australia/Melbourne'
      )
    ) {
      const adjustment = txn.attributes.metadata.payoutAdjustment ?? 0;
      return acc + txn.attributes.payoutTotal.amount + adjustment * 100;
    } else {
      return acc;
    }
  }, 0);
};

const monthTo2Digits = month => month.toLocaleString(undefined, { minimumIntegerDigits: 2 });

const monthEarnings = (transactions, year, month) => {
  const start = moment(`${year}-${monthTo2Digits(month)}-01T00:00:00+10:00`).toDate();
  const end =
    month === 12
      ? moment(`${year + 1}-01-01T00:00:00+10:00`).toDate()
      : moment(`${year}-${monthTo2Digits(month + 1)}-01T00:00:00+10:00`).toDate();

  if (end < earliestListingMoment(transactions).toDate()) {
    return null;
  }

  const earnings = earningsInRange(transactions, start, end);
  if (start > moment().toDate() && earnings === 0) {
    return null;
  }

  return earnings;
};

const earningsByFyMonth = (transactions, year) => [
  ...[...Array(12).keys()].slice(6).map(m => monthEarnings(transactions, year - 1, m + 1)),
  ...[...Array(6).keys()].map(m => monthEarnings(transactions, year, m + 1)),
];

const earningsByCyMonth = (transactions, year) =>
  [...Array(12).keys()].map(m => monthEarnings(transactions, year, m + 1));

export const fyEarnings = (transactions, financialYear) => {
  const start = moment(`${financialYear - 1}-07-01T00:00:00+10:00`).toDate();
  const end = moment(`${financialYear}-07-01T00:00:00+10:00`).toDate();
  return earningsInRange(transactions, start, end);
};

export const cyEarnings = (transactions, calendarYear) => {
  const start = moment(`${calendarYear}-01-01T00:00:00+10:00`).toDate();
  const end = moment(`${calendarYear + 1}-01-01T00:00:00+10:00`).toDate();
  return earningsInRange(transactions, start, end);
};

const earliestListingMoment = transactions => {
  return transactions.reduce((acc, curr) => {
    const createdAt = moment(curr.listing.attributes.createdAt);
    return createdAt < acc ? createdAt : acc;
  }, moment());
};

export const getYearlyEarningsPerMonth = (transactions, years, yearType) => {
  const allEarningsByMonth = [];
  years.forEach(year => {
    const yearName = yearType === YearType.Calendar ? `${year}` : `FY ${year}`;
    const yearEarnings =
      yearType === YearType.Calendar
        ? cyEarnings(transactions, year)
        : fyEarnings(transactions, year);
    if (yearEarnings > 0) {
      allEarningsByMonth.push({
        name: yearName,
        data:
          yearType === YearType.Calendar
            ? earningsByCyMonth(transactions, year)
            : earningsByFyMonth(transactions, year),
      });
    }
  });

  return allEarningsByMonth;
};

export const maxMonthlyEarnings = (transactions, years) => {
  return Math.max(
    ...years
      .map(y => earningsByCyMonth(transactions, y))
      .reduce((acc, curr) => acc.concat(curr), [])
  );
};

export const uniqueListings = transactions => {
  const uniqueListingsMap = new Map();

  transactions.forEach(transaction => {
    const uuid = transaction.listing.uuid;

    if (!uniqueListingsMap.has(uuid)) {
      uniqueListingsMap.set(transaction.listing.id.uuid, transaction.listing.attributes.title);
    }
  });

  return Array.from(uniqueListingsMap, ([k, v]) => {
    return { key: k, label: v };
  });
};
