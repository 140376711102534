import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './OwnerTerms.module.css';

const OwnerTerms = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 25 May, 2020</p>
      <h2>1.	Governing Terms and Conditions</h2>
      <p>1.1.	This contract (Owner Contract) between You and Local Trailer Hire consists of:</p>
      <p className={css.subpoint}>(a)	these Owner Terms and Conditions (Terms and Conditions), </p>
      <p className={css.subpoint}>(b)	the Privacy Policy; and </p>
      <p className={css.subpoint}>(c)	the Website Terms of Service.</p>
      <p>1.2.	The Owner Contract creates binding and enforceable legal obligations between You and Local Trailer Hire and each listing and booking of Your Trailer is subject to these Terms and Conditions.</p>
      <p>1.3.	The Owner Contract is governed by the laws of Victoria and You agree that courts in that state have non-exclusive jurisdiction to determine any dispute that arises between You and Us.  You and the Renter agree to submit to the non-exclusive jurisdiction of the Courts of that State for all purposes of or in connection with the Owner Contract. </p>
      <p>1.4.	The Australian Consumer Law applies to these Terms and Conditions and it provides You with rights that are not excluded, restricted or modified by the Owner Contract and any provision in this contract is subject to the specific protections and guarantees in that and any corresponding Federal, State or Territory legislation. </p>
      <p>1.5.	Local Trailer Hire may amend these Terms and Conditions and the Rental Terms and Conditions upon reasonable notice to You and that change will take effect 7 days after the date on which that notification is given pursuant to clause 14. </p>
      <h2>2.	Appointment of Local Trailer Hire as Agent</h2>
      <p>2.1.	Subject to these Terms and Conditions, You appoint Local Trailer Hire, and Local Trailer Hire accepts its appointment, as Your exclusive third party agent to market and procure the sale of the Local Trailer Hire Rental Services.</p>
      <p>2.2.	The Owner Contract continues in force, unless terminated earlier in accordance with these Terms and Conditions.</p>
      <p>2.3.	Local Trailer Hire enters into a Rental Agreement with a Renter as Your agent with respect to payments and receipts only and Local Trailer Hire is not liable to any person, and You will indemnify Local Trailer Hire, for any costs or charges which Local Trailer Hire incurs in its performance of its obligations to You under the Owner Contract or to the Renter.</p>
      <p>2.4.	This Owner Contract does not imply that You and Local Trailer Hire intend constituting a partnership, joint venture or other form of association in which any party may be liable for the acts or omissions of another, and no party has authority to pledge the credit of another.</p>
      <h2>3.	Owners Obligations </h2>
      <p>3.1.	At the commencement of this Owner Contract You <b>must</b> submit a profile of the Trailer to Local Trailer Hire for its approval and acceptance, which is entirely at its discretion, and the profile <b>must</b> include high definition photographs showing all aspects of the Trailer as well as Trailer details include make and model and any special conditions.</p>
      <p>3.2.	You warrant that the Trailer is in existence and is Your property or that You have the legal right to offer the Trailer for hire by Renters under a Rental Agreement and that it is free from all security interests registered under the Personal Property Securities Act 2009 other than those disclosed to Local Trailer Hire at the commencement of or during the currency of the Owner Contract. </p>
      <p>3.3.	You also warrant that when listing a Trailer: </p>
      <p className={css.subpoint}>(a)	it is available for hire by the Renter at the time and date specified; </p>
      <p className={css.subpoint}>(b)	it is roadworthy; </p>
      <p className={css.subpoint}>(c)	its description and photographs on the Listing are true and accurate and that any flaws/defects are included in the Listing; </p>
      <p className={css.subpoint}>(d)	it is fit for the purpose listed; and</p>
      <p className={css.subpoint}>(e)	it has the correct fees included in the listing.</p>
      <p>3.4.	You <b>must</b> set out all relevant information in a listing, including:</p>
      <p className={css.subpoint}>(a)	the time, date and availability of the listed Trailer;</p>
      <p className={css.subpoint}>(b)	the designated Collection Location;</p>
      <p className={css.subpoint}>(c)	the fee for hire of the Trailer; and </p>
      <p className={css.subpoint}>(d)	any other terms and conditions applicable to the hire of the Trailer.</p>
      <p>3.5.	Upon receipt of a booking request from a Renter You <b>must</b> immediately confirm or decline the request.  </p>
      <p>3.6.	You <b>must</b> make the Trailer available for the Local Trailer Hire Rental Services for a minimum of 3 months in each calendar year.</p>
      <p>3.7.	You <b>must</b> give Local Trailer Hire no less than seven (7) days prior written notice pursuant to clause 14 of any proposed change:</p>
      <p className={css.subpoint}>(a)	in Your ownership of the Trailer;</p>
      <p className={css.subpoint}>(b)	other change in Your details (including changes in Your name, address, or contact phone or email address). </p>
      <p>3.8.	Throughout the currency of the Owner Contract You <b>must</b> maintain and pay:</p>
      <p className={css.subpoint}>(a)	the Trailer's registration; and</p>
      <p className={css.subpoint}>(b)	for the Trailer to be roadworthy; and </p>
      <p className={css.subpoint}>(c)	at intervals not exceeding twelve (12) months to be serviced in accordance with the manufacturer's recommendations.</p>
      <p>3.9.	If You fail to comply with Your obligations under clause 3.8:</p>
      <p className={css.subpoint}>(a)	You <b>must</b> pay, and You indemnify the Renter, for any fines or monetary penalties incurred by the Renter; and </p>
      <p className={css.subpoint}>(b)	You acknowledge that the Renter will not be obligated to pay the Local Trailer Hire Rental Fee for any days that the Trailer is not available for rental.</p>
      <h2>4.	Local Trailer Hire Obligations</h2>
      <p>4.1.	Local Trailer Hire will during the term of this Owner Contract:</p>
      <p className={css.subpoint}>(a)	promote the Local Trailer Hire Services on its website and through advertising;</p>
      <p className={css.subpoint}>(b)	develop opportunities through its customer base; and</p>
      <p className={css.subpoint}>(c)	administer, as Your agent, the Local Trailer Hire Services, including:</p>
      <p className={css.subsubpoint}>(i)	entering into Rental Agreement as agent for, in Your name and on Your behalf;</p>
      <p className={css.subsubpoint}>(ii)	taking action to enforce a valid Rental Contract;</p>
      <p className={css.subsubpoint}>(iii)	holding the Owner Rate portion of the Total Rental Fee on Your behalf; and</p>
      <p className={css.subsubpoint}>(iv)	paying You the Owner Rate in the manner as agreed from time to time. . </p>
      <p>4.2.	Local Trailer Hire is not liable to You for:</p>
      <p className={css.subpoint}>(a)	the condition of the Trailer;</p>
      <p className={css.subpoint}>(b)	any information or content provided by the Renter or You;</p>
      <p className={css.subpoint}>(c)	death or personal injury to any person;</p>
      <p className={css.subpoint}>(d)	any damage to any property, or damage to or loss of any Trailer;</p>
      <p className={css.subpoint}>(e)	any damage or loss of any property left in any Trailer;</p>
      <p className={css.subpoint}>(f)	any incidental expenses;</p>
      <p className={css.subpoint}>(g)	any charges or fines;</p>
      <p className={css.subpoint}>(h)	any theft or breakdown of any Trailer;</p>
      <p className={css.subsubpoint}>(i)	any failure of the Renter to honour the Rental Terms and Conditions; or </p>
      <p>(j)	any failure of You to honour these Terms and Conditions,</p>
      <p>unless it is the result of Local Trailer Hire's negligence.</p>
      <p>4.3.	Subject to the Australian Consumer Law, Local Trailer Hire is not liable to You under or in connection with these Terms and Conditions whether for negligence, breach of contract, misrepresentation or otherwise, for:</p>
      <p className={css.subpoint}>(a)	loss or damage incurred by You in connection with any claims made by the Renter or a third party; or</p>
      <p className={css.subpoint}>(b)	any indirect or consequential loss or damage suffered by You. </p>
      <p>4.4.	Local Trailer Hire will use its best endeavours to verify the identity of each Renter, their suitability to be a Local Trailer Hire Renter and the ability of that Renter to pay rental charges associated with the Trailer rental.</p>
      <h2>5.	Handover Procedures</h2>
      <p>5.1.	Prior to the handover of the Trailer to the Renter at the Collection Location, You <b>must</b> ensure that:</p>
      <p className={css.subpoint}>(a)	the Trailer’s registration is current and will remain valid for the duration of the Rental Period;</p>
      <p className={css.subpoint}>(b)	the Trailer is in a roadworthy, serviceable and in a safe condition;</p>
      <p className={css.subpoint}>(c)	the Trailer is clean; and</p>
      <p className={css.subpoint}>(d)	all personal items are removed from the Trailer.</p>
      <p>5.2.	Upon acceptance and confirmation of the booking request You <b>must</b> deliver the Trailer to the Collection Location at the time and date agreed between You and the Renter.</p>
      <p>5.3.	Before proceeding with the hire, the Renter may inspect the Trailer and should it not meet the advertised specification is under no obligation to complete the hire.</p>
      <p>5.4.	At the handover of the Trailer to the Renter:</p>
      <p className={css.subpoint}>(a)	You <b>must</b> inspect the Renter’s Driver’s Licence and ensure that the details match those contained in the Rental Agreement and the photograph is an accurate likeliness of the person accepting Handover of the Trailer. If the details do not match, You <b>MUST NOT</b> handover the Trailer;</p>
      <p className={css.subpoint}>(b)	You <b>must</b> demonstrate to the Renter:</p>
      <p className={css.subsubpoint}>(i)	that the Trailer is in good working order; and </p>
      <p className={css.subsubpoint}>(ii)	how to operate the Trailer safely;</p>
      <p className={css.subpoint}>(c)	Local Trailer Hire recommends You take high definition digital photographs of the Trailer, using flash if the handover is not in daylight, showing its condition including:</p>
      <p className={css.subsubpoint}>(i)	the front and rear;</p>
      <p className={css.subsubpoint}>(ii)	each of the wheels; and</p>
      <p className={css.subsubpoint}>(iii)	the sides; and</p>
      <p className={css.subpoint}>(d)	<b>Special Notice:</b> <br /> to be eligible for Our Trailer Guarantee in clause 10.2 You and the Renter <b>must</b>:</p>
      <p className={css.subsubpoint}>(i)	at handover to the Renter take the photos referred to in clause 5.4(c) and </p>
      <p className={css.subsubpoint}>(ii)	on the return of the Trailer by the Renter take photos of the same areas they were taken at handover.  </p>
      <p>5.5.	If at the time of hand over to the Renter photos of the Trailer referred to in clause 5.4(c) are not taken You may forfeit the right to claim for any Damage allegedly caused by the Renter during the Rental Period and You will have no entitlement to the benefit of the Trailer Guarantee.</p>
      <p>5.6.	Any personal items left in the Trailer after handover to the Renter are left entirely at Your risk and Local Trailer Hire has no liability for any loss or damage to them.</p>
      <h2>6.	Conditions of Trailer Use by the Renter</h2>
      <p>6.1.	The Trailer <b>must</b> only be used by a Renter who has accepted the Local Trailer Hire Terms and Conditions of Rental.</p>
      <p>6.2.	The Trailer is rented by You to the Renter subject to the Renter's acceptance and compliance with the Rental Terms and Conditions. </p>
      <h2>7.	Financial obligations</h2>
      <p>7.1.	The Renter <b>must</b> pay Local Trailer Hire the Rental Fee shown on the Rental Agreement which includes:</p>
      <p className={css.subpoint}>(a)	the Owner Rate; and</p>
      <p className={css.subpoint}>(b)	Local Trailer Hire Fee.</p>
      <p>7.2.	The Owner Rate will be paid to You by electronic funds transfer at 10.00 am on the business day that next occurs after the day on which the Rental Period commenced.  These funds can take from 3 to 5 business days to show in Your account.</p>
      <p>7.3.	Local Trailer Hire is not liable to You for payment of the Owner Rate unless the Rental Fee has been paid by the Renter to Local Trailer Hire, and Local Trailer Hire acts solely in the capacity of agent for You for the receipt of the Rental Fee and payment to You of the Owner Rate. </p>
      <h2>8.	Cancellation </h2>
      <p>8.1.	Rental Agreements may only be cancelled by You in accordance with this clause 8. </p>
      <p>8.2.	If a booking is cancelled by a Renter:</p>
      <p className={css.subpoint}>(a)	24 hours or more prior to the Start of the Rental all prepaid Rental Charges will be refunded to the Renter; or  </p>
      <p className={css.subpoint}>(b)	within 24 hours prior to the Start of the Rental or the Renter fails to notify Us of their intended cancellation prior to the Start of the Rental and fails to pick up the Trailer, they will be charged the Rental Charges for the Rental Period as booked unless We are able to rent the Trailer to another renter for an equivalent term and rate.</p>
      <p>8.3.	A Renter's cancellation is not effective until acknowledged and confirmed by Us.</p>
      <p>8.4.	You may cancel a Rental Agreement before Commencement of Rental subject to a cancellation fee of $200.  </p>
      <p>8.5.	If You cancel a Rental Agreement after the Commencement of Rental and the Renter is not in arrears, You agree that, in addition to You being bound by the cancellation terms in clause 8.3, the Renter may keep the Trailer until such time as Local Trailer Hire has found a like for like replacement Trailer.</p>
      <h2>9.	Trailer Condition and Return</h2>
      <p>9.1.	The Renter <b>must</b> return the Trailer to You at the Return Location on or before the time and date agreed between You and the Renter.</p>
      <p>9.2.	The Renter remains responsible for the Trailer until it is returned to You in accordance with clause 9.1 and these Terms and Conditions continue to apply, regardless of whether it is returned during, at the end of, or after the expiry of the relevant Rental Period. </p>
      <p>9.3.	Subject to clause 8, if the Renter returns the Trailer to You before the end of the Rental Period the Renter <b>must</b> pay Local Trailer Hire the amounts in clause 7.1 for the duration of the Rental Period unless otherwise agreed in writing by You and Local Trailer Hire. </p>
      <p>9.4.	At the time of hand over at the Return Location:</p>
      <p className={css.subpoint}>(a)	You and the Renter <b>must</b> inspect the Trailer and You <b>must</b> check that:</p>
      <p className={css.subsubpoint}>(i)	the Trailer is returned to You in the same condition as it was at the beginning of the Rental Period, save for any reasonable wear and tear; and</p>
      <p className={css.subsubpoint}>(ii)	the Trailer has been cleaned prior to hand over to You; </p>
      <p className={css.subpoint}>(b)	We strongly recommend You take photos of the Trailer as close as practicable to those areas photographed at the handover to the Renter at the Commencement of the Rental Period and in the manner required by clause 5.4(c); and</p>
      <p className={css.subpoint}>(c)	<b>Special Notice:</b> <br />to be eligible for Our Trailer Guarantee in clause 10.2 You and the Renter <b>must</b>:</p>
      <p className={css.subsubpoint}>(i)	at handover to the Renter have taken the photos referred to in clause 5.4(c) and </p>
      <p className={css.subsubpoint}>(ii)	on the return of the Trailer by the Renter take photos of the same areas they were taken at handover.  </p>
      <p>9.5.	If at the time of handover by the Renter photos are not taken of those areas of the Trailer photographed at the handover to the Renter at the Commencement of the Rental Period You may forfeit the right to claim for any Damage allegedly caused by the Renter during the Rental Period and You will have no entitlement to the benefit of the Trailer Guarantee.</p>
      <p>9.6.	If the Trailer is not returned at the time and date specified in the Rental Agreement or as agreed between You and the Renter the Trailer may immediately be reported as stolen. </p>
      <h2>10.	Insurance Cover and the Trailer Guarantee</h2>
      <p>10.1.	<b>Renter's responsibility to insure</b></p>
      <p>There is no insurance or damage cover provided by the Owner Contract and it is the Renter's responsibility:</p>
      <p className={css.subpoint}>(a)	to have a policy of compulsory third party insurance that provides cover for personal injury; </p>
      <p className={css.subpoint}>(b)	to have insurance cover that provides indemnity for third party loss; and </p>
      <p className={css.subpoint}>(c)	to take reasonable steps to have insurance cover for Damage to the Trailer,</p>
      <p>arising from the use of the Trailer during the Rental Period.</p>
      <p>10.2.	<b>Trailer Guarantee</b></p>
      <p>As an added benefit and to give You confidence in Our services Local Trailer Hire will pay up to $2,000 to repair, replace or contribute to the replacement of Your Trailer if it is damaged, stolen or written off whilst on a hire booked through www.localtrailerhire.com.au</p>
      <p>10.3.	<b>Trailer Guarantee Conditions</b></p>
      <p>To be eligible for the Trailer Guarantee:</p>
      <p className={css.subpoint}>(a)	the Trailer <b>must</b> be roadworthy, maintained and serviced no less than annually as required by clauses 3.8(b) and 3.8(c); and</p>
      <p className={css.subpoint}>(b)	You and the Renter <b>must</b> take the photos referred to in:</p>
      <p className={css.subsubpoint}>(i)	clause 5.4(c) at handover; and </p>
      <p className={css.subsubpoint}>(ii)	clause 9.4(b) when the Trailer is returned.</p>
      <h2>11.	Damage claims</h2>
      <p>If there is Damage to the Trailer or it is stolen or there is third party loss:</p>
      <p className={css.subpoint}>(a)	Local Trailer Hire will arrange for a quotation for the repairs to the Trailer and where appropriate an assessment of it.</p>
      <p className={css.subpoint}>(b)	You <b>must</b>:</p>
      <p className={css.subsubpoint}>(i)	make the Trailer available for inspection or assessment; </p>
      <p className={css.subsubpoint}>(ii)	promptly forward any communication or documents You receive concerning the accident or theft, including from any other party, the police or any Court; and</p>
      <p className={css.subsubpoint}>(iii)	assist Local Trailer Hire in any negotiation, defence or settlement of the claim, including attending Court; and</p>
      <p className={css.subsubpoint}>(iv)	allow Local Trailer Hire to bring, defend or settle legal proceedings and You agree that Local Trailer Hire shall at all times have sole conduct of any legal proceedings. </p>
      <h2>12.	Material Breach of these Terms and Conditions </h2>
      <p>12.1.	If there is Damage to the Trailer or it is stolen and You have committed a Material Breach of these Terms and Conditions, the Trailer Guarantee will not apply. </p>
      <p>12.2.	For the purposes of these Terms and Conditions, a Material Breach is a breach of any of clauses 3.2, 3.8, 5.1(a) or 5.1(b).</p>
      <p>12.3.	If the Damage or third party loss occurred whilst the Trailer was being used by a Renter and the breach or conduct occurred without Your knowledge or consent, clause 12.1 does not apply.</p>
      <h2>13.	Termination of the Rental Agreement & Repossession</h2>
      <p>13.1.	Local Trailer Hire may terminate the Rental Agreement without penalty at any time if You commit a Material Breach of these Terms and Conditions and Local Trailer Hire <b>must</b> notify You and the Renter, in accordance with clause 14, immediately of the termination of the Rental Agreement.  </p>
      <p>13.2.	If, within 14 days of notification pursuant to clause 13.1, You fail to remedy the Material Breach, Local Trailer Hire may terminate the Owner Contract by notice to You pursuant to clause 14. </p>
      <p>13.3.	Local Trailer Hire may terminate the Rental Agreement at any time if the Renter commits a Major Breach of the Rental Terms and Conditions and Local Trailer Hire <b>must</b> notify the Renter and Owner, in accordance with clause 14, immediately of the termination of the Rental Agreement. </p>
      <p>13.4.	You may terminate the Rental Agreement at any time if the Renter commits a Major Breach of the Rental Terms and Conditions and You <b>must</b> notify the Renter and Local Trailer Hire in accordance with clause 14, immediately of the termination of the Rental Agreement. </p>
      <p>13.5.	Notwithstanding clause 13.4, You are entitled to immediately repossess the Trailer without notice to the Renter if:</p>
      <p className={css.subpoint}>(a)	the Renter has illegally parked the Trailer for longer than 24 hours;</p>
      <p className={css.subpoint}>(b)	the Renter has committed a reckless breach of road or traffic legislation;</p>
      <p className={css.subpoint}>(c)	the Trailer is apparently abandoned; </p>
      <p className={css.subpoint}>(d)	payments are in arrears or are not received within 7 days of the due date; or</p>
      <p className={css.subpoint}>(e)	the Trailer has not been returned to You at the time and date specified in the Rental Agreement.</p>
      <p>13.6.	If Local Trailer Hire notifies You that the Trailer may be repossessed, You may:</p>
      <p className={css.subpoint}>(a)	repossess the Trailer; or</p>
      <p className={css.subpoint}>(b)	request in writing that Local Trailer Hire as Your agent repossess the Trailer on Your behalf. </p>
      <p>13.7.	If Local Trailer Hire receives a request in accordance with clause 13.6(b), Local Trailer Hire is not obliged in any way to agree to Your request and Local Trailer Hire will not repossess the Trailer unless Local Trailer Hire has agreed to do so by written notice to You in accordance with clause 14. </p>
      <p>13.8.	If Local Trailer Hire agrees to repossess the Trailer:</p>
      <p className={css.subpoint}>(a)	Local Trailer Hire does so as Your agent and You <b>must</b> pay the costs incurred by Local Trailer Hire in connection with the repossession;</p>
      <p className={css.subpoint}>(b)	Local Trailer Hire will charge a processing fee of $100 to You, which You <b>must</b> pay; and</p>
      <p className={css.subpoint}>(c)	You acknowledge that the Renter agrees to indemnify You for any costs incurred by You, including but not limited to the costs at (a) and (b) above in connection with repossessing the Trailer.</p>
      <h2>14.	Notices</h2>
      <p>Any notice to be given to You, Renter or Local Trailer Hire shall be deemed to be given upon it being posted to the address or sent by email to the email address of You, Renter, or Local Trailer Hire (as the case may be) set out in the Rental Agreement. </p>
      <h2>15.	Intellectual Property</h2>
      <p>15.1.	Each party acknowledges and agrees that this Owner Contract does not transfer to either party any Intellectual Property Rights.</p>
      <p>15.2.	Each party acknowledges that it will have no rights and will not acquire any rights in respect of any trademarks, brands or trade names used by any other party or of the goodwill associated with them and that all such rights and goodwill are, and will remain, with the relevant owner.</p>
      <p>15.3.	Each party will, at the expense of the requesting party, take all such steps as a requesting party may reasonably require to assist that party in maintaining the validity and enforceability of its Intellectual Property Rights.</p>
      <h2>16.	Indemnity</h2>
      <p>16.1.	Each party indemnifies and agrees to keep indemnified the other party against any loss, claim, damage, liability, action or legal proceedings and all costs, charges and expenses (including legal fees and disbursements on a full indemnity basis) in connection with the enforcement of this Owner Contract or arising either directly or indirectly as a result of a breach by the indemnifying party of its obligations or of any warranties or representations given under this Owner Contract, including any action brought by third parties against the other party as a result, either directly or indirectly, of such breach.</p>
      <p>16.2.	Other than in respect of a breach of clause 15 or clause 17, neither party will be liable for any indirect loss or consequential loss, loss of profits, loss of revenue, loss of goodwill, exemplary damage or punitive damages arising in any way out of this Owner Contract however caused.</p>
      <h2>17.	Confidential Information</h2>
      <p>17.1.	Each party:</p>
      <p className={css.subpoint}>(a)	except as permitted under this clause 17, <b>must</b> keep confidential all Confidential Information of the other party;</p>
      <p className={css.subpoint}>(b)	may use the Confidential Information of the other party solely for the purposes of this Owner Contract; and</p>
      <p className={css.subpoint}>(c)	may disclose Confidential Information of the other party only:</p>
      <p className={css.subsubpoint}>(i)	with the prior written approval of the other party;</p>
      <p className={css.subsubpoint}>(ii)	to officers, employees and consultants or advisers of the party or its Related Bodies Corporate (including but not limited to the party’s insurers, insurance brokers and bankers) who:</p>
      <p className={css.subsubsubpoint}>(A)	are aware and agree that the Confidential Information <b>must</b> be kept confidential; and</p>
      <p className={css.subsubsubpoint}>(B)	either have a need to know (and only to the extent that each has a need to know); or</p>
      <p className={css.subsubpoint}>(iii)	as required to be disclosed by law or any order of any court, tribunal, authority or regulatory body.</p>
      <p>17.2.	Each party <b>must</b> notify the other party immediately once it becomes aware of any breach of confidentiality and <b>must</b> take all reasonable steps necessary to prevent further unauthorised use or disclosure of the Confidential Information.</p>
      <h2>18.	Dispute Resolution</h2>
      <p>18.1.	A party <b>must not</b> commence any court proceedings (other than proceedings for urgent interlocutory relief) in respect of a Dispute until it has complied with this clause 18.</p>
      <p>18.2.	A party claiming that a Dispute has arisen <b>must</b> notify the other party in writing giving details of the Dispute.</p>
      <p>18.3.	Within the Initial Period each party <b>must</b>:</p>
      <p className={css.subpoint}>(a)	nominate in writing a representative of that party authorised to settle the Dispute on its behalf; and</p>
      <p className={css.subpoint}>(b)	use its best endeavours to resolve the Dispute.</p>
      <p>18.4.	If the parties are unable to resolve the Dispute within the Initial Period they <b>must</b>, within an additional 10 business days either:</p>
      <p className={css.subpoint}>(a)	appoint a mediator and agree the terms upon which the mediator is to mediate the Dispute; or</p>
      <p className={css.subpoint}>(b)	if the parties are unable to agree on a mediator or the terms of the mediation, refer the Dispute for mediation to a mediator nominated by the then current President of the Law Institure of Victoria upon the terms of the mediation agreement then approved by the Law Institute of Victoria,</p>
      <p>and the parties <b>must</b> thereafter mediate the Dispute.</p>
      <p>18.5.	Each party <b>must</b> bear its own costs of resolving the Dispute under this clause and, unless the parties otherwise agree, the parties <b>must</b> bear equally the costs of any mediator engaged for that purpose.</p>
      <h2>19.	Privacy Policy</h2>
      <p>19.1.	We are committed to respecting privacy and will not collect, use or disclose Your personal information where doing so would be contrary to law. </p>
      <p>19.2.	We take reasonable steps to make sure Your personal information is accurate, up to date and complete and that it is protected from misuse, loss or unauthorised access, modification or disclosure.</p>
      <h2>20.	Definitions and interpretation</h2>
      <p>20.1.	Definitions </p>
      <p>In these Owner Terms and Conditions:</p>
      <p><b>Account</b> means the account You have created to access the services and features on the Website, including creating a user profile, making a listing, contacting a Renter and making or confirming a booking.</p>
      <p><b>Collection Location</b> means the location specified in the Rental Agreement for collection of the Trailer, or any other location as agreed between You and the Renter. </p>
      <p><b>Damage</b> means: </p>
      <p className={css.subpoint}>(a)	any damage to the Trailer including its parts, components and accessories; </p>
      <p className={css.subpoint}>(b)	towing and salvage fees and assessing fees; and</p>
      <p className={css.subpoint}>(c)	Loss of Use as a result of that damage or theft.</p>
      <p><b>Handover Inspection Report</b> means the document entitled Handover Inspection Report’.</p>
      <p><b>Local Trailer Hire</b> means The Local Hire Group Pty Ltd trading as Local Trailer Hire ABN 60 631 399 981. </p>
      <p><b>Local Trailer Hire Services</b> means services relating to the rental of the Trailer, including managing Rental Agreements, co-ordination of the drop off of the Trailer at the Destination at the commencement and conclusion of each Rental Period, collection of rental fees from the Renter, payment of the Owner Fee and arranging damage cover and insurance over the Trailer.</p>
      <p><b>Loss of Use</b> means the loss suffered because the Trailer identified in the Rental Agreement is being repaired or replaced as a result of an accident or because it has been stolen and is being replaced.  The amount is calculated on a daily basis at the rate shown in the Rental Agreement. </p>
      <p><b>Major Breach</b> means a breach of any of clauses 3.2, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 3.10, 3.11, 3.12, 3.13, 3.14, 3.15, 3.16 or 3.17 of the Rental Terms and Conditions that causes Damage, theft of the Trailer or third party loss.</p>
      <p><b>Material Breach</b> means a breach of any of the clauses listed in clause 12.2 of these Terms and Conditions.</p>
      <p><b>Owner Rate</b> means the amount payable to You as identified on the Rental Agreement. </p>
      <p><b>Parties</b> means Local Trailer Hire, You and the Renter. </p>
      <p><b>Person</b> includes an individual, the estate of an individual, a body politic, a corporation, an association (incorporated or unincorporated) and a statutory or other authority. </p>
      <p><b>Privacy Policy</b> means the privacy policy of Local Trailer Hire published on the Website.</p>
      <p><b>Rental Agreement</b> means that part of the Rental Contract between Local Trailer Hire, as Your agent, and a Renter for the hire of Your Trailer that specifies and identifies the key operational aspects of the hire, including the relevant Rental Period, the Collection Location, the Return Location, the Rental Fees payable and the Trailer hired.   </p>
      <p><b>Rental Contract</b> means the contract between Local Trailer Hire, as Your agent, and a Renter for the hire of Your Trailer and comprises the Rental Agreement, the Rental Terms and Conditions, the Website Terms and Conditions and the Privacy Policy.</p>
      <p><b>Rental Fee</b> means the amount payable by the Renter to Local Trailer Hire as set out in the Rental Agreement.</p>
      <p><b>Rental Period</b> means the period of time that You and Renter have agreed for use of the Trailer as set out in the Rental Agreement. </p>
      <p><b>Rental Terms and Conditions</b> means the terms and conditions which govern the operation of the Rental Contract for the hire of a Trailer.</p>
      <p><b>Renter</b> means a Person who has entered into a Rental Contract for the hire of a Trailer.  The hire is subject to the Rental Terms and Conditions.</p>
      <p><b>Return Location</b> means the location specified in the Rental Agreement for return of the Trailer, or any other location as agreed between You and the Renter. </p>
      <p><b>Terms and Conditions</b> means these terms and conditions which govern the operation of the Owner Contract. </p>
      <p><b>Trailer</b> means trailers made available for hire by You to a Renter pursuant to a Rental Contract and includes the Trailer's parts, components and accessories.  </p>
      <p><b>Trailer Guarantee</b> means the guarantee referred to in clause 10.2 and is subject to the conditions in clause 10.3.</p>
      <p><b>Website</b> means the www.LocalTrailerHire.com.au website and the content, features and services offered through it. </p>
      <p><b>You</b> means a Person who has agreed to hire out their Trailer to a Renter in accordance with the Rental Contract, subject to these Terms and Conditions. </p>
      <p>20.2.	Interpretation</p>
      <p>In these Owner Terms and Conditions, unless the context otherwise requires:</p>
      <p className={css.subpoint}>(a)	a reference to:</p>
      <p className={css.subsubpoint}>(i)	a recital, clause, schedule or annexure is a reference to a clause of or recital, schedule or annexure to these Terms and Conditions and references to these Terms and Conditions include any recital, schedule or annexure;</p>
      <p className={css.subsubpoint}>(ii)	a person or entity includes an individual, a firm, a body corporate, a trust, an unincorporated association or an authority;</p>
      <p className={css.subsubpoint}>(iii)	a person includes their legal personal representatives (including executors), administrators, successors, substitutes (including by way of novation) and permitted assigns;</p>
      <p className={css.subsubpoint}>(iv)	money (including ‘$’, ‘AUD’ or ‘dollars’) is to Australian currency; </p>
      <p className={css.subpoint}>(b)	headings are for convenience only and do not form part of these Terms and Conditions or affect their interpretation;</p>
      <p className={css.subpoint}>(c)	if a period of time is specified and dates from a given day or the day of an act or event, it is to be calculated exclusive of that day;</p>
      <p className={css.subpoint}>(d)	where there are two or more persons in a party each are bound jointly and severally; and</p>
      <p className={css.subpoint}>(e)	a provision of these Terms and Conditions <b>must not</b> be construed to the disadvantage of a party merely because that party was responsible for the preparation of these  Terms and Conditions or the inclusion of the provision in these Terms and Conditions .</p>


    </div>
  );
};

OwnerTerms.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

OwnerTerms.propTypes = {
  rootClassName: string,
  className: string,
};

export default OwnerTerms;
