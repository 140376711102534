import React, { useState } from 'react';
import { bool, string, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
import isEqual from 'lodash/isEqual';

import css from './CustomerNoteForm.module.css';

const CustomerNoteFormComponent = props => {
  const [submittedValuesState, setSubmittedValuesState] = useState({});

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updateInProgress,
          updateProfileError,
          values,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitInProgress = updateInProgress;
        const submittedOnce = Object.keys(submittedValuesState).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValuesState);
        const submitDisabled =
          invalid || disabled || submitInProgress || pristine || pristineSinceLastSubmit;

        const customerNoteMessage = intl.formatMessage({
          id: 'CustomerNoteForm.label',
        });
        const customerNotePlaceholderHint = <FormattedMessage id="CustomerNoteForm.hint" />;
        const customerNotePlaceholderMessage = intl.formatMessage({
          id: 'CustomerNoteForm.placeholder',
        });

        const maxNoteLength = 500;
        const invalidNoteLengthMessage = intl.formatMessage(
          {
            id: 'CustomerNoteForm.invalidNoteLengthLabel',
          },
          { maxLength: maxNoteLength }
        );
        const noteMaxLength = validators.maxLength(invalidNoteLengthMessage, maxNoteLength);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();
              setSubmittedValuesState(values);
              handleSubmit(e);
            }}
          >
            <FieldTextInput
              id="customerNote"
              name="customerNote"
              inputRootClass={css.textarea}
              hotjarWhitelist={true}
              type="textarea"
              label={customerNoteMessage}
              placeholder={customerNotePlaceholderMessage}
              hint={customerNotePlaceholderHint}
              validate={noteMaxLength}
            />
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={pristineSinceLastSubmit}
            >
              Save
            </Button>
          </Form>
        );
      }}
    />
  );
};

CustomerNoteFormComponent.defaultProps = { className: null, fetchErrors: null };

CustomerNoteFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  disabled: bool,
  ready: bool,
  updated: bool,
  updateInProgress: bool,
  updateProfileError: propTypes.error,
  customerNote: string,
};

export default compose(injectIntl)(CustomerNoteFormComponent);
