import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { LocationSearchForm } from '../../forms';

import css from './SectionForHirersHero.module.css';

import image from '../../assets/for-hirers-hero.png';

const SectionForHirersHero = props => {
  const { rootClassName, className, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleSearchSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroMainTitleDiv}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionForHirersHero.title" />
          </h1>
          <div className={css.searchFormForHirersContainer}>
            <LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />
          </div>
        </div>
        <div className={css.imageDiv}>
          <img src={image} alt="trailer" className={css.image} />
        </div>
      </div>
    </div>
  );
};

SectionForHirersHero.defaultProps = { rootClassName: null, className: null };

SectionForHirersHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForHirersHero;
