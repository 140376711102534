import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';

import { IconReviewStar } from '..';
import css from './SectionForHirersTestimonials.module.css';

import { REVIEW_RATINGS } from '../../util/types';

import andrewHImage from './images/andrew-h.jpg';
import ashleyEImage from './images/ashley-e.jpg';

const SectionForHirersTestimonials = props => {
  const { rootClassName, className } = props;

  const stars = REVIEW_RATINGS;
  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    accessibility: true,
  };

  return (
    <div className={classes}>
      <h2 className={css.heading}>CASE STUDIES</h2>
      <h3 className={css.subHeading}>Our happy customers</h3>
      <div className={css.content}>
        <Slider {...settings}>
          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “Great flexibility on your part Tai. Whilst you had preferred times for pick up and
                drop off, you adapted and made our life easy. Thanks for a seamless experience. Your
                trailer was perfect for our needs. Still in great condition and close to us for easy
                use in the future. Thanks so much :)”
              </div>
              <div className={css.testimonialGiver}>
                <img src={andrewHImage} alt="Andrew H" className={css.testimonialPhoto} />
                <div>
                  <div className={css.testimonialName}>Andrew H</div>
                  <div className={css.testimonialLocationAndDate}>Melbourne</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “Great service! Matt is super friendly was able to get me a trailer fast and so
                close to Christmas with no issues. He even helped hook it up! The brand new trailer
                was more than big enough for what I needed and with no deposit you can't go wrong.
                Thanks Matt.”
              </div>
              <div className={css.testimonialGiver}>
                <img src={ashleyEImage} alt="Ashley E" className={css.testimonialPhoto} />
                <div>
                  <div className={css.testimonialName}>Ashley E</div>
                  <div className={css.testimonialLocationAndDate}>Melbourne</div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.testimonialWrapper}>
            <div className={css.testimonial}>
              <div>
                {stars.map(star => (
                  <IconReviewStar
                    key={`star-${star}`}
                    className={css.reviewStar}
                    isFilled={star <= 5}
                  />
                ))}
              </div>
              <div>
                “What a great idea! All worked without a hiccup. Best way to hire a trailer - thanks
                for making this happen so easily Phillip!”
              </div>
              <div className={css.testimonialGiver}>
                {/* <img src={user1Image} alt="testimonial provider" className={css.testimonialPhoto} /> */}
                <div>
                  <div className={css.testimonialName}>Noel L</div>
                  <div className={css.testimonialLocationAndDate}>Melbourne</div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

SectionForHirersTestimonials.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForHirersTestimonials.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForHirersTestimonials;
