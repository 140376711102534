import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';
import { formatMoney } from '../../util/currency';

import css from './DeclineModal.module.css';
import DeclineMessageForm from '../../forms/DeclineMessageForm/DeclineMessageForm';

const DeclineModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onDeclineSale,
    customerName,
    transaction,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'DeclineModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="DeclineModal.title" />
      </p>
      <p className={css.modalMessage}>
        {transaction.attributes.payoutTotal ? (
          <FormattedMessage
            id="DeclineModal.description"
            values={{ customerName, money: formatMoney(intl, transaction.attributes.payoutTotal) }}
          />
        ) : null}
      </p>
      <DeclineMessageForm
        saveActionMsg={intl.formatMessage({ id: 'TransactionPanel.declineButton' })}
        onSubmit={onDeclineSale}
        intl={intl}
      />
    </Modal>
  );
};

const { string } = PropTypes;

DeclineModal.defaultProps = {
  className: null,
  rootClassName: null,
};

DeclineModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(DeclineModal);
