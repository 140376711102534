import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { styles } from '../Invoice/InvoiceStyles';
import { string } from 'prop-types';
import { lineItemCustomerCommissionSubTotalMaybe } from '../../util/bookingBreakdown/lineItemCustomerCommissionSubTotalMaybe';

const styleSheet = styles;

const LineItemCustomerCommissionSubtotalMaybePdf = props => {
  const { transaction, unitType, userRole, intl } = props;

  const { label, price } = lineItemCustomerCommissionSubTotalMaybe(
    transaction,
    unitType,
    userRole,
    intl
  );

  const isCustomer = userRole === 'customer';

  return isCustomer && price ? (
    <View style={[styleSheet.row, styleSheet.boldFont]}>
      <View style={styleSheet.tColumnLeftWide}>
        <Text>{label}</Text>
      </View>
      <View style={styleSheet.rightAligned}>
        <Text>{price}</Text>
      </View>
    </View>
  ) : null;
};

LineItemCustomerCommissionSubtotalMaybePdf.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape,
};

export default LineItemCustomerCommissionSubtotalMaybePdf;
