import React from 'react';
import loadable from '@loadable/component';
const Chart = loadable(() => import('react-apexcharts'));

import { YearType } from '../../util/earnings';

const MonthlyEarningsChart = props => {
  const { earnings, yearType, type, maxMonthlyEarnings } = props;

  const horizontalAxisLabels =
    yearType === YearType.Calendar
      ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      : ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

  const colors = [
    '#58c4f1', // marketplaceColor 2019
    '#2b5797', // marketplaceColorDark 2020
    '#B4DD7F', // green from hiring page 2021
    '#FCAF58', // orange from hiring page 2022
    '#E8505B', // red from hiring page 2023
    '#8155FF', // purple from hiring page 2024
    '#58c4f1', // marketplaceColor 2025
    '#2b5797', // marketplaceColorDark 2026
    '#B4DD7F', // green from hiring page 2027
    '#FCAF58', // orange from hiring page 2028
    '#E8505B', // red from hiring page 2029
    '#8155FF', // purple from hiring page 2030
    '#58c4f1', // marketplaceColor 2031
    '#2b5797', // marketplaceColorDark 2032
    '#B4DD7F', // green from hiring page 2033
    '#FCAF58', // orange from hiring page 2034
    '#E8505B', // red from hiring page 2035
    '#8155FF', // purple from hiring page 2036
  ];

  const colorsForYears = earnings
    .map(e => e.name)
    .map(name => {
      const year = yearType === YearType.Calendar ? name : name.slice(3);
      return colors[year - 2019];
    });

  const formatTheDollaryDoos = val => {
    return val ? '$' + (val / 100).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '$0';
  };

  const chartOptions = {
    chart: {
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    colors: colorsForYears,
    dataLabels: {
      enabled: false,
      formatter: formatTheDollaryDoos,
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      // size: 1
    },
    xaxis: {
      categories: horizontalAxisLabels,
      title: {
        // text: ' '
      },
    },
    yaxis: {
      title: {
        // text: 'Total Earnings'
      },
      labels: {
        formatter: formatTheDollaryDoos,
      },
      min: 0,
      max: maxMonthlyEarnings,
      forceNiceScale: true,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: false,
      containerMargin: {
        left: 35,
        right: 60,
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart options={chartOptions} series={earnings} type={type} />
      {/* <Chart options={chartOptions} series={series} type="line" /> */}
    </div>
  );
};

export default MonthlyEarningsChart;
