import { shortUrl } from '../../util/api';
import * as log from '../../util/log';

import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const NO_QR_CODE_SHORT_URL_REQUEST = 'app/StickersPage/NO_QR_CODE_SHORT_URL_REQUEST';
export const FETCH_QR_CODE_SHORT_URL_REQUEST = 'app/StickersPage/FETCH_QR_CODE_SHORT_URL_REQUEST';
export const FETCH_QR_CODE_SHORT_URL_SUCCESS = 'app/StickersPage/FETCH_QR_CODE_SHORT_URL_SUCCESS';
export const FETCH_QR_CODE_SHORT_URL_ERROR = 'app/StickersPage/FETCH_QR_CODE_SHORT_URL_ERROR';

// ================ Reducer ================ //

const initialState = {
  pageReady: false,
  fetchInProgress: false,
  qrCodeShortUrl: null,
  fetchError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_QR_CODE_SHORT_URL_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
      };
    case FETCH_QR_CODE_SHORT_URL_SUCCESS: {
      const qrCodeShortUrl = payload.shortURL;
      return {
        ...state,
        qrCodeShortUrl,
        fetchInProgress: false,
        pageReady: true,
      };
    }
    case FETCH_QR_CODE_SHORT_URL_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchError: payload, pageReady: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchQrCodeShortUrlRequest = () => ({ type: FETCH_QR_CODE_SHORT_URL_REQUEST });
const fetchQrCodeShortUrlSuccess = response => ({
  type: FETCH_QR_CODE_SHORT_URL_SUCCESS,
  payload: response,
});
const fetchQrCodeShortUrlError = e => ({
  type: FETCH_QR_CODE_SHORT_URL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());
  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;
  const customUrl = `https://${process.env.REACT_APP_DOMAIN}/u/${userId}`;
  const bodyParams = {
    url: customUrl,
    utmSource: 'qrcode-sticker',
    params: {},
  };
  dispatch(fetchQrCodeShortUrlRequest());
  try {
    const response = await shortUrl({ bodyParams });
    dispatch(fetchQrCodeShortUrlSuccess(response));
  } catch (e) {
    log.error(e, 'fetching-qr-code-short-url-failed');
    dispatch(fetchQrCodeShortUrlError(e));
  }
};
