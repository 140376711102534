import React from 'react';
import PropTypes, { bool, object, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import { formatMoney } from '../../util/currency';
import { BookingTimeForm } from '../../forms';
import { addBooking } from '../../util/timeslots';
import { propTypes } from '../../util/types';

import css from './ChangeBookingModal.module.css';
import { getQuantity } from '../../util/lineItemHelpers';
import { localizeAndFormatTime } from '../../util/dates';
import config from '../../config';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { paymentConfirmedAt } from '../../util/transaction';

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const ChangeBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    isProvider,
    onCloseModal,
    onManageDisableScrolling,
    onSubmit,
    transaction,
    listing,
    isOwnListing,
    monthlyTimeSlots,
    onFetchTimeSlots,
    unitType,
    customerName,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ChangeBookingModal.later' });

  const defaultOpeningHours = {
    entries: [
      { dayOfWeek: 'mon', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'tue', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'wed', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'thu', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'fri', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'sat', startTime: '06:00', endTime: '22:00' },
      { dayOfWeek: 'sun', startTime: '06:00', endTime: '22:00' },
    ],
  };
  const openingHours = listing.attributes.publicData.openingHours || defaultOpeningHours;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

  const isStartDayOutsideRange = dayMoment => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    const bookingStartMoment = moment(transaction.booking.attributes.start);
    const bookingHasStarted = moment().isAfter(bookingStartMoment);

    // this is the time of the confirm-payment transition as that's from when
    // Stripe's 90-day countdown begins. we want to avoid people postponing the booking beyond
    // the period for which we are allowed to hold funds in Stripe.
    const paymentConfirmedMoment = moment(paymentConfirmedAt(transaction));

    return (
      (bookingHasStarted && !isInclusivelyAfterDay(dayMoment, bookingStartMoment)) ||
      !isInclusivelyBeforeDay(dayMoment, paymentConfirmedMoment.add(endOfRange, 'days'))
    );
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ChangeBookingModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id={
            isProvider ? 'ChangeBookingModal.providerDescription' : 'ChangeBookingModal.description'
          }
          values={{ customerName }}
        />
      </p>

      <BookingTimeForm
        className={css.bookingForm}
        formId="ChangeBookingModal"
        submitButtonWrapperClassName={css.submitButtonWrapper}
        unitType={unitType}
        onSubmit={onSubmit}
        price={listing.attributes.price}
        isProvider={isProvider}
        // priceDiscount1={
        //   listing.attributes.publicData.priceDiscount1
        //     ? JSON.parse(listing.attributes.publicData.priceDiscount1, sdkTypes.reviver)
        //     : null
        // }
        // priceDiscount2={
        //   listing.attributes.publicData.priceDiscount2
        //     ? JSON.parse(listing.attributes.publicData.priceDiscount2, sdkTypes.reviver)
        //     : null
        // }
        openingHours={openingHours}
        customerName={customerName}
        listingId={listing.id}
        isOwnListing={isOwnListing}
        monthlyTimeSlots={addBooking(
          transaction.booking.attributes.start,
          transaction.booking.attributes.end,
          timeZone,
          monthlyTimeSlots
        )}
        onFetchTimeSlots={onFetchTimeSlots}
        startDatePlaceholder={intl.formatDate(
          transaction.booking.attributes.start,
          dateFormattingOptions
        )}
        endDatePlaceholder={intl.formatDate(
          transaction.booking.attributes.end,
          dateFormattingOptions
        )}
        startTimePlaceholder={localizeAndFormatTime(
          intl,
          timeZone,
          transaction.booking.attributes.start
        )}
        endTimePlaceholder={localizeAndFormatTime(
          intl,
          timeZone,
          transaction.booking.attributes.end
        )}
        timeZone={timeZone}
        publicData={listing.attributes.publicData}
        onFetchTransactionLineItems={() => null}
        fetchLineItemsInProgress={false}
        paidForQuantity={getQuantity(unitType, transaction)}
        isStartDayOutsideRange={isStartDayOutsideRange}
      />
    </Modal>
  );
};

const { string } = PropTypes;

ChangeBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ChangeBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOpen: bool,
  isProvider: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  onSubmit: func,
  transaction: propTypes.transaction.isRequired,
  listing: propTypes.listing.isRequired,
  isOwnListing: bool,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default injectIntl(ChangeBookingModal);
