import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 25 May, 2020</p>

      <p>The Local Hire Group Pty Ltd ABN 60 631 399 981 (localtrailerhire.com.au) provides a booking platform, payment platform, searchable directory and introductory service between a hirer who requires a Trailer for hire (Hirer) and an owner who is able to provide that Trailer for hire (Owner).  The Services are available on the localtrailerhire.com.au website at www.localtrailerhire.com.au.</p>

      <h2>1. Contact</h2>
      <p>These Marketplace Terms and Conditions (Terms) form a binding legal agreement between localtrailerhire.com.au, and each person or entity using the Services (User).  By using the Site and Services, each User agrees to comply with and be legally bound by these Terms.  Please read the Terms carefully.  If there are any questions, the User is encouraged to contact localtrailerhire.com.au using the contact details at the end of these Terms.</p>

      <p>The User’s use of the Site and Services indicates that:</p>
      <ul>
        <li>the User has had sufficient opportunity to access the Terms and contact localtrailerhire.com.au;</li>
        <li>the User has read, accepted and will comply with the Terms;</li>
        <li>the User has legal capacity to enter into a contract for sale; and</li>
        <li>the User is 18 years or older.</li>
      </ul>

      <p>If this is not correct, or if the User does not agree to these Terms, the User is not permitted to use any of the Services.</p>

      <p>These Terms may be amended from time to time, without prior notice.  Use of our Services following any such amendments will be deemed to be confirmation that the User accepts those amendments.  localtrailerhire.com.au recommends that each User check the current Terms, before continuing use of the Services.</p>

      <p>These Terms supplement and incorporate the localtrailerhire.com.au policies, including without limitation the Website Terms of Use and Privacy Policy posted on the Site;</p>


      <h2>2. Introductory Service Only</h2>
      <p>The Site and Services provide a booking platform, payment platform, searchable directory and an online introductory platform for Hirers and Owners through which:</p>

      <p>Owners will be able to:</p>
      <ul>
      <li>create a listing (Listing) on the Site;</li>
      <li>advertise the availability of Owner Trailers (Owner Trailers); and </li>
      </ul>

      <p>Hirers will be able to:</p>
      <ul>
        <li>search directories;</li>
        <li>view Listings;</li>
        <li>request Owner Trailers; and</li>
        <li>contact Owners using the link provided on the Site to arrange collection of the Trailer from the Owner’s nominated address.</li>
      </ul>

      <p>A User may create a profile as per information requested on the Site (Profile).</p>

      <p>Each User understands and agrees that the Site is an online introductory platform only, and that the responsibilities of localtrailerhire.com.au are limited to facilitating the availability of the Site and the Services.</p>

      <p>localtrailerhire.com.au is not a party to any agreement entered into between Hirer and Owner.  localtrailerhire.com.au is not a referrer or booking agent, and provides no such related services.  localtrailerhire.com.au has no control over the conduct of Hirers, Owners and any other users of the Site and the Services.  localtrailerhire.com.au disclaims all liability in this regard, as set out in these Terms. localtrailerhire.com.au reserves the right to remove any Listing at any time for
      any reason. </p>

      <p>Any arrangement between Hirer and Owner is solely between Hirer and Owner.  It is strictly and expressly not part of the User’s agreement with localtrailerhire.com.au.</p>

      <h2>3. Online Registration</h2>

      <p>A User can browse and view Listings on the Site as an unregistered user of the Site.  A User must register on the Site and create an account (Account) to access some Services and features on the Site, including making a Listing, creating a Profile, contacting another User and making a Booking.</p>
      <p>Each User may only have one (1) Account on the Site including a Profile.</p>
      <p>Basic information is required when registering on the Site for an Account.  Each User is required to provide certain information including name, email address, drivers licence number and location, and select a username and password.</p>
      <p>Each User agrees to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete.  localtrailerhire.com.au reserves the right to suspend or terminate any User’s Account and their access to the Site and Services if any information provided to localtrailerhire.com.au proves to be inaccurate, not current or incomplete.</p>
      <p>To keep information secure and confidential, localtrailerhire.com.au uses a number of security features. Further information on the storage and security of your personal information can be found in our Privacy Policy.</p>
      <p>It is the User’s responsibility to keep their Account details, username and password confidential.  The User is liable for all activity on their Account, including purchases made using their account details.  The User agrees that it will not disclose their password to any third party and that it will take sole responsibility for any activities or actions under their Account, whether or not it has authorised such activities or actions.</p>
      <p>The User will immediately notify localtrailerhire.com.au of any unauthorised use of their Account.</p>

      <h2>4. Users and User Profiles</h2>

      <p>Users who have created an Account are permitted to create Profiles. The User’s Profile for their use of the Site is created from the personal information it provides to localtrailerhire.com.au.</p>
      <p>Each User acknowledges and agrees that it is responsible for their own Profile.</p>
      <p>Users can access the Site to communicate with other Users and obtain or provide Trailer.</p>
      <p>If a Hirer contacts an Owner and requests a Trailer, any agreement entered into is between the Hirer and Owner. localtrailerhire.com.au is not a party to the agreement.</p>
      <p>Each User acknowledges and agrees that any communications entered into with another User is at their own risk. localtrailerhire.com.au does not provide a User with, and cannot guarantee that other Users have, genuine intentions.</p>
      <p>Each User should report to localtrailerhire.com.au, any activities or requests of Users which are, or which the User reasonably believes to be:</p>
      <ul><li>suspicious;</li>
      <li>inconsistent;</li>
      <li>illegal; or</li>
      <li>likely to have a negative effect on the reputation of localtrailerhire.com.au, the Site, Services and/or a User.</li></ul>
      <p>Each User acknowledges and agrees that while the Site allows Users to communicate with each other, Users are not permitted to share the contact information of other Users.</p>
      <p>Each User represents and warrants that any content that it provides and Profile and Listing that it posts:</p>
      <ul><li>will not breach any agreements they has entered into with any third parties;</li>
      <li>will be in compliance with all applicable laws, tax requirements, and rules and regulations that may apply to any User in their local area and country; and</li>
      <li>will not conflict with the rights of third parties.</li></ul>
      <p>For the avoidance of doubt, localtrailerhire.com.au assumes no responsibility for a User’s compliance with any applicable laws, rules and regulations.</p>
      <p>localtrailerhire.com.au reserves the right, at any time and without prior notice, to remove or disable access to any Account and/or Profile and/or Listing for any reason, including Accounts and/or Profiles and/or Listings that localtrailerhire.com.au, at its sole discretion, considers to be objectionable for any reason, in violation of these Terms or otherwise harmful to the Site or Services.</p>
      <p>Each User warrants that throughout the term of these Terms that:</p>
      <ul>
      <li>there are no legal restrictions preventing it from agreeing to these Terms;</li>
      <li>it will cooperate with localtrailerhire.com.au and provide localtrailerhire.com.au with information that is reasonably necessary to enable localtrailerhire.com.au to perform the Services as requested by localtrailerhire.com.au from time to time, and comply with these requests in a timely manner,;</li>
      <li>the information it provides to localtrailerhire.com.au is true, correct and complete;</li>
      <li>it will not infringe any third party rights in working with localtrailerhire.com.au and receiving the Services; and</li>
      <li>it is responsible for obtaining any consents, licences and permissions from other parties necessary for the Services to be provided, at their cost, and for providing localtrailerhire.com.au with the necessary consents, licences and permissions.</li>
      </ul>

      <h2>5. Ratings and Reviews</h2>
      <p>Users may rate a Profile (Rating), which determines the popularity of individual Profiles.  Hirers may also provide feedback to Owners regarding Trailer hired by that Hirer (Review).</p>
      <p>Ratings and Reviews of an Owner can be viewed by any User.  Ratings and Reviews will remain viewable until the relevant Owner’s Account, Profile and Listing is removed or terminated.</p>
      <p>Hirers and Owners must provide true, fair and accurate information in their Review.</p>
      <p>If, in localtrailerhire.com.au’s reasonable assessment, the Review is untrue, unfair, inaccurate, offensive or inappropriate, localtrailerhire.com.au may delete the Review or ban the User from posting the Review.  localtrailerhire.com.au does not undertake to review each Review made by a User.</p>
      <p>To the fullest extent permitted by law, localtrailerhire.com.au is not responsible for the content of any Reviews.</p>

      <h2>6. Intellectual Property</h2>
      <p>All logos, slogans, content, designs, diagrams, drawings, graphics, images, layouts, appearance, videos, ideas, methods, databases, codes, algorithms, software, fees, pricing, notes, documents, domain names, confidential information, copyright, rights in circuit layouts (or similar rights), registered or unregistered trademarks, trade names, patent, know-how, trade secret and any other intellectual or industrial property whether such rights are capable of being registered or not (collectively Intellectual Property), including but not limited to copyright which subsists in all creative and literary works displayed on the Site, the App and Services, the layout, appearance and look of the Site and App, together with any applications for registration and any rights to registration or renewal of such rights anywhere in the world, whether created before or after the date of these Terms and whether used or contained in the Site is owned, controlled or licensed to localtrailerhire.com.au.</p>
      <p>The User agrees that, as between the User and localtrailerhire.com.au, localtrailerhire.com.au  owns or holds the relevant licence to all Intellectual Property rights in the Site and Services, and that nothing in these Terms constitutes a transfer of any Intellectual Property. The Intellectual Property, Site and Services are protected by copyright, trademark, patent, trade secret, international treaties, laws and other proprietary or industrial rights whether such rights are capable of being registered or not, and also may have security components that protect digital information only as authorised by localtrailerhire.com.au or the owner of the content.</p>
      <p>Some Intellectual Property used in connection with the Site and Services are the trademarks of their respective owners (collectively Third Party Marks).</p>
      <p>localtrailerhire.com.au’s Intellectual Property and Third Party Marks may not be copied, imitated or used, in whole or in part, without the prior written permission of localtrailerhire.com.au or the applicable trademark holder or Intellectual Property owner.</p>
      <p>Users of the Site do not obtain any interest or licence in the Intellectual Property or Third Party Marks without the prior written permission of localtrailerhire.com.au or the applicable Intellectual Property owner.  Users may not do anything which interferes with or breaches the Intellectual Property rights.</p>


      <h2>7. User Licence</h2>
      <p>Subject to these Terms, localtrailerhire.com.au grants the User a personal, non-exclusive, non-transferable, limited and revocable licence to use the Site and Services for their own personal and/or non-commercial use only on a computer or mobile device owned or controlled by the User as permitted in accordance with these Terms (User Licence), and not to use the Site, App and Services in any other way or for any other purpose, apart from local fair dealing legislation in accordance with the Copyright Act 1968 (Cth).  All other uses are prohibited without localtrailerhire.com.au’s prior written consent.</p>
      <p>The right to use the Site and Services is licensed to the User and not being sold to the User.  A User has no rights in the Site and Services other than to use it in accordance with these Terms.</p>
      <p>This Agreement and User Licence governs any updates to, or supplements or replacements for the Site and Services, unless separate Terms accompany such updates, supplements or replacements, in which case the separate Terms will apply.</p>

      <h2>8. Permitted and Prohibited Conduct</h2>
      <p>The User is solely responsible for compliance with any and all laws, rules, regulations, including but not limited to tax obligations that may apply to its use of the Site and Services. In connection with the User’s use of the Site and Services, the User may not and agrees that they will not:</p>
      <ul><li>use the Site or Services for any commercial or other purposes that are not expressly permitted by these Terms;</li>
      <li>register for more than one Account or register for an Account on behalf of another individual and/or entity;</li>
      <li>use the Services to find an Owner and then complete, or assist another individual to complete, a Booking or transaction independent of the Services in order to circumvent the obligation to pay any fees related to localtrailerhire.com.au’s provision of the Services;</li>
      <li>post any Review or upload any content (including but not limited to User Content) that is offensive, contains nudity or inappropriate language, contains racial or religious ranting or discrimination or defames another User, Profile or Listing;</li>
      <li>submit any false or misleading information;</li>
      <li>as an Owner, offer any Trailer that it does not intend to honour or cannot provide;</li>
      <li>as a Hirer, make any offers or Bookings to the Owner that it does not intend to honour;</li>
      <li>violate any local, state, national, or other law or regulation, or any order of a court, including, without limitation, zoning restrictions and tax regulations;</li>
      <li>copy, store or otherwise access any information contained on the Site and Services or content for purposes not expressly permitted by these Terms;</li>
      <li>infringe the rights of any person or entity, including without limitation, their intellectual property, privacy, publicity or contractual rights;</li>
      <li>use the Site or Services to transmit, distribute, post or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, calling card or account numbers;</li>
      <li>use the Site, App or Services in connection with the distribution of unsolicited commercial email, i.e. spam or advertisements;</li>
      <li>stalk or harass any other user of the Services or collect or store any personally identifiable information about any other user other than for purposes of transacting as a User on the Site or App;</li>
      <li>use, display, mirror or frame the Site or App, or any individual element within the Site, Services, the localtrailerhire.com.au name, any localtrailerhire.com.au trademark, logo or other Intellectual Property, information, or the layout and design of any page or form contained on a page, without localtrailerhire.com.au’s express written consent; or</li>
      <li>advocate, encourage, or assist any third party in doing any of the foregoing.</li></ul>

      <h2>9. Content</h2>
      <p>The Site, Services and Intellectual Property are protected by copyright, trademark, and other laws of Australia and international countries. The User acknowledges and agrees that the Site, Services and Intellectual Property, including all associated intellectual property rights are the exclusive property of localtrailerhire.com.au and its licensors. The User will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Site and Services, or Intellectual Property.</p>
      <p>The User must not post, upload, publish, submit or transmit any content that:</p>
      <ul><li>infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy;</li>
      <li>is fraudulent, false, misleading or deceptive;</li>
      <li>denigrates localtrailerhire.com.au, the Site, Services, a Hirer, or a Owner;</li>
      <li>violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability;</li>
      <li>is defamatory, obscene, pornographic, vulgar, offensive, promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;</li>
      <li>is violent or threatening or promotes violence or actions that are threatening to any other person; or</li>
      <li>promotes illegal or harmful activities or substances.</li></ul>

      <h2>10. User Content</h2>
      <p>Users are permitted to post, upload, publish, submit or transmit relevant information and content (User Content).  By making available any User Content or any Intellectual Property on or through the Site, App and Services, the User grants to localtrailerhire.com.au a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free licence to use the User Content and Intellectual Property, with the right to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, or otherwise exploit such User Content and Intellectual Property on, through, or by means of the Site and Services.</p>
      <p>The User agrees that it is solely responsible for all User Content and Intellectual Property that it makes available through the Site and Services. The User represents and warrants that:</p>
      <li>it is either the sole and exclusive owner of all User Content and Intellectual Property that it makes available through the Site, App and Services, or that it has all rights, licences, consents and releases that are necessary to grant to localtrailerhire.com.au the rights in such User Content or Intellectual Property, as contemplated under these Terms; and</li>
      <li>neither the User Content nor the posting, uploading, publication, submission or transmittal of the User Content or localtrailerhire.com.au’s use of the User Content (or any portion thereof) on, through or by means of the Site and the Services will infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade secret, moral rights or other Intellectual Property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</li>
      <p>localtrailerhire.com.au may at its sole discretion remove any User Content that is offensive or in breach of these Terms.</p>

      <h2>11. Disclaimers</h2>
      <p>localtrailerhire.com.au does not guarantee that Trailer will be requested by any Hirer, nor does localtrailerhire.com.au  guarantee that Hirers will be able to find desirable Owners or Trailer.</p>
      <p>localtrailerhire.com.au does not endorse any Hirer, Owner, Profile, Listing or Trailer. localtrailerhire.com.au requires Hirers and Owners to confirm that they have provided accurate information. localtrailerhire.com.au does not perform any sort of background checks of Hirers and Owners, and does not confirm, any Hirer or Owner’s identity, Profile, Listing, insurances and/or Trailers.</p>
      <p>localtrailerhire.com.au cannot and does not control the content contained in any Profiles or Listings or the condition, legality or suitability of any Hirers or Trailer. Hirers are responsible for determining the identity and suitability of Owners that they contact via the Services and the Trailer.</p>
      <p>localtrailerhire.com.au accepts no responsibility for and makes no representations or warranties to the User or to any other person or entity as to the reliability, accuracy or completeness of the information contained on the Site or the App. localtrailerhire.com.au disclaims any and all liability related to any and all Hirer, Owner, Profiles, Listings and Trailers.</p>
      <p>By using the Site or Services, the User agrees that any legal remedy or liability that it seeks to obtain for actions or omissions of other Hirers or Owners or other third parties will be limited to a claim against the Hirer or Owner or other third party who caused it harm. localtrailerhire.com.au  encourages the User to communicate directly with the relevant Hirer or Owner on the Site and Services regarding any communications or arrangements made between them and to resolve any dispute between them.</p>
      <p>To the fullest extent allowable under applicable law, localtrailerhire.com.au disclaims all warranties, representations and conditions, whether express or implied, including any warranties, representations and conditions that the Site or Services are merchantable, of satisfactory quality, reliable, accurate, complete, suitable or fit for a particular purpose or need, non-infringing or free of defects or errors.</p>
      <p>For the avoidance of doubt, localtrailerhire.com.au is not responsible for any duties, fees, taxation, visa or immigration matters associated under these Terms. localtrailerhire.com.au  advises that all Users using the Site, App and Services should seek advice in relation to these matters.</p>
      <p>Each Hirer and Owner who uses the Site, App and the Services does so at their own risk.</p>
      <p>localtrailerhire.com.au excludes all express and implied conditions and warranties, except for the User’s Rights, to the fullest extent permitted by law, including but not limited to:</p>
      <p>localtrailerhire.com.au expressly disclaims any implied or express guarantees, warranties, representations or conditions of any kind, which are not stated in these Terms;</p>
      <p>localtrailerhire.com.au does not warrant that the Site, the Services, content on the Site (including pictures, videos, sound clips, resumes, links etc.), or the User’s access to the Site, the App or the Services will be error free, that any defects will be corrected or that the Site, the App or the server which stores and transmits material to the User is free of viruses or any other harmful components;</p>
      <p>localtrailerhire.com.au takes no responsibility for, and will not be liable for, the Site, the Services, the Hirer, the Owner and the Trailers being unavailable or not in existence, of a particular standard of workmanship, failing to meet the Profile description (including photographs), failing to meet the Listing description, failing to meet the User’s needs, being of less than merchantable quality or are fit for Hirer’s purpose; and</p>
      <p>localtrailerhire.com.au will not be liable for any loss, damage, costs or expense whether direct, indirect, incidental, special, consequential and/or incidental, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal, bodily injury, death or emotional distress, loss of revenue, production, opportunity, access to markets, goodwill, reputation, use or any indirect, remote, abnormal or unforeseeable loss, or any loss or damage relating to business interruption, loss of programs or other data on the User’s information systems or costs of replacement goods, or otherwise, suffered by the User or claims made against the User, arising out of or in connection with the Site, Services, content on the Site, inability to access or use the Site, the Services, any Profile, any Listing, the Trailers, the Trailers or the Terms, even if localtrailerhire.com.au was expressly advised of the likelihood of such loss or damage.</p>
      <p>The User agrees not to attempt to impose liability on, or seek any legal remedy from localtrailerhire.com.au with respect to such actions or omissions.</p>

      <h2>12. Limitation of Liability</h2>
      <p>localtrailerhire.com.au’s total liability arising out of or in connection with the Site, the Services or the Terms, however arising, including under contract, tort, including negligence, in equity, under statute or otherwise, will not exceed the total fees paid by the User to localtrailerhire.com.au in the twelve (12) month period prior to the event giving rise to the liability, or one hundred dollars (AUD$100) if no such payments have been made, as applicable.</p>
      <p>The limitations of damages set forth above are fundamental elements of the basis of the agreement between localtrailerhire.com.au and the User.</p>
      <p>This limitation of liability reflects the allocation of risk between the parties. The limitations specified in this section will survive and apply even if any limited remedy specified in these terms is found to have failed of its essential purpose. The limitations of liability provided in these terms are to the benefit of localtrailerhire.com.au.</p>

      <h2>13. Indemnity</h2>
      <p>Each User agrees to defend and indemnify and hold localtrailerhire.com.au (and localtrailerhire.com.au’s parent, related bodies corporate, officers, directors, contractors, employees and agents) harmless from and against any claims, actions, suits, demands, damages, liabilities, costs or expenses (including legal costs and expenses on a full indemnity basis), including in tort, contract or negligence, arising out of or connected to the User’s use of or access to the Services; any breach by the User of these Terms; any wilful, unlawful or negligent act or omission by the User; and any violation by the User of any applicable laws or the rights of any third party.</p>
      <p>localtrailerhire.com.au reserves the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by the User, in which event the User will cooperate in asserting any available defences.</p>
      <p>This defence and indemnification obligation will survive these Terms and the User’s use of the Site or Services. These Terms, and any rights and licences granted hereunder, may not be transferred or assigned by the User, but may be assigned by localtrailerhire.com.au without restriction.</p>

      <h2>14. General</h2>
      <p>Accuracy: While localtrailerhire.com.au will endeavour to keep the information up to date and correct, localtrailerhire.com.au  makes no representations, warranties or guarantee, express or implied, about the completeness, accuracy, reliability, suitability or availability of any information, images, products, services, or related graphics contained on the Site or App for any particular purpose. The User hereby acknowledges that such information and materials may contain mistakes, inaccuracies or errors and localtrailerhire.com.au expressly excludes any liability for such to the fullest extent permissible by law.</p>
      <p>Termination: localtrailerhire.com.au reserves the right to refuse supply of the Services required by any User, terminate any User’s Account, terminate its contract with any User, and remove or edit content on the Site at its sole discretion, without incurring any liability to the User. If localtrailerhire.com.au decides to terminate a User’s Account any of the following may occur, with or without notice to the User: (a) the User’s Account will be deactivated, its password will be disabled and it will not be able to access the Site, Services, their Account or their User Content; (b) any pending or future Booking (in respect of a Hirer or Owner) will be immediately terminated; (c) localtrailerhire.com.au may communicate to the relevant Hirer or Owner that the Booking has been cancelled; (d) localtrailerhire.com.au may refund the Hirer in full, regardless of the cancellation and refund policy; (e) localtrailerhire.com.au may contact Hirer to inform them of potential alternative Trailers available from different Owner on the Site, App and Services; and (f) the User will not be entitled to any compensation for Bookings that were cancelled as a result of a suspension, deactivation or termination of their Account.</p>
      <p>Fraudulent Activities: Each User acknowledges and agrees that, in the event localtrailerhire.com.au reasonably suspects that there are fraudulent activities occurring within the Site and Services, localtrailerhire.com.au reserves the right to immediately terminate any Accounts involved in such activities, contact the relevant authorities and provide all necessary information to assist in proceedings and investigations.</p>
      <p>Force Majeure: localtrailerhire.com.au  will not be liable for any delay or failure to perform its obligations under the Terms if such delay is due to any circumstance beyond its reasonable control.</p>
      <p>Notice: Any notice in connection with the Terms will be deemed to have been duly given when made in writing and delivered or sent by email or post to the party to whom such notice is intended to be given or to such other address or email address as may from time to time be notified in writing to the other party.</p>
      <p>Waiver: Any failure by a party to insist upon strict performance by the other of any provision in the Terms will not be taken to be a waiver of any existing or future rights in relation to the provision. No waiver by localtrailerhire.com.au  of any of the Terms shall be effective unless localtrailerhire.com.au expressly states that it is a waiver and localtrailerhire.com.au communicates it to the User in writing.</p>
      <p>Assignment: A User must not assign any rights and obligations under the Terms whether in whole or in part without localtrailerhire.com.au’s prior written consent.</p>
      <p>Severability: If any of the Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>
      <p>Jurisdiction and Applicable Law: These Terms, use of this Site, the App, the Services and any dispute arising out of any User’s use of the Site, App or Services is subject to the laws of Victoria, Australia, and subject to the exclusive jurisdiction of the Victorian courts. The Site may be accessed throughout Australia and overseas. localtrailerhire.com.au  makes no representation that the content of the Site complies with the laws (including intellectual property laws) of any country outside Australia. If a User accesses the Site or App from outside Australia, it does so as its own risk and are responsible for complying with the laws in the place where he/she accesses the Site or App.</p>
      <p>Entire Agreement: These Terms and any document expressly referred to in them represent the entire agreement between localtrailerhire.com.au and each User, and supersede any prior agreement, understanding or arrangement between localtrailerhire.com.au and each User, whether oral or in writing.</p>


      <h2>For questions and notices, please contact:</h2>

      <p>The Local Hire Group Pty Ltd<br/>
      Level 8, 607 Bourke Street, Melbourne VIC 3000.<br />
      ABN: 60 631 399 981</p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
