import React from 'react';
import { string, bool } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldSelect } from '../../components';

import css from './SelectListingForm.module.css';

const SelectListingFormComponent = props => {
  const { listings, onSubmit, intl } = props;
  const listingFilterConfig = {
    id: 'EarningsPage.ListingFilter',
    label: intl.formatMessage({ id: 'EarningsPage.listingFilter' }),
    options: listings,
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, initialValue } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form className={classes}>
            <FieldSelect
              id={listingFilterConfig.id}
              name="listingId"
              label={listingFilterConfig.label}
              onChange={onSubmit}
              initialValue={initialValue}
            >
              <option key="default" value={'all-trailers'}>
                All trailers
              </option>
              {listingFilterConfig.options.map(listing => (
                <option key={listing.key} value={listing.key}>
                  {listing.label}
                </option>
              ))}
            </FieldSelect>
          </Form>
        );
      }}
    />
  );
};

SelectListingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

SelectListingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
};

const SelectListingForm = SelectListingFormComponent;

export default SelectListingForm;
