import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemManualCustomerCommissionMaybe } from '../../util/bookingBreakdown/lineItemManualCustomerCommissionMaybe';

import css from './BookingBreakdown.module.css';

const LineItemManualCustomerCommissionMaybe = props => {
  const { transaction, intl, isProvider } = props;

  const { label, price, showManualFeeAdjustment } = lineItemManualCustomerCommissionMaybe(
    transaction,
    intl
  );

  return !isProvider && price && showManualFeeAdjustment ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemManualCustomerCommissionMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemManualCustomerCommissionMaybe;
