import React, { useState } from 'react';
import config from '../../config';
import PropTypes, { bool, func } from 'prop-types';
import classNames from 'classnames';
import { ExternalLink, InlineTextButton } from '../../components';
import { propTypes } from '../../util/types';
import CallBackModal from '../CallBackModal/CallBackModal';

import css from './SectionContactUs.module.css';

import phoneImage from './images/phone.png';
import facebookImage from './images/facebook.png';
import emailImage from './images/mail.png';

const SectionContactUsComponent = props => {
  const {
    rootClassName,
    className,
    currentUser,
    onSubmitCallMeBack,
    submitInProgress,
    callMeBackError,
    callMeBackSuccess,
    sectionTitle,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [isCallBackModalOpen, setCallBackModalOpen] = useState(false);

  const { siteFacebookPage } = config;

  const contactUsSectionTitle = sectionTitle ? sectionTitle : 'Contact us';

  const openModal = () => {
    setCallBackModalOpen(true);
  };

  const closeModal = () => {
    setCallBackModalOpen(false);
  };

  const callMeBackSuccessMessage = (
    <div className={css.contactUsContent}>
      <img src={phoneImage} alt="sign up form" className={css.contactImage} />
      <div className={css.contactUsSuccess}>
        <span>Thanks for your message.</span> <br />
        <span>We'll be in touch soon.</span>
      </div>
    </div>
  );

  return (
    <div className={classes}>
      <h3 className={css.heading}>{contactUsSectionTitle}</h3>
      <div className={css.contactUsContainer}>
        {callMeBackSuccess ? (
          callMeBackSuccessMessage
        ) : (
          <div className={css.contactUsCallBack} onClick={openModal} role="button" tabIndex={0}>
            <div className={css.contactUsContent}>
              <img src={phoneImage} alt="sign up form" className={css.contactImage} />
              <div>
                <span className={css.subtitle}>Request a call back</span> <br />
                <InlineTextButton onClick={openModal}>Call me back</InlineTextButton>
              </div>
            </div>
          </div>
        )}
        <ExternalLink href={siteFacebookPage}>
          <div className={css.contactUsContent}>
            <img src={facebookImage} alt="sign up form" className={css.contactImage} />
            <div>
              <span className={css.subtitle}>Find us on </span>
              <br /> Facebook
            </div>
          </div>
        </ExternalLink>
        <a href="mailto:hello@localtrailerhire.com.au">
          <div className={css.contactUsContent}>
            <img src={emailImage} alt="sign up form" className={css.contactImage} />
            <div>
              <span className={css.subtitle}>Email us at </span> <br />
              hello@localtrailerhire.com.au
            </div>
          </div>
        </a>
      </div>
      <CallBackModal
        id="CallBackModal"
        isOpen={isCallBackModalOpen && !callMeBackSuccess}
        onCloseModal={() => closeModal()}
        onManageDisableScrolling={() => {}}
        currentUser={currentUser}
        rootClassName={css.form}
        onSubmitCallMeBack={onSubmitCallMeBack}
        submitInProgress={submitInProgress}
        callMeBackError={callMeBackError}
      />
    </div>
  );
};

SectionContactUsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
};

SectionContactUsComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionContactUsComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  onSubmitCallMeBack: func.isRequired,
  submitInProgress: bool.isRequired,
  callMeBackSuccess: bool,
  callMeBackError: propTypes.error,
  sectionTitle: string,
};

export default SectionContactUsComponent;
