import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../components';

import css from './CallBackForm.module.css';
import { propTypes } from '../../util/types';

const CallBackFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        invalid,
        intl,
        submitInProgress,
        callMeBackError,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      const submitDisabled = invalid || submitInProgress;

      const callMeBackErrorMessage = callMeBackError ? (
        <p className={css.error}>
          <FormattedMessage id="CallBackModal.callMeBackFailed" />
        </p>
      ) : null;

      // name
      const nameLabel = intl.formatMessage({
        id: 'CallBackModal.nameLabel',
      });
      const namePlaceholder = intl.formatMessage({
        id: 'CallBackModal.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'CallBackModal.nameRequiredMessage',
      });
      const nameRequired = validators.required(nameRequiredMessage);

      // phoneNumber
      const phoneNumberLabel = intl.formatMessage({
        id: 'CallBackModal.phoneNumberLabel',
      });
      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'CallBackModal.phoneNumberPlaceholder',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'CallBackModal.phoneNumberRequiredMessage',
      });
      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

      // email
      const emailLabel = intl.formatMessage({
        id: 'CallBackModal.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'CallBackModal.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'CallBackModal.emailRequiredMessage',
      });
      const emailRequired = validators.required(emailRequiredMessage);

      // reasonForCallBack
      const reasonForCallBackLabel = intl.formatMessage({
        id: 'CallBackModal.reasonForCallBackLabel',
      });
      const reasonForCallBackRequiredMessage = intl.formatMessage({
        id: 'CallBackModal.reasonForCallBackRequiredMessage',
      });
      const reasonForCallBackRequired = validators.required(reasonForCallBackRequiredMessage);

      // otherInfo
      const otherInfoLabel = intl.formatMessage({
        id: 'CallBackModal.otherInfoLabel',
      });
      const otherInfoPlaceholder = intl.formatMessage({
        id: 'CallBackModal.otherInfoPlaceholder',
      });
      const otherInfoRequiredMessage = intl.formatMessage({
        id: 'CallBackModal.otherInfoRequiredMessage',
      });
      const otherInfoRequired = validators.required(otherInfoRequiredMessage);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            type="text"
            className={css.name}
            name="name"
            id={formId ? `${formId}.name` : 'name'}
            label={nameLabel}
            placeholder={namePlaceholder}
            validate={nameRequired}
          />
          <FieldTextInput
            type="tel"
            className={css.phoneNumber}
            name="phoneNumber"
            id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
            label={phoneNumberLabel}
            placeholder={phoneNumberPlaceholder}
            validate={phoneNumberRequired}
          />
          <FieldTextInput
            type="email"
            className={css.email}
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={emailRequired}
          />
          <FieldSelect
            className={css.reasonForCallBack}
            name="reasonForCallBack"
            id={formId ? `${formId}.reasonForCallBack` : 'reasonForCallBack'}
            label={reasonForCallBackLabel}
            validate={reasonForCallBackRequired}
          >
            <option value="">Select a reason</option>
            <option value="Booking enquiry">Booking enquiry</option>
            <option value="Existing booking">Existing booking</option>
            <option value="Previous hire">Previous hire</option>
            <option value="Current hire">Current hire</option>
            <option value="Technical support">Technical support</option>
            <option value="Emergency">Emergency</option>
            <option value="Other">Other</option>
          </FieldSelect>
          <FieldTextInput
            type="textarea"
            className={css.otherInfo}
            name="otherInfo"
            id={formId ? `${formId}.otherInfo` : 'otherInfo'}
            label={otherInfoLabel}
            placeholder={otherInfoPlaceholder}
            validate={otherInfoRequired}
          />
          {callMeBackErrorMessage}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            Submit
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

const { bool, func } = PropTypes;

CallBackFormComponent.propTypes = {
  onSubmit: func.isRequired,
  submitInProgress: bool.isRequired,
  callMeBackError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CallBackForm = compose(injectIntl)(CallBackFormComponent);
CallBackForm.displayName = 'CallBackForm';

export default CallBackForm;
