import React from 'react';
import { composeValidators, numberAtLeast, numberAtMost, required } from '../../util/validators';
import { FieldNumberInput } from '../../components';

import css from './EditListingOverviewForm.module.css';

const CustomSizeInputFieldMaybe = props => {
  const { intl } = props;

  const min = 0.5;
  const step = 0.01;
  const maxLength = 11.0;
  const maxWidth = 2.5;

  const lengthLabel = intl.formatMessage({
    id: 'EditListingOverviewForm.lengthLabel',
  });
  const lengthPlaceholder = intl.formatMessage({
    id: 'EditListingOverviewForm.lengthPlaceholder',
  });
  const lengthHint = intl.formatMessage({
    id: 'EditListingOverviewForm.lengthHint',
  });
  const widthLabel = intl.formatMessage({
    id: 'EditListingOverviewForm.widthLabel',
  });
  const widthPlaceholder = intl.formatMessage({
    id: 'EditListingOverviewForm.widthPlaceholder',
  });
  const widthHint = intl.formatMessage({
    id: 'EditListingOverviewForm.widthHint',
  });
  const lengthAtLeastMessage = intl.formatMessage(
    { id: 'EditListingOverviewForm.lengthAtLeast' },
    { min }
  );
  const lengthAtMostMessage = intl.formatMessage(
    {
      id: 'EditListingOverviewForm.lengthAtMost',
    },
    { max: maxLength }
  );
  const widthAtLeastMessage = intl.formatMessage(
    {
      id: 'EditListingOverviewForm.widthAtLeast',
    },
    { min }
  );
  const widthAtMostMessage = intl.formatMessage(
    {
      id: 'EditListingOverviewForm.widthAtMost',
    },
    { max: maxWidth }
  );
  const lengthRequired = required(
    intl.formatMessage({
      id: 'EditListingOverviewForm.lengthRequired',
    })
  );
  const widthRequired = required(
    intl.formatMessage({
      id: 'EditListingOverviewForm.widthRequired',
    })
  );
  return (
    <div className={css.customSizeInputWrapper}>
      <FieldNumberInput
        id="lengthMetres"
        name="lengthMetres"
        className={css.customSizeInput}
        hotjarWhitelist={true}
        label={lengthLabel}
        placeholder={lengthPlaceholder}
        hint={lengthHint}
        validate={composeValidators(
          lengthRequired,
          numberAtLeast(lengthAtLeastMessage, min),
          numberAtMost(lengthAtMostMessage, maxLength)
        )}
        autoFocus
        min={min}
        max={maxLength}
        step={step}
      />
      <FieldNumberInput
        id="widthMetres"
        name="widthMetres"
        className={css.customSizeInput}
        hotjarWhitelist={true}
        label={widthLabel}
        placeholder={widthPlaceholder}
        hint={widthHint}
        validate={composeValidators(
          widthRequired,
          numberAtLeast(widthAtLeastMessage, min),
          numberAtMost(widthAtMostMessage, maxWidth)
        )}
        min={min}
        max={maxWidth}
        step={step}
      />
    </div>
  );
};

export default CustomSizeInputFieldMaybe;
