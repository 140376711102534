import React, { Component } from 'react';
import { string, func, oneOfType, arrayOf, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import {
  AvatarMedium,
  NamedLink,
  InlineTextButton,
  OwnerStats,
  CustomerStats,
  ExpandableCustomerNote,
} from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import css from './UserCard.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }
  render() {
    const { expanded } = this.state;
    const { className, bio } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expanded: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expanded ? bio : truncatedBio}
        {bio !== truncatedBio && !expanded ? showMore : null}
      </p>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    onContactUser,
    isProvider,
    salesForSameCustomer,
    fetchSalesForSameCustomerInProgress,
    fetchSalesForSameCustomerError,
    updateInProgress,
    updateProfileError,
    onSubmitCustomerNoteForm,
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, bio } = ensuredUser.attributes.profile;
  const businessName = ensuredUser.attributes.profile.publicData?.businessName;

  const firstListingCreatedAt =
    ensuredUser.attributes.profile.publicData?.listingStats?.firstListingCreatedAt || null;

  const customerNote =
    !!ensuredCurrentUser.id && !!isProvider
      ? ensuredCurrentUser.attributes.profile.privateData[`customerNote_${ensuredUser.id.uuid}`]
      : null;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const separator = isCurrentUser ? null : (
    <React.Fragment>
      &#8203;<span className={css.linkSeparator}>•</span>&#8203;
    </React.Fragment>
  );

  const contact = (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  );

  const editProfileMobile = (
    <span className={css.editProfileMobile}>
      <span className={css.linkSeparator}>•</span>
      <NamedLink name="ProfileSettingsPage">
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
    </span>
  );

  const editProfileDesktop = isCurrentUser ? (
    <NamedLink className={css.editProfileDesktop} name="ProfileSettingsPage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  const links = ensuredUser.id ? (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {!isProvider ? (
        <React.Fragment>
          {separator}
          {isCurrentUser ? editProfileMobile : contact}
        </React.Fragment>
      ) : null}
    </p>
  ) : null;

  const addCustomerNotes =
    !!ensuredCurrentUser.id && !!isProvider ? (
      <ExpandableCustomerNote
        ensuredUser={ensuredUser}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        onSubmitCustomerNoteForm={onSubmitCustomerNoteForm}
        customerNote={customerNote}
      />
    ) : null;

  const sharedByHeading = businessName ? (
    <>
      <h3 className={css.heading}>
        <FormattedMessage id="UserCard.heading" values={{ name: businessName }} />
      </h3>
      <h4 className={css.subheading}>
        Operated by <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
      </h4>
    </>
  ) : (
    <h3 className={css.heading}>
      <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
    </h3>
  );

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.editProfileContainer}>
          {editProfileDesktop}
          <div className={css.headingRow}>
            <div className={css.headingContainer}>
              {sharedByHeading}
              {firstListingCreatedAt && !isProvider ? (
                <p className={css.subheading}>Sharing since {firstListingCreatedAt}</p>
              ) : null}
            </div>
            <AvatarMedium className={css.avatar} user={user} />
          </div>
        </div>

        {isProvider ? (
          <CustomerStats
            user={ensuredUser}
            currentUser={ensuredCurrentUser}
            salesForSameCustomer={salesForSameCustomer}
            fetchSalesForSameCustomerInProgress={fetchSalesForSameCustomerInProgress}
            fetchSalesForSameCustomerError={fetchSalesForSameCustomerError}
          />
        ) : (
          <OwnerStats ownerUser={ensuredUser} />
        )}
        {hasBio ? <ExpandableBio className={css.desktopBio} bio={bio} /> : null}
        {hasBio ? <ExpandableBio className={css.mobileBio} bio={bio} /> : null}
        {links}
        {addCustomerNotes}
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func,
  salesForSameCustomer: arrayOf(propTypes.transaction),
  fetchSalesForSameCustomerInProgress: bool,
  fetchSalesForSameCustomerError: propTypes.error,

  // update profile
  updateInProgress: bool,
  updateProfileError: propTypes.error,
  onSubmitCustomerNoteForm: func,
};

export default UserCard;
