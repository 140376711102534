import merge from 'lodash/merge';

import { storableError } from '../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from './user.duck';
import { pickBy } from 'lodash';
import { denormalisedResponseEntities } from '../util/data';

// ================ Action types ================ //

export const SAVE_LICENCE_DETAILS_REQUEST = 'app/updateLicenceDetails/SAVE_LICENCE_DETAILS_REQUEST';
export const SAVE_LICENCE_DETAILS_SUCCESS = 'app/updateLicenceDetails/SAVE_LICENCE_DETAILS_SUCCESS';
export const SAVE_LICENCE_DETAILS_ERROR = 'app/UpdateLicenceModal/SAVE_LICENCE_DETAILS_ERROR';

export const SAVE_LICENCE_DETAILS_CLEAR = 'app/updateLicenceDetails/SAVE_LICENCE_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveProfileError: null,
  saveLicenceDetailsInProgress: false,
  licenceDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_LICENCE_DETAILS_REQUEST:
      return {
        ...state,
        saveLicenceDetailsInProgress: true,
        saveProfileError: null,
        licenceDetailsChanged: false,
      };
    case SAVE_LICENCE_DETAILS_SUCCESS:
      return { ...state, saveLicenceDetailsInProgress: false, licenceDetailsChanged: true };
    case SAVE_LICENCE_DETAILS_ERROR:
      return { ...state, saveLicenceDetailsInProgress: false, saveProfileError: payload };

    case SAVE_LICENCE_DETAILS_CLEAR:
      return {
        ...state,
        saveLicenceDetailsInProgress: false,
        saveProfileError: null,
        licenceDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveLicenceDetailsRequest = () => ({ type: SAVE_LICENCE_DETAILS_REQUEST });
export const saveLicenceDetailsSuccess = () => ({ type: SAVE_LICENCE_DETAILS_SUCCESS });

export const saveProfileError = error => ({
  type: SAVE_LICENCE_DETAILS_ERROR,
  payload: error,
  error: true,
});

export const saveLicenceDetailsClear = () => ({ type: SAVE_LICENCE_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a profile update request to the API and return the current user.
 *
 * from the LicenceDetailsForm on CheckoutPage and ListingPage
 */
const requestSaveProfile = params => (dispatch, getState, sdk) => {
  const residentialAddress = params.residentialAddress;
  const driversLicenceExpiryDate = params.driversLicenceExpiryDate;
  const driversLicenceNumber = params.driversLicenceNumber;
  const driversLicenceIssuedBy = params.driversLicenceIssuedBy;
  const building = params.building;

  const possibleProtectedData = {
    residentialAddress,
    driversLicenceExpiryDate,
    driversLicenceNumber,
    driversLicenceIssuedBy,
    building,
  };

  // remove undefined values; e.g. from the LicenceDetailsForm on CheckoutPage and ListingPage
  const protectedData = pickBy(possibleProtectedData, v => v !== undefined);

  return sdk.currentUser
    .updateProfile(
      { protectedData },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveProfileError(storableError(e)));
      // pass the same error so that the SAVE_LICENCE_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save profile data and update the current user.
 */
const saveProfile = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveProfile(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveLicenceDetailsSuccess());
      })
      // error action dispatched in requestSaveProfile
      .catch(e => null)
  );
};

/**
 * Update licence details, actions depend on which data has changed
 */
export const saveLicenceDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveLicenceDetailsRequest());
  const {
    driversLicenceExpiryDate,
    driversLicenceNumber,
    driversLicenceIssuedBy,
    currentDriversLicenceExpiryDate,
    currentDriversLicenceNumber,
    currentDriversLicenceIssuedBy,
    residentialAddress,
    currentResidentialAddress,
    building,
    currentBuilding,
  } = params;
  const licenceExpiryChanged = driversLicenceExpiryDate !== currentDriversLicenceExpiryDate;
  const driversLicenceNumberChanged = driversLicenceNumber !== currentDriversLicenceNumber;
  const driversLicenceIssuedByChanged = driversLicenceIssuedBy !== currentDriversLicenceIssuedBy;
  const residentialAddressChanged = residentialAddress !== currentResidentialAddress;
  const buildingChanged = building !== currentBuilding;

  const profileChanged =
    licenceExpiryChanged ||
    driversLicenceNumberChanged ||
    driversLicenceIssuedByChanged ||
    residentialAddressChanged ||
    buildingChanged;

  if (profileChanged) {
    return dispatch(
      saveProfile({
        residentialAddress,
        driversLicenceExpiryDate,
        driversLicenceNumber,
        driversLicenceIssuedBy,
        building,
      })
    );
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
