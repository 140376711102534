import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txHasBeenAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenCompleted,
  txIsReviewableBy,
  getChangeBookingTransition,
  getCompleteEarlyTransition,
  getCancelBookingTransition,
  TRANSITION_EXPIRE_REFUNDABLE_PERIOD,
  TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import { parse } from '../../util/urlHelpers';
import {
  AvatarLarge,
  BookingPanel,
  Button,
  ChangeBookingModal,
  CancelBookingModal,
  DeclineModal,
  IconSpinner,
  Modal,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  SectionSafeHiringTips,
  UserCard,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import * as log from '../../util/log';
import { findNextBoundary } from '../../util/dates';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import ChangeButtonMaybe from './ChangeButtonMaybe';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const declineMessage = values => {
  if (values.declineMessage !== 'other') {
    const selectedMessage = config.custom.declineMessageOptions.find(
      option => option.key === values.declineMessage
    );
    if (selectedMessage) {
      values.message =
        'Sorry for the inconvenience but the trailer is unavailable. ' +
        selectedMessage.label +
        '.';
    }
  }
  return values;
};
const cancelRequestMessage = values => {
  if (values.cancelMessage !== 'other') {
    const selectedMessage = config.custom.cancelRequestMessageOptions.find(
      option => option.key === values.cancelMessage
    );
    if (selectedMessage) {
      values.message =
        'Sorry for the inconvenience but I have cancelled my booking request. ' +
        selectedMessage.label +
        '.';
    }
  }
  return values;
};
const cancelBookingMessage = (values, isRefundableTransaction) => {
  if (values.cancelMessage === 'forProvider') {
    return;
  }
  const apologeticStartMaybe = isRefundableTransaction ? 'Sorry for the inconvenience but ' : '';
  if (values.cancelMessage !== 'other') {
    const selectedMessage = config.custom.cancelBookingMessageOptions.find(
      option => option.key === values.cancelMessage
    );
    if (selectedMessage) {
      values.message = `${apologeticStartMaybe}I have cancelled my booking. ${selectedMessage.label}.`;
    }
  }
  return values;
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      sendMessageFormFocused: false,
      sendMessageFormHasValue: false,
      isReviewModalOpen: !!parse(location.search).review,
      isDeclineModalOpen: false,
      isChangeBookingModalOpen: false,
      isCancelBookingModalOpen: false,
      isMarkReturnedEarlyModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onCloseReviewModal = this.onCloseReviewModal.bind(this);
    this.onOpenDeclineModal = this.onOpenDeclineModal.bind(this);
    this.onOpenChangeBookingModal = this.onOpenChangeBookingModal.bind(this);
    this.onOpenCancelBookingModal = this.onOpenCancelBookingModal.bind(this);
    this.openMarkReturnedEarlyModal = this.openMarkReturnedEarlyModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onSendMessageFormChange = this.onSendMessageFormChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onCloseReviewModal() {
    this.setState({ isReviewModalOpen: false });
  }

  onOpenDeclineModal() {
    this.setState({ isDeclineModalOpen: true });
  }

  onOpenChangeBookingModal() {
    this.setState({ isChangeBookingModalOpen: true });
  }
  onOpenCancelBookingModal() {
    this.setState({ isCancelBookingModalOpen: true });
  }
  openMarkReturnedEarlyModal() {
    this.setState({ isMarkReturnedEarlyModalOpen: true });
  }

  onReturnedEarlyConfirmClick() {
    const { transaction, onChangeBookingRequest } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const tz = currentListing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());
    const params = {
      bookingStart: currentTransaction.booking.attributes.start,
      bookingEnd: nextBoundary,
      bookingDisplayStart: currentTransaction.booking.attributes.displayStart,
      bookingDisplayEnd: nextBoundary,
    };

    console.log(params);
    console.log(JSON.stringify(params));

    onChangeBookingRequest(
      params,
      currentTransaction.id,
      getCompleteEarlyTransition(currentTransaction),
      currentListing
    );
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormChange(setting) {
    this.setState({ sendMessageFormHasValue: setting });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.onSendMessageFormChange(false);
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      onChangeBookingRequest,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onCancelBooking,
      cancelInProgress,
      cancelBookingError,
      cancelBookingSuccess,
      changeBookingRequestInProgress,
      changeBookingRequestError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      salesForSameCustomer,
      fetchSalesForSameCustomerInProgress,
      fetchSalesForSameCustomerError,
      updateInProgress,
      updateProfileError,
      onSubmitCustomerNoteForm,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    // TODO: remove once all pending transactions are using 'customer-cancel' transaction process
    const isCustomerCancelTransactionProcessAlias =
      transaction.attributes.processName === 'customer-cancel';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showChangeButton: isCustomer,
          showCancelLink: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenCompleted(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txHasBeenAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showChangeButton: isProvider && !isCustomerBanned,
          showReturnedEarlyButton:
            isProvider && moment(tx.booking.attributes.start).isBefore(moment()),
          showCancelLink:
            isCustomer &&
            isCustomerCancelTransactionProcessAlias &&
            moment(tx.booking.attributes.start)
              .add(1, 'hour')
              .isAfter(moment()),
        };
      } else {
        log.error(new Error('Unknown transaction heading'), 'unknown-transaction-heading', tx);
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const listingLocation = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;
    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const bookingStart = currentTransaction?.booking?.attributes.start;
    const bookingEnd = currentTransaction?.booking?.attributes.end;
    const listingTimeZone = currentListing?.attributes?.availabilityPlan?.timezone;
    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const isBookingRequest = !txHasBeenAccepted(currentTransaction);

    const cancelMessage = values => {
      if (isBookingRequest) {
        return cancelRequestMessage(values);
      } else {
        return cancelBookingMessage(values, isRefundableTransaction);
      }
    };
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={this.onOpenDeclineModal}
        sendMessageFormHasValue={this.state.sendMessageFormHasValue}
      />
    );
    const changeButton = (
      <ChangeButtonMaybe
        showButton={stateData.showChangeButton}
        onClick={this.onOpenChangeBookingModal}
        onCancelClick={this.onOpenCancelBookingModal}
        onReturnedEarlyClick={this.openMarkReturnedEarlyModal}
        showReturnedEarlyButton={stateData.showReturnedEarlyButton}
        showCancelLink={stateData.showCancelLink}
        cancelBookingError={cancelBookingError}
        cancelInProgress={cancelInProgress}
        isBookingRequest={isBookingRequest}
      />
    );

    const changeBookingRequestErrorMessage = changeBookingRequestError ? (
      <div className={css.changeBookingErrors}>
        <p className={css.actionError}>
          <FormattedMessage id="TransactionPanel.changeBookingFailed" />
        </p>
      </div>
    ) : null;

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const isRefundableTransaction =
      transaction.attributes.lastTransition !== TRANSITION_EXPIRE_REFUNDABLE_PERIOD &&
      transaction.attributes.lastTransition !== TRANSITION_CHANGE_NON_REFUNDABLE_BOOKING;

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const providerResponseDelay =
      currentProvider.attributes.profile.publicData?.responseStatsLast12Months
        ?.meanBookingResponseDelayDisplay || '';

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.flexBox}>
            <div className={css.txInfo}>
              <DetailCardImage
                rootClassName={css.imageWrapperMobile}
                avatarWrapperClassName={css.avatarWrapperMobile}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />
              {isProvider ? (
                <div className={css.avatarWrapperProviderDesktop}>
                  <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
                </div>
              ) : null}

              <PanelHeading
                panelHeadingState={stateData.headingState}
                transactionRole={transactionRole}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isCustomerBanned={isCustomerBanned}
                listingId={currentListing.id && currentListing.id.uuid}
                listingTitle={listingTitle}
                listingDeleted={listingDeleted}
                providerResponseDelay={providerResponseDelay}
              />

              <div className={css.bookingDetailsMobile}>
                <AddressLinkMaybe
                  rootClassName={css.addressMobile}
                  location={listingLocation}
                  geolocation={geolocation}
                  showAddress={stateData.showAddress}
                />
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
              </div>

              {savePaymentMethodFailed ? (
                <p className={css.genericError}>
                  <FormattedMessage
                    id="TransactionPanel.savePaymentMethodFailed"
                    values={{ paymentMethodsPageLink }}
                  />
                </p>
              ) : null}

              <div className={css.mobileActionButtons}>
                {changeBookingRequestInProgress || cancelInProgress ? (
                  <IconSpinner />
                ) : (
                  changeButton
                )}
                {changeBookingRequestErrorMessage}
              </div>

              <FeedSection
                rootClassName={css.feedContainer}
                currentTransaction={currentTransaction}
                currentUser={currentUser}
                fetchMessagesError={fetchMessagesError}
                fetchMessagesInProgress={fetchMessagesInProgress}
                initialMessageFailed={initialMessageFailed}
                messages={messages}
                oldestMessagePageFetched={oldestMessagePageFetched}
                onOpenReviewModal={this.onOpenReviewModal}
                onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                totalMessagePages={totalMessagePages}
              />
              {showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onKeyUp={this.onSendMessageFormChange}
                  onSubmit={this.onMessageSubmit}
                />
              ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              )}

              {isProvider ? (
                <div id="customerCard" className={css.userCard}>
                  <UserCard
                    user={currentCustomer}
                    currentUser={currentUser}
                    isProvider={isProvider}
                    salesForSameCustomer={salesForSameCustomer}
                    fetchSalesForSameCustomerInProgress={fetchSalesForSameCustomerInProgress}
                    fetchSalesForSameCustomerError={fetchSalesForSameCustomerError}
                    updateInProgress={updateInProgress}
                    updateProfileError={updateProfileError}
                    onSubmitCustomerNoteForm={onSubmitCustomerNoteForm}
                  />
                </div>
              ) : null}

              {stateData.showSaleButtons ? (
                <div className={css.mobileActionButtons}>{saleButtons}</div>
              ) : null}
            </div>

            <div className={css.asideDesktop}>
              <div className={css.detailCard}>
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />

                <DetailCardHeadingsMaybe
                  showDetailCardHeadings={stateData.showDetailCardHeadings}
                  listingTitle={listingTitle}
                  subTitle={bookingSubTitle}
                  location={listingLocation}
                  geolocation={geolocation}
                  showAddress={stateData.showAddress}
                />
                {stateData.showBookingPanel ? (
                  <BookingPanel
                    className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={currentListing}
                    title={listingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    unitType={unitType}
                    isAuthenticated={!!currentUser}
                  />
                ) : null}
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
                <div className={css.desktopActionButtons}>
                  {changeBookingRequestInProgress ? <IconSpinner /> : changeButton}
                  {changeBookingRequestErrorMessage}
                </div>

                {stateData.showSaleButtons ? (
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                ) : null}
              </div>
            </div>
          </div>
          {isCustomer ? <SectionSafeHiringTips /> : null}
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen && txIsReviewableBy(currentTransaction, isCustomer)}
          onCloseModal={this.onCloseReviewModal}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <DeclineModal
          id="DeclineModal"
          isOpen={this.state.isDeclineModalOpen}
          onCloseModal={() => this.setState({ isDeclineModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onDeclineSale={values => {
            this.setState({ isDeclineModalOpen: false });
            this.onMessageSubmit(declineMessage(values));
            onDeclineSale(currentTransaction.id);
          }}
          customerName={otherUserDisplayName}
          transaction={currentTransaction}
        />
        <Modal
          id="MarkReturnedEarlyModal"
          contentClassName={css.returnedEarlyModalContent}
          isOpen={this.state.isMarkReturnedEarlyModalOpen}
          onClose={() => this.setState({ isMarkReturnedEarlyModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <p className={css.modalTitle}>Are you sure?</p>
          <p className={css.modalMessage}>
            <FormattedMessage id="TransactionPanel.returnedEarlyDescription" />
          </p>
          <Button
            onClick={() => {
              this.setState({ isMarkReturnedEarlyModalOpen: false });
              this.onReturnedEarlyConfirmClick();
            }}
            className={css.confirmButton}
          >
            <FormattedMessage id="TransactionPanel.returnedEarlyConfirmButton" />
          </Button>
        </Modal>
        {stateData.showChangeButton ? (
          <ChangeBookingModal
            id="ChangeBookingModal"
            isOpen={this.state.isChangeBookingModalOpen}
            isProvider={isProvider}
            customerName={customerDisplayName}
            onCloseModal={() => this.setState({ isChangeBookingModalOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmit={values => {
              this.setState({ isChangeBookingModalOpen: false });
              // TODO switch back to using date directly
              const startDate = new Date(parseInt(values.bookingStartTime));
              const endDate = new Date(parseInt(values.bookingEndTime));
              const params = {
                bookingStart: startDate,
                bookingEnd: endDate,
                bookingDisplayStart: startDate,
                bookingDisplayEnd: endDate,
              };
              onChangeBookingRequest(
                params,
                currentTransaction.id,
                getChangeBookingTransition(transaction),
                currentListing
              );
            }}
            transaction={currentTransaction}
            listing={currentListing}
            isOwnListing={false}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            unitType={unitType}
          />
        ) : null}

        <CancelBookingModal
          id="CancelBookingModal"
          isOpen={this.state.isCancelBookingModalOpen && !cancelBookingSuccess}
          onCloseModal={() => this.setState({ isCancelBookingModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          cancelInProgress={cancelInProgress}
          onCancelBooking={values => {
            onCancelBooking(
              currentTransaction.id,
              cancelMessage(values),
              getCancelBookingTransition(transaction)
            );
            this.setState({ isCancelBookingModalOpen: false });
          }}
          providerName={userDisplayNameAsString(currentProvider, '')}
          listingTitle={listingTitle}
          bookingStart={bookingStart}
          bookingEnd={bookingEnd}
          listingTimeZone={listingTimeZone}
          isRefundableTransaction={isRefundableTransaction}
          isBookingRequest={isBookingRequest}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  cancelBookingError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
  updateInProgress: false,
  updateProfileError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  onCancelBooking: func.isRequired,
  onChangeBookingRequest: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  cancelInProgress: bool.isRequired,
  cancelBookingSuccess: bool.isRequired,
  changeBookingRequestInProgress: bool.isRequired,
  changeBookingRequestError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  cancelBookingError: propTypes.error,
  salesForSameCustomer: arrayOf(propTypes.transaction),
  fetchSalesForSameCustomerInProgress: bool.isRequired,
  fetchSalesForSameCustomerError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // update profile
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  onSubmitCustomerNoteForm: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = compose(
  withRouter,
  injectIntl
)(TransactionPanelComponent);

export default TransactionPanel;
