import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import Decimal from 'decimal.js';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION_TAX,
} from '../types';
import { txIsPayoutManuallyAdjusted } from '../transaction';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Checks if line item represents commission tax
 */
const isCommissionTax = lineItem => {
  return lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX;
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(
    item => !isCommission(item) && !isCommissionTax(item) && !item.reversal
  );
};

/**
 * Checks if a transaction has a commission line-item for
 * a given user role.
 */
const txHasCommission = (transaction, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  } else if (userRole === 'provider') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION
    );
  }
  return !!commissionLineItem;
};

const amountToMoney = amount => new Money(amount, config.currency);

const adjustedSubTotal = (transaction, subTotal) => {
  const manualTotals = transaction.attributes.metadata?.payoutAdjustment ?? 0;
  return amountToMoney(manualTotals * 100 + subTotal.amount.toNumber());
};

export const lineItemSubTotalMaybe = (transaction, unitType, userRole, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const refund = transaction.attributes.lineItems.find(
    item => item.code === unitType && item.reversal
  );

  // Show unit purchase line total (unit price * quantity) as a subtotal.
  // PLEASE NOTE that this assumes that the transaction doesn't have other
  // line item types than the defined unit type (e.g. week, month, year).
  const showSubTotal = txHasCommission(transaction, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(transaction);
  // line totals of those line items combined
  const subTotal = lineItemsTotal(subTotalLineItems);

  const subTotalAdjusted = txIsPayoutManuallyAdjusted(transaction)
    ? adjustedSubTotal(transaction, subTotal)
    : subTotal;

  const formattedSubTotalLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.subTotal" />
  ) : (
    'Subtotal'
  );

  const formattedSubTotal = () => {
    if (subTotalLineItems.length > 0) {
      return !!intl
        ? formatMoney(intl, subTotalAdjusted)
        : `$${(subTotalAdjusted.amount / 100).toFixed(2)}`;
    }
  };

  return {
    label: formattedSubTotalLabel,
    price: formattedSubTotal(),
    showSubTotal,
    isRefund: !!refund,
  };
};
