import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getMonthStartInTimeZone, monthIdStringInTimeZone } from './dates';
import { cloneDeep } from 'lodash';

export const addBooking = (startDate, endDate, timeZone, monthlyTimeSlots) => {
  const bookingStartMonthId = monthIdStringInTimeZone(startDate, timeZone);
  const bookingEndMonthId = monthIdStringInTimeZone(endDate, timeZone);
  if (
    !!monthlyTimeSlots[bookingStartMonthId] &&
    !!monthlyTimeSlots[bookingStartMonthId].timeSlots &&
    !!monthlyTimeSlots[bookingEndMonthId] &&
    !!monthlyTimeSlots[bookingEndMonthId].timeSlots
  ) {
    const precedingSlot = getPrecedingTimeSlot(
      startDate,
      timeZone,
      monthlyTimeSlots[bookingStartMonthId].timeSlots
    );
    const followingSlot = getFollowingTimeSlot(
      endDate,
      timeZone,
      monthlyTimeSlots[bookingEndMonthId].timeSlots
    );
    const immediatelyFollowingSlot = getImmediatelyFollowingTimeSlot(
      endDate,
      timeZone,
      monthlyTimeSlots[bookingEndMonthId].timeSlots
    );

    // not having a preceding slot could mean that the booking is in progress,
    // or the booking is immediately following on from another booking or unavailable time
    const isBookingInProgress = !precedingSlot;
    if (!!followingSlot) {
      if (bookingStartMonthId === bookingEndMonthId) {
        const newSlots = isBookingInProgress
          ? [
              ...removeTimeSlots(
                [followingSlot.id.uuid],
                monthlyTimeSlots[bookingStartMonthId].timeSlots
              ),
              extendSlotToBookingStart(followingSlot, startDate),
            ]
          : [
              ...removeTimeSlots(
                [precedingSlot.id.uuid, followingSlot.id.uuid],
                monthlyTimeSlots[bookingStartMonthId].timeSlots
              ),
              combineSlotsWithBookingBetween(precedingSlot, followingSlot),
            ];
        const newMonthlyTimeSlots = cloneDeep(monthlyTimeSlots);
        newMonthlyTimeSlots[bookingEndMonthId].timeSlots = newSlots;
        return newMonthlyTimeSlots;
      } else {
        const newStartMonthSlots = isBookingInProgress
          ? startDateToEndOfMonthTimeSlot(startDate, bookingStartMonthId)
          : [
              ...removeTimeSlots(
                [precedingSlot.id.uuid],
                monthlyTimeSlots[bookingStartMonthId].timeSlots
              ),
              extendSlotToEndOfMonth(precedingSlot),
            ];
        const newEndMonthSlots = !!immediatelyFollowingSlot
          ? [
              ...removeTimeSlots(
                [followingSlot.id.uuid],
                monthlyTimeSlots[bookingEndMonthId].timeSlots
              ),
              extendSlotToStartOfMonth(followingSlot),
            ]
          : [
              createTimeSlot(moment(bookingEndMonthId).toDate(), endDate),
              ...monthlyTimeSlots[bookingEndMonthId].timeSlots,
            ];
        const newMonthlyTimeSlots = cloneDeep(monthlyTimeSlots);
        newMonthlyTimeSlots[bookingStartMonthId].timeSlots = newStartMonthSlots;
        newMonthlyTimeSlots[bookingEndMonthId].timeSlots = newEndMonthSlots;
        monthIdsInBetweenDates(startDate, endDate).forEach(monthId => {
          newMonthlyTimeSlots[monthId].timeSlots = wholeMonthTimeSlot(monthId);
        });
        return newMonthlyTimeSlots;
      }
    } else {
      // no following slot - possibly an immediately-following booking (or unavailable time) is filling up the rest of the month
      if (bookingStartMonthId === bookingEndMonthId) {
        const newSlots = !precedingSlot
          ? [createTimeSlot(startDate, endDate)]
          : [
              ...removeTimeSlots(
                [precedingSlot.id.uuid],
                monthlyTimeSlots[bookingStartMonthId].timeSlots
              ),
              extendSlotToBookingEnd(precedingSlot, endDate),
            ];
        const newMonthlyTimeSlots = cloneDeep(monthlyTimeSlots);
        newMonthlyTimeSlots[bookingEndMonthId].timeSlots = newSlots;
        return newMonthlyTimeSlots;
      } // TODO: what if it's a cross-month booking AND there's no following slot
    }
  }
  return monthlyTimeSlots;
};

export const combineSlotsWithBookingBetween = (precedingSlot, followingSlot) => {
  const combined = cloneDeep(precedingSlot);
  combined.attributes.end = followingSlot.attributes.end;
  return combined;
};

export const extendSlotToEndOfMonth = precedingSlot => {
  const combined = cloneDeep(precedingSlot);
  combined.attributes.end = moment(combined.attributes.end)
    .add(1, 'month')
    .startOf('month')
    .toDate();
  return combined;
};

export const extendSlotToStartOfMonth = followingSlot => {
  const combined = cloneDeep(followingSlot);
  combined.attributes.start = moment(combined.attributes.start)
    .startOf('month')
    .toDate();
  return combined;
};

export const extendSlotToBookingStart = (followingSlot, bookingStartDate) => {
  const combined = cloneDeep(followingSlot);
  combined.attributes.start = bookingStartDate;
  return combined;
};

export const extendSlotToBookingEnd = (precedingSlot, bookingEndDate) => {
  const combined = cloneDeep(precedingSlot);
  combined.attributes.end = bookingEndDate;
  return combined;
};

export const wholeMonthTimeSlot = monthId => {
  const startDate = moment(monthId).toDate();
  const endDate = moment(monthId)
    .add(1, 'month')
    .toDate();
  return [createTimeSlot(startDate, endDate)];
};

export const createTimeSlot = (startDate, endDate) => {
  return {
    id: {
      _sdkType: 'UUID',
      uuid: 'generated-on-frontend',
    },
    type: 'timeSlot',
    attributes: {
      type: 'time-slot/time',
      seats: 1,
      start: startDate,
      end: endDate,
    },
  };
};
export const startDateToEndOfMonthTimeSlot = (startDate, monthId) => {
  const endDate = moment(monthId)
    .add(1, 'month')
    .toDate();
  return [
    {
      id: {
        _sdkType: 'UUID',
        uuid: 'generated-on-frontend',
      },
      type: 'timeSlot',
      attributes: {
        type: 'time-slot/time',
        seats: 1,
        start: startDate,
        end: endDate,
      },
    },
  ];
};

export const monthIdsInBetweenDates = (startDate, endDate) => {
  let date = moment(startDate).add(1, 'month');
  let result = [];

  while (date.isBefore(endDate)) {
    result.push(date.format('YYYY-MM'));
    date = date.add(1, 'month');
  }
  return result;
};

export const removeTimeSlots = (uuids, timeSlots) => {
  return timeSlots.filter(timeSlot => !uuids.includes(timeSlot.id.uuid));
};

export const getPrecedingTimeSlot = (startDate, timeZone, timeSlots) => {
  return timeSlots.find(timeSlot =>
    moment(timeSlot.attributes.end, timeZone).isSame(moment(startDate, timeZone))
  );
};

export const getFollowingTimeSlot = (endDate, timeZone, timeSlots) => {
  return sortTimeSlots(timeSlots).find(timeSlot =>
    moment(timeSlot.attributes.start, timeZone).isSameOrAfter(moment(endDate, timeZone))
  );
};

export const getImmediatelyFollowingTimeSlot = (endDate, timeZone, timeSlots) => {
  return timeSlots.find(timeSlot =>
    moment(timeSlot.attributes.start, timeZone).isSame(moment(endDate, timeZone))
  );
};

export const sortTimeSlots = timeSlots =>
  timeSlots.sort((a, b) => {
    if (a.attributes.start < b.attributes.start) {
      return -1;
    }
    if (a.attributes.start > b.attributes.start) {
      return 1;
    }
    return 0;
  });

export const addBufferToSlots = (monthlyTimeSlots, timeZone) => {
  const updatedTimeSlots = {};
  Object.keys(monthlyTimeSlots).forEach(month => {
    const slotsForMonth = monthlyTimeSlots[month];

    // Copy the original monthly metadata
    updatedTimeSlots[month] = {
      ...slotsForMonth,
      timeSlots: [], // We will add updated time slots here
    };

    const timeSlots = slotsForMonth.timeSlots;

    if (timeSlots && Array.isArray(timeSlots)) {
      const updatedSlotsForMonth = [];

      for (let i = 0; i < timeSlots.length; i++) {
        const currentSlot = timeSlots[i];
        const currentSlotEnd = new Date(currentSlot.attributes.end);
        const currentSlotStart = new Date(currentSlot.attributes.start);

        // if end of month, don't remove 30 mins
        const bufferEnd =
          currentSlotEnd.getTime() === getMonthStartInTimeZone(currentSlotEnd, timeZone).getTime()
            ? currentSlotEnd
            : new Date(currentSlotEnd.getTime() - 30 * 60 * 1000); // Remove 30 minutes

        // if now don't add 30 mins,
        // if start of month don't add 30 mins
        const bufferStart =
          moment()
            .add(30, 'minutes')
            .isSameOrAfter(currentSlotStart) ||
          currentSlotStart.getTime() ===
            getMonthStartInTimeZone(currentSlotStart, timeZone).getTime()
            ? currentSlotStart
            : new Date(currentSlotStart.getTime() + 30 * 60 * 1000); // Add 30 minutes

        updatedSlotsForMonth.push({
          id: {
            _sdkType: 'UUID',
            uuid: `${currentSlot.id.uuid}-buffer`, // Generate a unique ID for the buffer slot
          },
          type: 'timeSlot',
          attributes: {
            type: 'time-slot/time',
            seats: 1,
            start: bufferStart,
            end: bufferEnd,
          },
        });
      }

      // Update `timeSlots` in the monthly slots
      updatedTimeSlots[month].timeSlots = updatedSlotsForMonth;
    }
  });

  return updatedTimeSlots;
};
