import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { lineItemCustomerCommissionTaxRefundMaybe } from '../../util/bookingBreakdown/lineItemCustomerCommissionTaxRefundMaybe';

import css from './BookingBreakdown.module.css';

const LineItemCustomerCommissionTaxRefundMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const { label, price, showRefund } = lineItemCustomerCommissionTaxRefundMaybe(
    transaction,
    isCustomer,
    intl
  );

  return isCustomer && showRefund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemCustomerCommissionTaxRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionTaxRefundMaybe;
