import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import Decimal from 'decimal.js';
import config from '../../config';
import { types as sdkTypes } from '../sdkLoader';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION_TAX,
} from '../types';
import { txIsPayoutManuallyAdjusted } from '../transaction';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Checks if line item represents commission tax
 */
const isCommissionTax = lineItem => {
  return lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX;
};

/**
 * Returns commission, line items including non-reversal
 */
const commissionLineItems = transaction => {
  return transaction.attributes.lineItems.filter(
    item => isCommission(item) || isCommissionTax(item)
  );
};

/**
 * Checks if a transaction has a commission line-item for
 * a given user role.
 */
const txHasCommission = (transaction, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  } else if (userRole === 'provider') {
    commissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION
    );
  }
  return !!commissionLineItem;
};

const amountToMoney = amount => new Money(amount, config.currency);

const adjustedSubTotal = (transaction, subTotal) => {
  const feeAdjustment = transaction.attributes.metadata?.feeAdjustment ?? 0;
  const feeGstAdjustment = transaction.attributes.metadata?.feeGstAdjustment ?? 0;

  const manualTotals = feeAdjustment + feeGstAdjustment;

  return amountToMoney(manualTotals * 100 + subTotal.amount.toNumber());
};

export const lineItemCustomerCommissionSubTotalMaybe = (transaction, unitType, userRole, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const refund = transaction.attributes.lineItems.find(
    item => item.code === unitType && item.reversal
  );

  const showSubTotal = txHasCommission(transaction, userRole) || refund;

  // all commission line items
  const subTotalLineItems = commissionLineItems(transaction);
  // line totals of those line items combined
  const subTotal = lineItemsTotal(subTotalLineItems);

  const subTotalAdjusted = txIsPayoutManuallyAdjusted(transaction)
    ? adjustedSubTotal(transaction, subTotal)
    : subTotal;

  const formattedSubTotal = () => {
    if (subTotalLineItems.length > 0) {
      return !!intl
        ? formatMoney(intl, subTotalAdjusted)
        : `$${(subTotalAdjusted.amount / 100).toFixed(2)}`;
    }
  };

  return { label: 'Subtotal', price: formattedSubTotal(), showSubTotal };
};
