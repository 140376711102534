import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';
import { trailerCategoryUrls } from '../../util/trailerCategoryUrls';

import css from './SectionCarTrailers.module.css';

const SectionCarTrailersComponent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className);

  const categoryLink = type => {
    return (
      <NamedLink key={type} name="SearchPage" to={{ search: trailerCategoryUrls[type] }}>
        {type} trailers
      </NamedLink>
    );
  };

  return (
    <React.Fragment>
      <div className={css.carTrailersContent}>
        <h3 className={css.heading}>
          How to find the right car trailer for you on Local Trailer Hire
        </h3>
        <ul className={css.list}>
          <li>Enter your location to search your area.</li>
          <li>
            Use the filters to limit your search to trailers that are the right size and price.
          </li>
          <li>
            If you are looking for specific features you can also filter for these to narrow down
            your search results.
          </li>
          <li>Choose your desired car trailer from the list.</li>
          <li>
            If you want to see more search results further away from your location you can zoom out
            on the map.
          </li>
        </ul>
      </div>
      <div className={css.carTrailersContent}>
        <h3 className={css.heading}>Car trailers FAQ</h3>
        <h4 className={css.subheading}>What is a car trailer?</h4>
        <p>
          A car trailer is a specialized type of trailer designed specifically for transporting
          vehicles. These trailers are typically flatbed trailers with no sides, allowing cars to be
          driven or rolled onto the trailer for transport. They may also feature ramps or other
          loading mechanisms to make it easier to load and unload vehicles. Car trailers can come in
          various sizes and weight capacities, allowing different types and sizes of vehicles to be
          transported.
        </p>
      </div>
      <div className={css.carTrailersContent}>
        <h4 className={css.subheading}>What can a car trailer be used for?</h4>
        <p>
          Car trailers can be used for a variety of purposes, including transporting a vehicle for a
          race or car event, moving to a new home, or even for commercial purposes. Car trailers are
          especially useful for towing broken down cars, as they allow you to transport the car
          safely and securely without causing further damage. With a range of car trailers available
          for hire on Local Trailer Hire, you can find the perfect trailer for your specific needs.
        </p>
      </div>
      <div className={css.carTrailersContent}>
        <h4 className={css.subheading}>Can anyone operate a car trailer?</h4>
        <p>
          Using a car trailer is not limited to any particular group of people or profession. Car
          trailers work just like other trailers and can be attached to different vehicles that have
          a tow ball. However, it's important to note that some car trailers may require electrical
          brake controllers, which are devices that apply the brakes on the trailer when the car
          brakes. If you're unsure about how to use a specific car trailer, or whether your vehicle
          needs an electrical brake controller, you can always ask the trailer owner on Local
          Trailer Hire before making a booking.
        </p>
      </div>

      <div className={css.carTrailersContent}>
        <h4 className={css.subheading}>How can I choose the right size car trailer?</h4>
        <p>
          Choosing the right size car trailer depends on the weight and size of the vehicle you want
          to transport. Not all cars can tow all car trailers – what you can tow depends on your
          car's towing capacity. To find out the maximum weight your car can tow, you can check its
          manual or contact the manufacturer. Local Trailer Hire gives you information about the
          weight capacity (ATM, payload and tare) of each trailer available for hire, so you can
          select a trailer that fits your needs and safely and securely transport your vehicle.
        </p>
      </div>
      <div className={css.carTrailersContent}>
        <h4 className={css.subheading}>
          Why are the car trailers on Local Trailer Hire different prices?
        </h4>
        <p>
          Each trailer owner sets the price for their trailer, so the price of a car trailer will
          vary. Trailer owners generally set their price depending on the size and payload capacity
          of the trailer, the condition of the trailer, and the features of the trailer. Features on
          a car trailer may include ramps for loading and unloading, brakes for added safety and
          control, and tie-downs or straps to secure the car during transport. You can use the
          search filters on Local Trailer Hire to find a car trailer that is the price you need.
        </p>
      </div>
      <div className={css.carTrailersContent}>
        <h4 className={css.subheading}>
          Other popular types of trailers available for hire include:
        </h4>
        <ul>
          <li>{categoryLink('cage')}</li>
          <li>{categoryLink('box')}</li>
          <li>{categoryLink('enclosed')}</li>
        </ul>
        <p>
          By hiring a trailer from a local, you can save money and support your community. Enter
          your postcode or suburb into the search input to find a local trailer.
        </p>
      </div>
    </React.Fragment>
  );
};
SectionCarTrailersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCarTrailersComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCarTrailersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarTrailersComponent;
