import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { lineItemManualCustomerCommissionTaxMaybe } from '../../util/bookingBreakdown/lineItemManualCustomerCommissionTaxMaybe';

import css from './BookingBreakdown.module.css';

const LineItemManualCustomerCommissionTaxMaybe = props => {
  const { transaction, intl, isProvider } = props;

  const { label, price, showManualFeeGstAdjustment } = lineItemManualCustomerCommissionTaxMaybe(
    transaction,
    intl
  );

  return !isProvider && price && showManualFeeGstAdjustment ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{price}</span>
    </div>
  ) : null;
};

LineItemManualCustomerCommissionTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemManualCustomerCommissionTaxMaybe;
