import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Slider from 'react-slick';
import { NamedLink, TestimonialCard } from '../../components';

import { IconReviewStar } from '..';
import css from './SectionTestimonialSlider.module.css';

import { REVIEW_RATINGS } from '../../util/types';

const SectionTestimonialSlider = props => {
  const { rootClassName, className } = props;

  const stars = REVIEW_RATINGS;
  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    autoplay: true,
    autoplaySpeed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    accessibility: true,
    centerMode: true,
    centerPadding: '25px',
  };

  const testimonialLink = (name, location, review, profileId) => {
    return (
      <NamedLink name="ProfilePage" params={{ id: profileId }}>
        <TestimonialCard name={name} location={location} review={review} />
      </NamedLink>
    );
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <Slider {...settings}>
          {testimonialLink(
            'Jodie R',
            'Brisbane',
            'Just loved this service, especially being able to provide a bit of income to local people who allow people like me, to use their trailer for a small fee. So much better than hiring from the bigger chains.',
            '5cfb74a0-084f-4c5c-90e5-b87c8ddaa0d8'
          )}

          {testimonialLink(
            'Mark S',
            'Melbourne',
            'The portal is very efficient to use and the trailer owner was very helpful. It all went very smoothly',
            '5fc77199-4916-4a5b-b808-d309fe98faf8'
          )}

          {testimonialLink(
            'Grant N',
            'Sydney',
            'Experience was easy from booking through to pick up of trailer. Adrian was great to deal with and explained all details of how trailer hire worked as it was my first time using the service.',
            '6103291b-0f3b-452e-a75b-ac98a9248b95'
          )}

          {testimonialLink(
            'Brady R',
            'Central Coast',
            'Great service, easy and hassle free pick up and drop off, trailers are great, awesome experience, and very affordable!',
            '5f98bfee-d62c-4627-91d7-4bfe0a7ef5cc'
          )}

          {testimonialLink(
            'Karly S',
            'Bundaberg',
            'Fantastic price compared to other trailers for hire. Easy to book online and service was fantastic. Lady was really helpful and friendly. Will definitely be using this service again!',
            '60e02ad8-3daa-4db4-8a91-47d3435423a7'
          )}

          {testimonialLink(
            'Geraint G',
            'Nunawading',
            '#1 trailer hire right here. Honestly such amazing communication and smooth transaction. Have now used them twice and will need them a 3rd time very soon. Thank you.',
            '5d0df852-a92a-4571-8b61-5ae84ef6bfe2'
          )}
        </Slider>
      </div>
    </div>
  );
};

SectionTestimonialSlider.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTestimonialSlider.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTestimonialSlider;
