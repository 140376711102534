import React from 'react';
import { FormattedMessage } from '../reactIntl';
import { formatMoney } from '../currency';
import { LINE_ITEM_CUSTOMER_COMMISSION_TAX } from '../types';
import { TRANSITION_DECLINE, TRANSITION_EXPIRE } from '../transaction';

export const lineItemCustomerCommissionTaxRefundMaybe = (transaction, isCustomer, intl) => {
  // there is no intl possible for the PDF parent component using react-pdf
  // https://github.com/diegomura/react-pdf/issues/2354

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION_TAX && item.reversal
  );

  const showRefund =
    transaction.attributes.lastTransition !== TRANSITION_EXPIRE &&
    transaction.attributes.lastTransition !== TRANSITION_DECLINE;

  const customerCommissionRefundLabel = !!intl ? (
    <FormattedMessage id="BookingBreakdown.refundCustomerTaxFee" />
  ) : (
    'Refund GST'
  );

  if (isCustomer && refund) {
    const formattedCustomerCommissionRefund = !!intl
      ? formatMoney(intl, refund.lineTotal)
      : `$${(refund.lineTotal.amount / 100).toFixed(2)}`;

    return {
      label: customerCommissionRefundLabel,
      price: formattedCustomerCommissionRefund,
      showRefund,
    };
  }
  return { label: null, price: null, showRefund: false };
};
