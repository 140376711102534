import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, Button, IconClose } from '../../components';
import { CustomerNoteForm } from '../../forms';
import { propTypes } from '../../util/types';

import css from './ExpandableCustomerNote.module.css';

class ExpandableCustomerNote extends Component {
  constructor(props) {
    super(props);
    this.state = { customerNoteFormExpanded: false };
  }
  render() {
    const { customerNoteFormExpanded } = this.state;
    const {
      ensuredUser,
      customerNote,
      updateInProgress,
      updateProfileError,
      onSubmitCustomerNoteForm,
    } = this.props;

    const handleShowFormClick = () => {
      this.setState({ customerNoteFormExpanded: true });
    };

    const handleSubmit = values => {
      onSubmitCustomerNoteForm(values, ensuredUser.id.uuid);
    };

    const handleClose = () => {
      this.setState({ customerNoteFormExpanded: false });
    };

    const showCustomerNoteForm = (
      <p className={css.links}>
        <InlineTextButton className={css.link} onClick={handleShowFormClick}>
          Add a private note
        </InlineTextButton>
      </p>
    );
    return (
      <React.Fragment>
        {updateProfileError ? (
          <div className={css.errorContainer}>
            <p className={css.error}>
              <FormattedMessage id="ExpandableCustomerNote.updateProfileFailed" />
            </p>
          </div>
        ) : null}
        {customerNoteFormExpanded || customerNote ? (
          <div className={css.customerNoteContainer}>
            <CustomerNoteForm
              onSubmit={handleSubmit}
              updateInProgress={updateInProgress}
              updateProfileError={updateProfileError}
              initialValues={{ customerNote }}
            />

            {customerNote ? null : (
              <Button onClick={handleClose} className={css.close} title="Close">
                <span className={css.closeText}>{<FormattedMessage id="Modal.close" />}</span>
                <IconClose className={css.closeIcon} />
              </Button>
            )}
          </div>
        ) : (
          showCustomerNoteForm
        )}
      </React.Fragment>
    );
  }
}

ExpandableCustomerNote.defaultProps = { className: null };

ExpandableCustomerNote.propTypes = {
  className: string,
  // update profile
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  onSubmitCustomerNoteForm: func.isRequired,
};

export default ExpandableCustomerNote;
