import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import * as log from '../../util/log';
import {
  convertMoneyToNumber,
  convertUnitToSubUnit,
  ensureSeparator,
  formatMoney,
  truncateToSubUnitPrecision,
  unitDivisor,
} from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput } from '../../components';

import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => {
  const { onSubmit, initialValues, intl, saveActionMsg } = props;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const translationKey = isNightly
    ? 'EditListingPricingForm.pricePerNight'
    : isDaily
    ? 'EditListingPricingForm.pricePerDay'
    : 'EditListingPricingForm.pricePerUnit';

  const pricePerUnitMessage = intl.formatMessage({
    id: translationKey,
  });

  const pricePlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.priceInputPlaceholder',
  });

  const pricePerUnitDiscount1Message = intl.formatMessage({
    id: translationKey + 'Discount1',
  });

  const priceDiscount1PlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.priceDiscount1InputPlaceholder',
  });

  const pricePerUnitDiscount2Message = intl.formatMessage({
    id: translationKey + 'Discount2',
  });

  const priceDiscount2PlaceholderMessage = intl.formatMessage({
    id: 'EditListingPricingForm.priceDiscount2InputPlaceholder',
  });

  const priceRequired = value =>
    value ? undefined : intl.formatMessage({ id: 'EditListingPricingForm.priceRequired' });
  const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
  const minPriceRequired = validators.currencyAmountAtLeast(
    intl.formatMessage(
      {
        id: 'EditListingPricingForm.priceTooLow',
      },
      {
        minPrice: formatMoney(intl, minPrice),
      }
    ),
    convertMoneyToNumber(minPrice)
  );

  const priceValidators = config.listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;

  const initialPriceValue = price => {
    return price instanceof Money && !!price.amount
      ? parseFloat(convertMoneyToNumber(price)).toFixed(2)
      : '';
  };
  initialValues.price = initialPriceValue(initialValues?.price) || '';
  initialValues.priceDiscount1 = initialPriceValue(initialValues?.priceDiscount1) || '';
  initialValues.priceDiscount2 = initialPriceValue(initialValues?.priceDiscount2) || '';

  const testSubUnitFormat = intl.formatNumber('1.1', config.currencyConfig);
  const usesComma = testSubUnitFormat.indexOf(',') >= 0;

  // Convert unformatted value (e.g. 10,00) to Money (or null)
  const getPrice = unformattedValue => {
    if (!unformattedValue) return null;

    try {
      const truncatedValue = truncateToSubUnitPrecision(
        ensureSeparator(unformattedValue.toString(), usesComma),
        unitDivisor(config.currencyConfig.currency),
        usesComma
      );
      return new Money(
        convertUnitToSubUnit(truncatedValue, unitDivisor(config.currencyConfig.currency)),
        config.currencyConfig.currency
      );
    } catch (e) {
      log.error(e, 'a message dearly');
      return null;
    }
  };

  return (
    <FinalForm
      {...props}
      onSubmit={values => {
        const { price, priceDiscount1, priceDiscount2 } = values;

        const formattedValues = {
          ...values,
          price: getPrice(price),
          priceDiscount1: getPrice(priceDiscount1),
          priceDiscount2: getPrice(priceDiscount2),
        };

        onSubmit(formattedValues);
      }}
      initialValues={initialValues}
      render={({
        className,
        disabled,
        ready,
        handleSubmit,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
      }) => {
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || pristine;

        const { updateListingError, showListingsError } = fetchErrors || {};

        const classes = classNames(css.root, className);
        const formId = 'EditListingPricingForm';

        return (
          <Form onSubmit={handleSubmit} className={classNames(classes)}>
            {updateListingError && (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            )}
            {showListingsError && (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            )}

            <Field
              name="price"
              id={formId ? `${formId}.price` : 'price'}
              className={css.priceInput}
              component={FieldCurrencyInput}
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
              autofocus
            />
            <Field
              name="priceDiscount1"
              id={formId ? `${formId}.priceDiscount1` : 'priceDiscount1'}
              className={css.priceInput}
              component={FieldCurrencyInput}
              label={pricePerUnitDiscount1Message}
              placeholder={priceDiscount1PlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
            <Field
              name="priceDiscount2"
              id={formId ? `${formId}.priceDiscount2` : 'priceDiscount2'}
              className={css.priceInput}
              component={FieldCurrencyInput}
              label={pricePerUnitDiscount2Message}
              placeholder={priceDiscount2PlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
