/**
 * Renders customer details to assist with owner identity check
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { txHasBeenAccepted, txIsReviewed, txIsCanceled } from '../../util/transaction';

import css from './BookingBreakdown.module.css';

const fullOrDisplayName = transaction => {
  const firstName = transaction.attributes.protectedData.firstName;
  const lastName = transaction.attributes.protectedData.lastName;
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : transaction.customer.attributes.profile.displayName;
};

const address = transaction =>
  typeof transaction.attributes.protectedData.residentialAddress === 'string'
    ? transaction.attributes.protectedData.residentialAddress
    : transaction.attributes.protectedData.residentialAddress.selectedPlace.address;

const dlExpiryDateDisplay = transaction => {
  const dlExpiryDate = transaction.attributes.protectedData.driversLicenceExpiryDate;
  return typeof dlExpiryDate === 'string'
    ? dlExpiryDate
    : `${dlExpiryDate.day}/${dlExpiryDate.month}/${dlExpiryDate.year}`;
};

const LineItemCustomerDetails = props => {
  const { transaction, isProvider } = props;
  const showCustomerDetails =
    txHasBeenAccepted(transaction) && !txIsReviewed(transaction) && !txIsCanceled(transaction);
  return showCustomerDetails && isProvider ? (
    <React.Fragment>
      <p className={css.verifyInstructions}>Remember to verify the customer's details.</p>
      <div className={css.dayInfo}>
        <span>{fullOrDisplayName(transaction)}</span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>Licence Number</span>
        <span className={css.itemValue}>
          {transaction.attributes.protectedData.driversLicenceNumber} (
          {transaction.attributes.protectedData.driversLicenceIssuedBy})
        </span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>Licence Expiry</span>
        <span className={css.itemValue}>{dlExpiryDateDisplay(transaction)} </span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>Address</span>
        <span className={css.itemValue}>
          {transaction.attributes.protectedData.building
            ? `${transaction.attributes.protectedData.building}, `
            : null}
          {address(transaction)}
        </span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>Phone</span>
        <span className={css.itemValue}>
          <a href={`tel:${transaction.attributes.protectedData.phoneNumber}`}>
            {transaction.attributes.protectedData.phoneNumber}
          </a>
        </span>
      </div>
      <hr className={css.totalDivider} />
    </React.Fragment>
  ) : null;
};

LineItemCustomerDetails.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerDetails;
